import http from "./http";

export const GetMyAccount = async() => {
    return await http("/accounts");
};


export const FundAccount = async(data) => {
    return await http.post("/accounts/fund", data);
};










export const CreateAccount = async(account) => {
    return await http.post("account/accounts", account);
};

export const UpdateAccount = async(id, account) => {
    return await http.put("account/accounts/" + id, account);
};



/**
 * Setting
 */
export const GetAllSetting = async() => {
    return await http("account/settings");
};

export const GetSetting = async(id) => {
    return await http("account/settings/" + id);
};

export const CreateSetting = async(setting) => {
    return await http.post("account/settings", setting);
};

export const CreateSettingPin = async(setting) => {
    return await http.post("account/settings/pin/create", setting);
};

export const VerifySettingPin = async(setting) => {
    return await http.post("account/settings/pin/create", setting);
};

export const UpdateSetting = async(id, setting) => {
    return await http.patch("account/settings/" + id, setting);
};

export const DeleteSetting = async(id) => {
    return await http.delete("account/settings/" + id);
};



export const VerifyViaBVN = async(data) => {
    return await http.post("verify/bvn", data);
};


export const VerifyViaNIN = async(data) => {
    return await http.post("verify/nin", data);
};

export const ConfirmViaBVN = async(data) => {
    return await http.post("verify/confirm-bvn", data);
};

export const ConfirmAddress = async(data) => {
    return await http.post("verify/address", data);
};