<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>
    <div class="login-wrapper d-flex align-items-center justify-content-center">
      <div class="custom-container">
        <div class="text-center px-4"><img class="login-intro-img" :src="appLogo" alt=""></div>
        <!-- Register Form -->
        <div class="register-form mt-4">
          <h6 class="mb-3 text-center">Register to continue.</h6>
          <TransitionGroup>
            <a class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-google me-2 text-danger"></i>Google
            </a>
            <button v-if="registrationMethod!==registrationMethods.bvn" @click="changeRegistrationMethod(registrationMethods.bvn)" class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-hand-index-thumb-fill me-2 text-primary"></i>Use BVN
            </button>
            <button v-if="registrationMethod!==registrationMethods.nin" @click="changeRegistrationMethod(registrationMethods.nin)" class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-hand-index-fill me-2 text-secondary"></i>Use NIN
            </button>
            <button v-if="registrationMethod!==registrationMethods.email" @click="changeRegistrationMethod(registrationMethods.email)" class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-envelope-fill me-2 text-secondary"></i>Use Email
            </button>
          </TransitionGroup>
          <hr/>

          <TransitionGroup>
            <form  class="was-validated" v-if="registrationMethod===registrationMethods.email">

              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ user.name ? 'form-control-clicked' : ''}`" v-model="v$.user.name.$model" type="text" required placeholder="Name">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.name.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>

              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ user.email ? 'form-control-clicked' : ''}`" v-model="v$.user.email.$model" type="email" required placeholder="Email address">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.email.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>

              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ user.phone ? 'form-control-clicked' : ''}`" v-model="v$.user.phone.$model" type="string" required placeholder="Phone">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.phone.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>
            
              <!-- <div class="form-group text-start mb-3">
                <input :class="`form-control ${ user.phone ? 'form-control-clicked' : ''}`" v-model="v$.user.phone.$model" type="number" placeholder="Phone">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.phone.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div> -->
              <div class="form-group text-start mb-3 position-relative">
                <input class="form-control" v-model="v$.user.password.$model" id="psw-input" :type="passwordType" placeholder="New password" @keyup="checkStrength">
                <div class="position-absolute" @click="changePasswordType" id="password-visibility"><i class="bi bi-eye" v-if="passwordType === 'password'"></i><i v-else class="bi bi-eye-slash-fill"></i></div>

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.password.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>
              <div class="mb-3 password-strength-meter" id="pswmeter" ><div class="password-strength-meter-score"></div></div>

              <div id="popover-password" class="text-xs">
                <p><span id="result"></span></p>
                <div class="progress">
                    <div id="password-strength" 
                        :class="`progress-bar ${passwordStrengthProgressBarClasses}`" 
                        role="progressbar" 
                        aria-valuenow="40" 
                        aria-valuemin="0" 
                        aria-valuemax="100" 
                        >
                    </div>
                </div>
                <ul class="list-unstyled">
                    <li class="">
                        <small class="low-upper-case text-xs">
                            <i :class="`bi ${passwordStrength.uppercase ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                            &nbsp;Lowercase &amp; Uppercase
                        </small>
                    </li>
                    <li class="">
                        <small class="one-number text-xs">
                            <i :class="`bi ${passwordStrength.number ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                            &nbsp;Number (0-9)
                        </small> 
                    </li>
                    <li class="">
                        <small class="one-special-char text-xs">
                            <i :class="`bi ${passwordStrength.specialChar ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                            &nbsp;Special Character (!@#$%^&*)
                        </small>
                    </li>
                    <li class="">
                        <small class="eight-character text-xs">
                            <i :class="`bi ${passwordStrength.min ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                            &nbsp;Atleast 8 Character
                        </small>
                    </li>
                </ul>
              </div>

              <hr/>
              <div class="text-muted mb-1">Optional *</div>
              <div class="form-group text-start mb-3">
                <input :class="`form-control`" v-model="v$.user.referrer.$model" type="string" placeholder="Referrer Code">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.referrer.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-check mb-3">
                <input class="form-check-input" id="checkedCheckbox" v-model="v$.user.agreement.$model" type="checkbox" >
                <label class="form-check-label text-muted fw-normal" for="checkedCheckbox">I agree with the terms &amp; policy.</label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.agreement.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <app-button @click="register" :title="'Sign Up'" :loading="loaders().registerUser" :disabled="loaders().registerUser || v$.user.$invalid || passwordStrength.value < 3" ></app-button>
              
              <!-- <button class="btn btn-sm m-1 btn-outline w-100 text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
                <i class="bi bi-fingerprint me-2 text-danger"></i>Use BVN
              </button>
              <button class="btn btn-sm m-1 btn-outline w-100 text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
                <i class="bi bi-fingerprint me-2 text-danger"></i>Use NIN
              </button>
              <a class="btn btn-sm m-1 btn-outline w-100 text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
                <i class="bi bi-google me-2 text-danger"></i>Google
              </a> -->
            </form>

            <form  class="was-validated" v-if="registrationMethod===registrationMethods.bvn">
              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ bvn ? 'form-control-clicked' : ''}`" v-model="v$.bvn.$model" type="text" required placeholder="BVN Number">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bvn.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>
              <hr/>
              <div class="text-muted mb-1">Optional *</div>
              <div class="form-group text-start mb-3">
                <input :class="`form-control`" v-model="v$.referrer.$model" type="string" placeholder="Referrer Code">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.referrer.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <app-button @click="registerViaBVN" :title="'Continue'" :loading="loaders().identityRegistrationInit" :disabled="loaders().identityRegistrationInit || v$.bvn.$invalid" ></app-button>
            </form>

            <form  class="was-validated" v-if="registrationMethod===registrationMethods.nin">
              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ nin ? 'form-control-clicked' : ''}`" v-model="v$.nin.$model" type="text" required placeholder="NIN Number">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nin.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>
              <hr/>
              <div class="text-muted mb-1">Optional *</div>
              <div class="form-group text-start mb-3">
                <input :class="`form-control`" v-model="v$.referrer.$model" type="string" placeholder="Referrer Code">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.referrer.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <app-button @click="registerViaNIN" :title="'Continue'" :loading="loaders().identityRegistrationInit" :disabled="loaders().identityRegistrationInit || v$.nin.$invalid" ></app-button>
            </form>
          </TransitionGroup>

          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of Object.keys(errors)" :key="index">
            <small class="error-msg">
              <small>{{ error }} {{ errors[error] }}</small>
            </small>
          </div>
          <hr/>

        </div>
        <!-- Login Meta -->
        <div class="login-meta-data text-center">
          <p class="mt-3 mb-0">Already have an account? <router-link class="stretched-link text-success" to="/login">Login</router-link></p>
        </div>
      </div>
    </div>
  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue';
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/common/form/AppButton.vue';
import { BASE_URL } from '../../../utils/constant.util';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';

export default {
  name: 'RegisterPage',
  components: {
    CentralizedTemplate,
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    const { logo } = this.appConfig() || {};
    return {
      user: {},
      nin: "",
      bvn: "",
      referrer: "",
      passwordType: "password",
      baseUrl: BASE_URL,
      errors: {},
      passwordStrength: {
        value: 0
      },
      passwordStrengthProgressBarClasses: "",
      appLogo: logo || require("../../../assets/jawo.png"),
      registrationMethod: "EMAIL",
      registrationMethods: {
        email: "EMAIL",
        bvn: "BVN",
        nin: "NIN"
      }
    }
  },
  validations() {
    return {
      nin: { required, min: minLength(11) },
      bvn: { required, min: minLength(11) },
      referrer: {},
      user: {
        email: { required, email },
        name: {required, min: minLength(3)},
        phone: {required},
        referrer: {},
        password: { required, min: minLength(6) },
        agreement: {required}
      },
    }
  },
  mounted() {
    if ( window.localStorage.getItem("referrer") ) {
      const referrer = window.localStorage.getItem("referrer");
      this.user.referrer = referrer;
    }
  },
  methods: {
    ...mapActions([
      "getAuthUser",
      "registerUser",
      "identityRegistrationInit"
    ]),
    ...mapGetters([
      "loaders",
      "appConfig"
    ]),
    registerViaBVN() {
      this.identityRegistrationInit({
        bvn: this.bvn
      }).then(() => {
        this.$router.push({
          path: '/otp-confirm-page', 
          params: {bvn: this.bvn, referrer: this.referrer, page: "identity-registration"},
          query: {bvn: this.bvn, referrer: this.referrer, page: "identity-registration"}
        });
      }).catch (err => {
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
      })
    },
    registerViaNIN() {
      this.identityRegistrationInit({
        nin: this.nin
      }).then(() => {
        this.$router.push({
          path: '/otp-confirm-page', 
          params: {nin: this.nin, referrer: this.referrer, page: "identity-registration"},
          query: {nin: this.nin, referrer: this.referrer, page: "identity-registration"}
        });
      }).catch (err => {
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
      })
    },
    register() {
      // this.$router.push('/profile')
      const agreement = this.user.agreement;
      // delete this.user.agreement;
      const self = this;
      this.registerUser(this.user).then( res => {
        if ( res.token ) {
          self.$router.push('/dashboard');
        } else {
          if ( window.localStorage.getItem("referrer") ) {
            window.localStorage.removeItem("referrer");
          }
          self.$router.push({
            path: '/otp-confirm-page', 
            params: {email: self.user.email, page: "validate"},
            query: {email: self.user.email, page: "validate"}
          });
        }
      }).catch (err => {
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
      })
      // this.$router.push('/otp-page')
    },
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },
    goBack() {
      this.$router.go(-1)
    },
    changeRegistrationMethod(method) {
      this.registrationMethod = method
    },
    checkStrength(e) {
      const password = e.target.value
      let strength = 0;

      //If password contains both lower and uppercase characters
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
          strength += 1;
          this.passwordStrength.uppercase = true;
      } else {
          this.passwordStrength.uppercase = false;
      }
      //If it has numbers and characters
      if (password.match(/([0-9])/)) {
          strength += 1;
          this.passwordStrength.number = true;
      } else {
          this.passwordStrength.number = false;
      }
      //If it has one special character
      if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
          strength += 1;
          this.passwordStrength.specialChar = true;
      } else {
        this.passwordStrength.specialChar = false;
      }
      //If password is greater than 7
      if (password.length > 7) {
          strength += 1;
          this.passwordStrength.min = true;
      } else {
          this.passwordStrength.min = false; 
      }

      // If value is less than 2
      if (strength < 2) {
          this.passwordStrengthProgressBarClasses = 'progress-bar-danger w-10'
      } else if (strength == 3) {
          this.passwordStrengthProgressBarClasses = 'progress-bar-warning w-60'
      } else if (strength == 4) {
          this.passwordStrengthProgressBarClasses = 'progress-bar-success w-100'
      }
      this.passwordStrength.value = strength;
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}

.progress {
    height: 3px !important;
}

.progress-bar-danger {
    background-color: #e90f10;
}
.progress-bar-warning{
    background-color: #ffad00;
}
.progress-bar-success{
    background-color: #02b502;
}
.w-10 {
  width: 10%;
}
.w-60 {
  width: 60%;
}
.w-100 {
  width: 100%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
