function pipeline(context, middleware, index) {
    const nextMiddleware = middleware[index]

    if (!nextMiddleware || context.stopLoop) {
        return context.next || context._next
    }

    return () => {
        const nextPipeline = pipeline(
            context, middleware, index + 1
        )

        nextMiddleware({...context, next: nextPipeline })

    }
}

export default pipeline;