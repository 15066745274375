<template>
  <div class="container">
    <success-notification title="Subscribed" message="You have successfully completed your subscription" next="/my-savings" nextPageName="My Saves"></success-notification>
  </div>
</template>

<script>

import SuccessNotification from '@/components/pages/notification/SuccessNotification.vue'


export default {
  name: 'SaveSubSuccessNot',
  components: {
    SuccessNotification
  },
  data() {
    return {
      user: {},
      // countries: countries
    }
  },
  mounted() {
    // const overlay = document.getElementsByClassName("modal-backdrop")[0];
    // overlay.classList.remove(...overlay.classList)
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
