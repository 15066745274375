<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>

    <div class="login-wrapper d-flex align-items-center justify-content-center text-center">
      <div class="custom-container">
        <div class="text-center w-100">
          <!-- <img class="login-intro-img mb-4" src="../../../assets/images/auth/38.png" alt=""> -->
          <h3>Choose App!</h3>
          <p class="mb-4">Select your view.</p>
          Choose any of
          <!-- <router-link class="btn btn-success text-white" to="/login"> login </router-link> -->

          <app-button classes="btn-success w-100 my-1" @click="selectApp('investment')" :title="'Investment'" :loading="false" :disabled="false" ></app-button>

          <app-button classes="btn-success w-100" @click="selectApp('savings')" :title="'Savings'" :loading="false" :disabled="false" ></app-button>
        </div>
      </div>
    </div>

  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue'
import AppButton from '@/components/common/form/AppButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SelectApp',
  components: {
    CentralizedTemplate,
    AppButton
  },
  methods: {
    ...mapActions([
      "switchAccount",
    ]),
    ...mapGetters([]),
    goBack() {
      this.$router.go(-1)
    },
    selectApp(app) {
      this.switchAccount(app)
      this.$router.push("/dashboard")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
