import AccountGetter from "./account.getter";
import LoaderGetter from "./loader.getter";
import QuestionGetter from "./question.getter";
import NotificationGetter from "./notification.getter";
import TransactionGetter from "./transaction.getter";
import UserGetter from "./user.getter";
import PlanGetter from "./plan.getter";
import PredictionGetter from "./predictions.getter";
import SettingsGetter from "./settings.getter";
import BankAccountGetter from "./bankAccount.getter";
import WithdrawGetter from "./withdraw.getter";
import VasGetter from "./vas.getter";
import RateGetter from "./rate.getter";
import AppGetter from "./app.getter";
import BusinessGetter from "./business.getter";

const getters = {
    ...SettingsGetter,
    ...TransactionGetter,
    ...UserGetter,
    ...PlanGetter,
    ...LoaderGetter,
    ...NotificationGetter,
    ...AccountGetter,
    ...QuestionGetter,
    ...PredictionGetter,
    ...BankAccountGetter,
    ...WithdrawGetter,
    ...VasGetter,
    ...RateGetter,
    ...AppGetter,
    ...BusinessGetter
};

export default getters;