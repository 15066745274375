import { AbortPlan, ConfirmFlutterPayment, GetAllPlans, Subscribe, RepayLoan, PaySavings, JoinPlan, FetchByReference, FetchByCode } from "@/services/plan";

export const getAllPlans = (context) => {
    context.commit("updateLoader", { getPlan: true });
    return new Promise((resolve, reject) => {
        GetAllPlans()
            .then(res => {
                const result = res.data.data;
                context.commit("getPlans", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPlan: false });
            });
    });
};

export const subscribePlan = (context, data) => {
    context.commit("updateLoader", { subscribe: true });
    return new Promise((resolve, reject) => {
        Subscribe(data)
            .then(res => {
                const result = res.data.data;
                context.commit("subscribe", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { subscribe: false });
            });
    });
};

export const joinPlan = (context, data) => {
    context.commit("updateLoader", { joinPlan: true });
    return new Promise((resolve, reject) => {
        JoinPlan(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("joinPlan", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { joinPlan: false });
            });
    });
};

export const fetchByReference = (context, data) => {
    context.commit("updateLoader", { fetchByReference: true });
    return new Promise((resolve, reject) => {
        FetchByReference(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("fetchByReference", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchByReference: false });
            });
    });
};

export const fetchByCode = (context, data) => {
    context.commit("updateLoader", { fetchByCode: true });
    return new Promise((resolve, reject) => {
        FetchByCode(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("fetchByCode", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchByCode: false });
            });
    });
};

export const confirmFlutterPayment = (context, data) => {
    context.commit("updateLoader", { confirmFlutterPayment: true });
    return new Promise((resolve, reject) => {
        ConfirmFlutterPayment(data)
            .then(res => {
                const result = res.data.data;
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmFlutterPayment: false });
            });
    });
};


export const abortPlan = (context, id) => {
    context.commit("updateLoader", { abortPlan: true });
    return new Promise((resolve, reject) => {
        AbortPlan(id)
            .then(res => {
                const result = res.data.data;
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { abortPlan: false });
            });
    });
};

export const repayLoan = (context, data) => {
    context.commit("updateLoader", { repayLoan: true });
    return new Promise((resolve, reject) => {
        const loadId = data.id;
        delete data.id;
        RepayLoan(data, loadId)
            .then(res => {
                const result = res.data.data;
                // context.commit("repayLoan", loadId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { repayLoan: false });
            });
    });
}

export const paySavings = (context, data) => {
    context.commit("updateLoader", { paySavings: true });
    return new Promise((resolve, reject) => {
        const loadId = data.id;
        delete data.id;
        PaySavings(data, loadId)
            .then(res => {
                const result = res.data.data;
                // context.commit("paySavings", loadId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { paySavings: false });
            });
    });
}


const PlanAction = {
    abortPlan,
    getAllPlans,
    subscribePlan,
    joinPlan,
    confirmFlutterPayment,
    fetchByReference,
    fetchByCode,
    repayLoan,
    paySavings
};

export default PlanAction;