<template>
  <div class="container">
    <success-notification 
      title="Withdraw Successful" 
      :message="`A sum of ${$route.query.currency} ${$route.query.amount} has been transfered to bank account ${bank.bank_name} ${bank.account_number} ${bank.account_name}`" 
      next="/withdraw" 
      nextPageName="withdrawal"
    ></success-notification>
  </div>
</template>

<script>

import SuccessNotification from '@/components/pages/notification/SuccessNotification.vue'


export default {
  name: 'BankWithdrawSuccess',
  components: {
    SuccessNotification
  },
  data() {
    return {
      bank: {},
      // countries: countries
    }
  },
  mounted() {
    this.bank = JSON.parse(this.$route.query.bank);
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
