import { AddStrategy, DeleteStrategy, GetAllPredictions, GetStrategy, ToggleStrategy, UpdateStrategy } from "@/services/prediction";

export const getAllPredictions = (context) => {
    context.commit("updateLoader", { getPrediction: true });
    return new Promise((resolve, reject) => {
        GetAllPredictions()
            .then(res => {
                const result = res.data.data;
                context.commit("getPredictions", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPrediction: false });
            });
    });
};



export const getStrategy = (context) => {
    context.commit("updateLoader", { fetchStrategy: true });
    return new Promise((resolve, reject) => {
        GetStrategy()
            .then(res => {
                context.commit("getStrategy", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchStrategy: false });
            });
    });
};

export const addStrategy = (context, data) => {
    context.commit("updateLoader", { addStrategy: true });
    return new Promise((resolve, reject) => {
        AddStrategy(data)
            .then(res => {
                context.commit("addStrategy", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addStrategy: false });
            });
    });
};

export const updateStrategy = (context, data) => {
    context.commit("updateLoader", { updateStrategy: true });
    const id = data.id;
    return new Promise((resolve, reject) => {
        UpdateStrategy(id, data)
            .then(res => {
                context.commit("updateStrategy", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateStrategy: false });
            });
    });
};

export const toggleStrategy = (context, data) => {
    context.commit("updateLoader", { toggleStrategy: true });
    return new Promise((resolve, reject) => {
        ToggleStrategy(data.id)
            .then(res => {
                context.commit("toggleStrategy", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { toggleStrategy: false });
            });
    });
};

export const deleteStrategy = (context, data) => {
    context.commit("updateLoader", { deleteStrategy: true });
    return new Promise((resolve, reject) => {
        DeleteStrategy(data.id)
            .then(res => {
                context.commit("deleteStrategy", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteStrategy: false });
            });
    });
};

const PredictionAction = {
    getAllPredictions,
    getStrategy,
    addStrategy,
    updateStrategy,
    toggleStrategy,
    deleteStrategy
};

export default PredictionAction;