import axios from "axios";
import http from "./http";

export const GetCandleStick = async(data) => {
    return await http.post("/candle-stick", data);
};

export const GetCurrentPrice = async(data) => {
    return await http.post("/candle-stick", data);
};

export const GetPrices = async(data) => {
    // try {
    //     const res = await axios.get("https://api.binance.com/api/v3/ticker/price?symbols=" + encodeURIComponent(JSON.stringify(data)));
    //     console.log(res);
    // } catch (e) {
    //     console.log(e)
    // }

    return []; //await http.post("/prices", data);
};