import { BASE_URL } from "@/utils";
import axios from "axios";
// import Vue from "vue";
import { createToaster } from "@meforma/vue-toaster";
import store from "@/store";
const toaster = createToaster({ /* options */ });

const http = axios.create({
    baseURL: BASE_URL
});

http.interceptors.request.use(
    config => {
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };

        if (config.method === "get") {
            config.data = {};
        }

        config.headers = {
            ...config.headers,
            ...headers
        };

        const storageToken = localStorage.getItem("rep") || "";
        if (!!storageToken && storageToken !== "") {
            const token = storageToken; //JSON.parse(storageToken);
            // console.log(config)
            config.headers.authorization = "Bearer " + token;
        }

        const appConfig = localStorage.getItem("appConfig");

        if ( appConfig ) {
            const { business } = JSON.parse(appConfig);
            if ( business ) {
                const { uuid } = business;
                config.headers["business-uuid"] = uuid;
            }
        }

        const transactionPin = localStorage.getItem("tp");

        if ( transactionPin ) {
            config.headers["transaction-pin"] = transactionPin;
        }

        return config;
    },
    error => {
        console.log(error);
    }
);

http.interceptors.response.use(
    response => {
        // console.log(response.config.method)
        if (response.config.method.toUpperCase() !== "GET" && store.getters.toast) {
            toaster.success(response.data.message || " success ");
        }
        return response;
    },
    error => {
        // console.dir(error);
        const { data = {}, status = ""} = error && error.response ? error.response : {}
        const { message = "" } = data

        if (message !== "Unauthenticated." && store.getters.toast)
            toaster.show(message || " no response ");

        data.status = status;
        throw data;
    }
);

export default http;