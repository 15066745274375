import http from "./http";

export const GetProfile = async() => {
    return await http.get("/profile");
};

export const SaveProfile = async data => {
    return await http.post("/profile", data)
}

export const GetAuthUser = async() => {
    return await http.get("/auth/me");
};

export const Logout = async() => {
    return await http.get("/auth/logout");
};

export const AuthUser = async(data) => {
    return await http.post("/auth/login", data);
}

export const AuthUserWithOTP = async(data) => {
    return await http.post("/auth/login-otp", data);
}

export const RegisterUser = async(data) => {
    return await http.post("/auth/register", data);
}

export const Verify = async(data) => {
    return await http.post("/auth/verify", data);
}

export const AuthCode = async(data) => {
    return await http.post("/auth/code", data);
}

export const ValidateCode = async(data) => {
    return await http.post("/auth/code/validate", data);
}

export const RetrieveAuth = async(auth_code) => {
    return await http.get("/auth/retrieve/" + auth_code);
}

export const ForgotPassword = async(data) => {
    return await http.post("/forgot/password", data);
}

export const ResetPassword = async(data) => {
    return await http.post("/reset/password", data);
}

export const ChangePassword = async(data) => {
    return await http.post("/user/change-password", data);
}

export const PhoneCode = async(data) => {
    return await http.post("/phone/code", data);
}

export const UpdatePhone = async(data) => {
    return await http.post("/phone", data);
}

export const RegisterUserInit = async data => {
    return await http.post("/user/init", data);
}

export const RegisterUserProxy = async data => {
    return await http.post("/user/proxy-create", data);
}

export const SearchUsers = async(q = "") => {
    return await http.get("/users/search?" + q);
};

export const AuthChat = async () => {
    return await http.get("/auth/chat");
}

export const IdentityRegistrationInit = async(data) => {
    return await http.post("/identity/register/init", data);
}

export const IdentityRegistrationCreate = async(data) => {
    return await http.post("/identity/register/create", data);
}

export const IdentityAuthInit = async(data) => {
    return await http.post("/identity/auth/init", data);
}

export const IdentityAuthCreate = async(data) => {
    return await http.post("/identity/auth/create", data);
}
