export const registerUser = (state, user) => {
    state.user = user;
};

export const fetchProfile = (state, profile) => {
    state.userProfile = profile;
    return profile;
}

export const saveProfile = (state, profile) => {
    state.userProfile = profile;
    return profile;
}

export const fetchAuth = (state, auth) => {
    state.auth = auth;
};

export const verifyUser = (state, verify) => {
    //
    console.log(state, verify)
};

export const authCode = (state, code) => {
    //
    console.log(state, code)
};

export const forgotPassword = (state, code) => {
    //
    console.log(state, code)
};

export const resetPassword = (state, code) => {
    //
    console.log(state, code)
};


export const authUser = (state, auth) => {
    const { token, verified, scores = {}, uuid } = auth;
    if ( token ) {
        window.localStorage.setItem("rep", token)
    }
    window.localStorage.setItem("verified", verified)
    window.localStorage.setItem("scores", JSON.stringify(scores || {}))
    if ( uuid ) {
        window.localStorage.setItem("uuid", uuid)
    }
        // state.auth = auth;
    return auth;
};

export const logout = (data) => {
    return logoutAuthUser(data);
}

export const logoutAuthUser = (state) => {
    window.localStorage.removeItem("rep");
    window.localStorage.removeItem("verified");
    window.localStorage.removeItem("scores");
    window.localStorage.removeItem("uuid");
    state.auth = {};
};

export const updateUrl = (state, url) => {
    console.log(state, url)
};

const UserMutation = {
    verifyUser,
    updateUrl,
    logout,
    logoutAuthUser,
    registerUser,
    fetchProfile,
    saveProfile,
    authUser,
    authCode,
    resetPassword,
    forgotPassword,
    fetchAuth
};

export default UserMutation;