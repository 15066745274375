import http from "./http";

export const Withdraw = async data => {
    return await http.post("/withdraw", data);
};

export const Sell = async data => {
    return await http.post("/sell", data);
};

export const Send = async data => {
    return await http.post("/send", data);
};

export const FetchWithdraw = async () => {
    return await http.get("/withdraw");
};

export const FetchSell = async () => {
    return await http.get("/sell");
};

export const FetchSend = async () => {
    return await http.get("/send");
};

export const WalletInquiry = async data => {
    return await http.post("/wallet/inquire", data)
}

export const WalletTransfer = async data => {
    return await http.post("/wallet/transfer", data)
}

export const ChangePin = async data => {
    return await http.put("/wallet/pin-update", data)
}

export const ConfirmPin = async data => {
    return await http.post("/wallet/pin-confirm", data)
}

export const TransactionPinStatus = async () => {
    return await http.get("/wallet/pin-status")
}