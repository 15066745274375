const PlanGetter = {
  savingPlans: state => state.savingPlans,
  loanPlans: state => state.loanPlans,
  cryptoPlans: state => state.cryptoPlans,
  subscribe: state => state.subscribe,
  userCryptoPlans: state => state.userCryptoPlans,
  userSavingPlans: state => state.userSavingPlans,
  userLoanPlans: state => state.userLoanPlans
};

export default PlanGetter;
