import http from "./http";

export const AddFile = async (data) => {
  http.defaults.headers = {
    "Content-Type": "multipart/form-data",
    "x-auth-id": "assets",
    Accept: "*/*"
  };
  const res = await http.post("/profile/avatar", data);
  http.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  return res;
};
