<template>
  <div class="hello">
    <div class="internet-connection-status" id="internetStatus" style="display: none; background-color: rgb(0, 184, 148); opacity: 0;">Your internet connection is back.</div>
  </div>
  <router-view />
  <slot />
  <!-- <div class="offcanvas-backdrop fade show"></div> -->
</template>

<script>
import "@/assets/css/global-variable.css";
import "@/assets/css/style.css";
import "@/assets/css/icons-alipay.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/bootstrap-icons.css";
import "@/assets/css/apexcharts.css";
import "@/assets/css/baguetteBox.min.css";
import "@/assets/css/rangeslider.css";
import "@/assets/css/rangeslider.css";
import "@/assets/css/tiny-slider.css";
import "@/assets/css/vanilla-dataTables.min.css";

import "@/assets/js/bootstrap.bundle.min.js"
import "@/assets/js/slideToggle.min.js";
import "@/assets/js/internet-status.js";
import "@/assets/js/tiny-slider.js";
import "@/assets/js/baguetteBox.min.js";
import "@/assets/js/countdown.js";
import "@/assets/js/rangeslider.min.js";
import "@/assets/js/vanilla-dataTables.min.js";
import "@/assets/js/index.js";
import "@/assets/js/imagesloaded.pkgd.min.js";
import "@/assets/js/isotope.pkgd.min.js";
import "@/assets/js/dark-rtl.js";
import "@/assets/js/active.js";
import { mapActions, mapGetters } from 'vuex';
import { firebase, VAPID_KEY } from "@/utils";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: 'BaseTemplate',
  methods: {
    ...mapActions([
      "getAuthUser",
      "saveProfile",
      "updateSettings"
    ]),
    ...mapGetters([
      "user",
      "auth",
      "appConfig"
    ]),
    login() {
      this.$router.push('/profile')
    },
    requestPermission() {
        console.log("Requesting permission...");
        Notification && Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                console.log("Notification permission granted.");
            }
        });
    }
  },
  data() {
    return {
      fcm_token: null
    }
  },
  mounted() {
    const self = this;
    if ( Object.keys(this.user()).length === 0 || Object.keys(this.auth()).length === 0 ) {
      const self = this;
      this.getAuthUser().then( res => {
        // console.log(res)
        const successCallback = (position) => {
          const { coords } = position;
          const { latitude, longitude } = coords
          self.saveProfile({
            latitude: String(latitude), 
            longitude: String(longitude)
          });
        };

        const errorCallback = (error) => {
          console.log(error);
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      });
    }

    const messaging = getMessaging(firebase);
    if ( !this.fcm_token ) {
      getToken(messaging, {
        vapidKey: VAPID_KEY,
      })
      .then((fcm_token) => {
        if (fcm_token) {
          this.fcm_token = fcm_token
          // Send the token to your server and update the UI if necessary
          this.updateSettings({fcm_token})
        } else {
          // Show permission request UI
          console.log(
              "No registration token available. Request permission to generate one."
          );
          this.requestPermission();
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
    }

    onMessage(messaging, function ({ notification }) {
      const { logo } = self.appConfig();
      new Notification(notification.title, {
          body: notification.body,
          icon: logo || require("../../assets/jawo.png")
      });
    });

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
