<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="/login">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>
    <div class="login-wrapper d-flex align-items-center justify-content-center">
      <div class="custom-container">
        <div class="text-center px-4"><img class="login-intro-img" src="../../../assets/images/auth/37.png" alt=""></div>
        <!-- Register Form -->
        <div class="register-form mt-4">
          <form action="page-forget-password-success.html">
            <div class="form-group text-start mb-3">
              <input :class="`form-control ${ forgot.email ? 'form-control-clicked' : ''}`" type="email" v-model="v$.forgot.email.$model" placeholder="Enter your email address">

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.forgot.email.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>

            </div>

            <app-button @click="forgotPass" :title="'Reset Password'" :loading="loaders().forgotPassword" :disabled="loaders().forgotPassword || v$.forgot.$invalid" ></app-button>

            <!-- <button class="btn btn-success w-100" :disabled="loaders().forgotPassword || v$.forgot.$invalid" type="button" @click="forgotPass">
              {{ loaders().authUser ? 'loading...' : 'Reset Password' }}
            </button> -->

          </form>
        </div>
      </div>
    </div>
  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue';
import AppButton from '@/components/common/form/AppButton.vue';
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  name: 'ForgotPassword',
  components: {
    CentralizedTemplate,
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      forgot: {}
    }
  },
  validations() {
    return {
      forgot: {
        email: {
           required, email 
        }
      },
    }
  },
  methods: {
    ...mapActions([
      "forgotPassword"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    forgotPass: async function() {
      // 
      const data = {
        email: this.forgot.email
      };
      const self = this;
      try {
        const res = await this.forgotPassword(data); //.then( function (res) {
        console.log(res);
        self.$router.push({
          path: '/otp-confirm-page', 
          params: data,
          query: data
        })
        //});
      } catch ( err ) {
        console.log(err)
      }
    },
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
