<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay pb-4" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-4 pb-5">
            <h6 class="mb-3 text-white">Transaction Summary.</h6>

            <div class="btn-group" role="group" aria-label="Basic outlined example">
              <button class="btn btn-outline-warning" style="border-right: 1px" @click="load24hrsTransactions()">last 24hr</button>
              <button class="btn btn-outline-warning" @click="load1WeekTransactions()">1 week</button>
              <button class="btn btn-outline-warning" @click="load30DaysTransactions()">30 days</button>
            </div>

          </div>
        </div>
      </div>

      <div class="notification-area">    
        <div class="container">

          <div class="card mb-4">
            <div class="card-body direction-rtl">
              <div class="search-form-wrapper">
                <p class="mb-2 fz-12">Search transaction record</p>
                <!-- Search Form -->
                <form class=" border-bottom" action="#">
                  <div class="input-group">
                    <input class="form-control form-control-clicked" type="search" v-model="search" :onkeyup="searchTransactions" placeholder="Search...">
                    <button class="btn btn-dark" type="submit"><i class="bi bi-search fz-14"></i></button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          
          
         <transaction-list :transactions="transactionList"></transaction-list>

         <div class="d-flex justify-content-center">
            <button class="btn m-1 btn-sm btn-warning shadow" :disabled="transactions().current_page === 1" @click="loadMoretransaction({...query, page: transactions().current_page-1})">
              Prev
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" :disabled="transactions().current_page === transactions().last_page" @click="loadMoretransaction({...query, page: transactions().current_page+1})">
              Next
            </button>
          </div>

        </div>
      </div>

    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import TransactionList from '@/components/pages/transaction/TransactionList.vue'
import { mapActions, mapGetters } from 'vuex'
import { ACCOUNT_TYPES } from "../../../store/states/account.state"
import moment from 'moment'

export default {
  name: 'Transaction',
  components: {
    PageTemplate,
    TransactionList
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      query: {},
      banner: banner || require("@/assets/images/common/22.jpeg"),
      transactionList: [],
      timer: null,
      search: ""
    }
  },
  mounted() {
    this.initQuery()
    this.getTransactions(this.query).then(() => {
      this.filterTransaction();
    })
  },
  methods: {
    ...mapActions([
      "getTransactions"
    ]),
    ...mapGetters([
      "transactions",
      "activeAccount",
      "appConfig"
    ]),
    initQuery() {
      let category = this.activeAccount();
      if ( category === ACCOUNT_TYPES.HOME ) {
        category = null
      } else if ( category === ACCOUNT_TYPES.INVESTMENT ) {
        category = ACCOUNT_TYPES.CRYPTO
      }
      
      this.query = {
        ...this.query,
        category
      }
    },
    loadMoretransaction(query) {
      // console.log(this.query, query)
      const self = this;
      this.getTransactions(query).then(() => {
        self.filterTransaction();
      })
    },
    load24hrsTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")
      // this.query = `${this.query}&from=${from}&to=${to}`
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    load1WeekTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'week').format("YYYY-MM-DD")
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    load30DaysTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'M').format("YYYY-MM-DD")
      // this.query = `${this.query}&from=${from}&to=${to}`
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    searchTransactions(e = null) {
      const value = e === null ? this.search : e.target.value;
      this.filterTransaction(value)
    },
    filterTransaction(search = null) {
      if (!this.transactions().data || this.transactions().data.length === 0) {
        this.transactionList = [];
        return;
      }

      const transactions = this.transactions().data;

      if (search === null) {
        this.transactionList = transactions;
        return;
      }

      this.transactionList = transactions.filter( transaction => (
        transaction.category.includes(search) ||
        transaction.description.includes(search) ||
        `${transaction.net_amount}`.includes(search) ||
        `${transaction.gross_amount}`.includes(search) ||
        transaction.reference.includes(search) ||
        transaction.status.includes(search) ||
        transaction.type.includes(search) 
      ));

      this.query = {
        search
      }
      if ( this.timer !== null ) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.loadMoretransaction(this.query)
      }, 2000)
      

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px;
  overflow: hidden;
}
.notification-area {
  position: relative;
  margin-top: -63px;
  z-index: 10;
}
</style>
