<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-5">
            <h3 class="mb-3 text-white">Loan List</h3><router-link class="btn btn-warning" to="/loans">Request a loan</router-link>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <loan-list></loan-list>
      </div>

    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import LoanList from '@/components/common/loan/LoanList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserLoanList',
  components: {
    PageTemplate,
    LoanList
  },
  data() {
    const { banner } = this.appConfig();
    return {
      banner: banner || require("@/assets/images/common/22.jpeg"),
    }
  },
  methods: {
    ...mapGetters(["appConfig"])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header h6 {
  color: var(--jw-text-success) !important;
}
</style>
