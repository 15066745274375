import { FetchSell, FetchSend, FetchWithdraw, Sell, Send, WalletInquiry, WalletTransfer, Withdraw, ChangePin, TransactionPinStatus, ConfirmPin } from "@/services/withdraw";

export const withdrawToBank = (context, data) => {
    context.commit("updateLoader", { withdraw: true });
    return new Promise((resolve, reject) => {
        Withdraw(data)
            .then(res => {
                context.commit("withdraw", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { withdraw: false });
            });
    });
};

export const sellCoin = (context, data) => {
    context.commit("updateLoader", { sell: true });
    return new Promise((resolve, reject) => {
        Sell(data)
            .then(res => {
                context.commit("sell", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { sell: false });
            });
    });
};

export const sendCoin = (context, data) => {
    context.commit("updateLoader", { send: true });
    return new Promise((resolve, reject) => {
        Send(data)
            .then(res => {
                context.commit("send", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { send: false });
            });
    });
};


export const fetchWithdraw = (context) => {
    context.commit("updateLoader", { fetchWithdraw: true });
    return new Promise((resolve, reject) => {
        FetchWithdraw()
            .then(res => {
                context.commit("fetchWithdraw", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchWithdraw: false });
            });
    });
};

export const fetchSell = (context) => {
    context.commit("updateLoader", { fetchSell: true });
    return new Promise((resolve, reject) => {
        FetchSell()
            .then(res => {
                context.commit("fetchSell", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchSell: false });
            });
    });
};

export const fetchSend = (context) => {
    context.commit("updateLoader", { fetchSend: true });
    return new Promise((resolve, reject) => {
        FetchSend()
            .then(res => {
                context.commit("fetchSend", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchSend: false });
            });
    });
};

export const walletInquiry = (context, data) => {
    context.commit("updateLoader", { walletInquiry: true });
    return new Promise((resolve, reject) => {
        WalletInquiry(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { walletInquiry: false });
            });
    });
};

export const walletTransfer = (context, data) => {
    context.commit("updateLoader", { walletTransfer: true });
    return new Promise((resolve, reject) => {
        WalletTransfer(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { walletTransfer: false });
            });
    });
};

export const changePin = (context, data) => {
    context.commit("updateLoader", { changePin: true });
    return new Promise((resolve, reject) => {
        ChangePin(data)
            .then(res => {
                // context.commit("changePin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { changePin: false });
            });
    });
};

export const confirmPin = (context, data) => {
    context.commit("updateLoader", { confirmPin: true });
    return new Promise((resolve, reject) => {
        ConfirmPin(data)
            .then(res => {
                // context.commit("confirmPin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmPin: false });
            });
    });
};

export const transactionPinStatus = (context, data) => {
    context.commit("updateLoader", { transactionPinStatus: true });
    return new Promise((resolve, reject) => {
        TransactionPinStatus(data)
            .then(res => {
                context.commit("transactionPinStatus", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { transactionPinStatus: false });
            });
    });
};

const WithdrawAction = {
    withdrawToBank,
    sellCoin,
    sendCoin,
    fetchSend,
    fetchSell,
    fetchWithdraw,
    walletInquiry,
    walletTransfer,
    changePin,
    confirmPin,
    transactionPinStatus,
};

export default WithdrawAction;