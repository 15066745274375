export const fetchBanks = (state, banks) => {
    state.banks = banks;
};

export const fetchBanksWithUSSD = (state, banks) => {
    state.banksWithUSSD = banks;
};

export const fetchBankAccount = (state, bankAccounts) => {
    state.bankAccounts = bankAccounts;
};


export const addBankAccount = (state, bankAccount) => {
    if ( state.bankAccounts && !state.bankAccounts.data) {
        state.bankAccounts.data = []
    }
    state.bankAccounts.data = [...state.bankAccounts.data, bankAccount];
};

export const updateBankAccount = (state, bankAccount) => {
    const bankAccountIndex = state.bankAccounts.data.findIndex(
        (_bankAccount) => _bankAccount.id === bankAccount.id
    );
    if (bankAccountIndex < 0) {
        return;
    }
    state.bankAccounts.data[bankAccountIndex] = bankAccount;
};

export const deleteBankAccount = (state, bankAccount) => {
    const bankAccounts = state.bankAccounts.data.filter(
        (_bankAccount) => _bankAccount.id !== bankAccount.id
    );
    state.bankAccounts.data = bankAccounts;
};


const bankAccountMutation = {
    fetchBanks,
    fetchBanksWithUSSD,
    fetchBankAccount,
    addBankAccount,
    updateBankAccount,
    deleteBankAccount,
};

export default bankAccountMutation;