import { GetRates } from "@/services/rate";

export const getRates = (context) => {
    context.commit("updateLoader", { getRates: true });
    return new Promise((resolve, reject) => {
        GetRates()
            .then(res => {
                const result = res.data.data;
                context.commit("getRates", result);
                resolve(result);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getRates: false });
            });
    });
};

export const getRate = (context, data) => {
    context.commit("updateLoader", { getRate: true });
    return new Promise((resolve, reject) => {
        GetRates(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("getRate", result);
                resolve(result);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getRate: false });
            });
    });
};




const RateAction = {
    getRate,
    getRates
};

export default RateAction;