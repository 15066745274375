<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>

    <div class="login-wrapper d-flex align-items-center justify-content-center text-center">
      <div class="container">
        <div class="text-center">
          <!-- <img class="login-intro-img mb-4" src="../../../assets/images/splash/19.png" alt=""> -->
          <h3>Set up Security Questions</h3>
          <p class="mb-4">Let us know your risk tolerance. </p>
          
          


          <div class="container px-0">
            <div class="card user-data-card mb-3">
              <div class="card-body">
                <form>

                  <div class="form-group mb-3">
                    <label class="form-label" for="question">Question One</label>
                    <select :disabled="loaders().answerSecurityQuestion" v-model="v$.answerQuestion.question.$model" class="form-control" id="question">
                      <option v-for="(question, i) in securityQuestions()" :key="i" :value="question.id">{{ question.question }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-label" for="answer-one">Provide Answer</label>
                    <input class="form-control" :disabled="loaders().answerSecurityQuestion" id="answer-one" v-model="v$.answerQuestion.answer.$model" type="text" @change="submitAnswer">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.answerQuestion.answer.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>

                  <!-- <app-button @click="createBankAccount" :title="'Add Bank Account'" :loading="loaders().answerSecurityQuestion" :disabled="loaders().answerSecurityQuestion || v$.answerQuestion.$invalid" ></app-button> -->
                </form>
              </div>
            </div>

            <div class="card user-data-card mb-3">
              <div class="card-body">
                <form>

                  <div class="form-group mb-3">
                    <label class="form-label" for="question">Question Two</label>
                    <select :disabled="loaders().answerSecurityQuestion" v-model="v$.answerQuestion.question.$model" class="form-control" id="question">
                      <option v-for="(question, i) in securityQuestions()" :key="i" :value="question.id">{{ question.question }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-label" for="answer-one">Provide Answer</label>
                    <input class="form-control" :disabled="loaders().answerSecurityQuestion" id="answer-one" v-model="v$.answerQuestion.answer.$model" type="text" @change="submitAnswer">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.answerQuestion.answer.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>

                  <!-- <app-button @click="createBankAccount" :title="'Add Bank Account'" :loading="loaders().answerSecurityQuestion" :disabled="loaders().answerSecurityQuestion || v$.answerQuestion.$invalid" ></app-button> -->
                </form>
              </div>
            </div>

            <div class="card user-data-card mb-3">
              <div class="card-body">
                <form>

                  <div class="form-group mb-3">
                    <label class="form-label" for="question">Question Three</label>
                    <select :disabled="loaders().answerSecurityQuestion" v-model="v$.answerQuestion.question.$model" class="form-control" id="question">
                      <option v-for="(question, i) in securityQuestions()" :key="i" :value="question.id">{{ question.question }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-label" for="answer-one">Provide Answer</label>
                    <input class="form-control" :disabled="loaders().answerSecurityQuestion" id="answer-one" v-model="v$.answerQuestion.answer.$model" type="text" @change="submitAnswer">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.answerQuestion.answer.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>

                  <!-- <app-button @click="createBankAccount" :title="'Add Bank Account'" :loading="loaders().answerSecurityQuestion" :disabled="loaders().answerSecurityQuestion || v$.answerQuestion.$invalid" ></app-button> -->
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue'
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/common/form/AppButton.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'SecurityQuestionPage',
  components: {
    CentralizedTemplate,
    AppButton
  },
  data() {
    return {
      answerQuestion: {},
      userQuestions: []
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      answerQuestion: {
        question: {required},
        answer: { required }
      }
    }
  },
  methods: {
    ...mapActions([
      "getSecurityQuestions",
      "answerSecurityQuestion",
      "fetchUserSecurityQuestion"
    ]),
    ...mapGetters([
      "securityQuestions",
      "loaders"
    ]),
    submitAnswer(id, questionType) {
      const self = this;
      this.selectAnwser(id).then( res => {

        const scores = window.localStorage.getItem("scores") ? JSON.parse(window.localStorage.getItem("scores")) : {}
        scores[questionType] = res;

        window.localStorage.setItem("scores", JSON.stringify(scores))
        if ( self.slider ) {
          const info = self.slider.getInfo();
          if ( info.index < info.slideCount ) {
            self.slider.goTo('next')
          } else {
            self.$router.push({ 
              path: '/success'
            })
          }
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    const self = this;
    this.getSecurityQuestions().then( function( res ) {
      console.log(res)
    });
    const userUuid = window.localStorage.getItem("uuid");
    this.fetchUserSecurityQuestion(userUuid).then ( res => {
      console.log(res)
      self.userQuestions = res;
    })
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}

botton {
  padding: 5px 10px;
}
</style>
