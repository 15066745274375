import http from "./http";

// export const GetAllAirtimes = async(_q) => {
//     return await http.get("/vas/airtime" + _q);
// };

// export const GetAllData = async(_q) => {
//     return await http.get("/vas/data" + _q);
// };

// export const GetAllPower = async(_q) => {
//     return await http.get("/vas/power" + _q);
// };

// export const GetAllCables = async(_q) => {
//     return await http.get("/vas/cable" + _q);
// };

// export const GetAllJamb = async(_q) => {
//     return await http.get("/vas/jamb" + _q);
// };



export const VendAirtime = async data => {
    return await http.post("/vas/airtime", data);
};

export const FetchData = async network => {
    return await http.get("/vas/data/" + network);
};

export const VendData = async data => {
    return await http.post("/vas/data", data);
};

export const FetchPower = async() => {
    return await http.get("/vas/power");
};

export const ValidatePower = async data => {
    return await http.post("/vas/power/validate", data);
};

export const VendPower = async data => {
    return await http.post("/vas/power", data);
};

export const FetchCable = async service => {
    return await http.get("/vas/cable/" + service);
};

export const ValidateCable = async data => {
    return await http.post("/vas/cable/validate", data);
};

export const VendCable = async data => {
    return await http.post("/vas/cable", data);
};