export default function verified({ next, store, _next }) {
    if (!JSON.parse(window.localStorage.getItem("verified"))) {
        // setTimeout(() => {
        //     console.log(store.getters.auth.email)
        // }, 10)
        return _next({
            name: 'otp-confirm-page',
            // params: {email: self.user.email, page: "validate"},
            // query: {email: self.user.email, page: "validate"},
            params: { page: "validate" },
            query: { page: "validate" }
        });
    }

    return next()
}