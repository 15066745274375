import {
    AuthUser,
    AuthUserWithOTP,
    GetAuthUser,
    RegisterUser,
    Logout,
    Verify,
    AuthCode,
    ForgotPassword,
    ResetPassword,
    ValidateCode,
    ChangePassword,
    GetProfile,
    SaveProfile,
    RetrieveAuth,
    IdentityRegistrationInit,
    IdentityRegistrationCreate,
    IdentityAuthInit,
    IdentityAuthCreate,
    UpdatePhone,
    PhoneCode,
    RegisterUserProxy,
    RegisterUserInit,
    SearchUsers
} from "@/services/user";

export const getProfile = (context) => {
    context.commit("updateLoader", { fetchProfile: true });
    return new Promise((resolve, reject) => {
        GetProfile()
            .then((res) => {
                context.commit("fetchProfile", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchProfile: false });
            });
    });
};

export const saveProfile = (context, profile) => {
    context.commit("updateLoader", { saveProfile: true });
    return new Promise((resolve, reject) => {
        SaveProfile(profile)
            .then((res) => {
                context.commit("saveProfile", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { saveProfile: false });
            });
    });
};

export const getAuthUser = (context) => {

    if (Object.values(context.state.auth).length > 0) {
        return;
    }
    context.commit("updateLoader", { fetchAuth: true });
    GetAuthUser()
        .then(res => {
            context.commit("fetchAuth", res.data.data);
            return res.data.data;
        })
        .catch(err => {
            return err;
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { fetchAuth: false });
        });
};

export const authUser = async(context, data) => {
    context.commit("updateLoader", { authUser: true });
    return new Promise((resolve, reject) => {
        AuthUser(data)
            .then(res => {
                const data = res.data.data;
                console.log(data)
                if (data.profile) {
                    context.commit("fetchProfile", data.profile)
                }
                context.commit("authUser", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authUser: false });
            });
    });
};

export const authUserWithOTP = async(context, data) => {
    context.commit("updateLoader", { authUser: true });
    return new Promise((resolve, reject) => {
        AuthUserWithOTP(data)
            .then(res => {
                const data = res.data.data;
                console.log(data)
                if (data.profile) {
                    context.commit("fetchProfile", data.profile)
                }
                context.commit("authUser", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authUser: false });
            });
    });
};

export const retrieveAuth = async(context, auth_code) => {
    context.commit("updateLoader", { authUser: true });
    return new Promise((resolve, reject) => {
        RetrieveAuth(auth_code)
            .then(res => {
                const data = res.data.data;
                if (data.profile)
                    context.commit("fetchProfile", data.profile)
                context.commit("authUser", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authUser: false });
            });
    });
};

export const logout = (context) => {
    // console.log(context.state.auth)
    // if (Object.values(context.state.auth).length > 0) {
    //     return;
    // }
    context.commit("updateLoader", { logout: true });
    context.commit("logout", {});
    return new Promise((resolve, reject) => {
        Logout()
            .then(res => {
                context.commit("logout", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { logout: false });
            });
    });
};

export const registerUser = (context, data) => {
    context.commit("updateLoader", { registerUser: true });

    return new Promise((resolve, reject) => {
        RegisterUser(data)
            .then(res => {
                const data = res.data.data;
                if (data.token) {
                    context.commit("authUser", data);
                } else {
                    context.commit("registerUser", data);
                }
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { registerUser: false });
            });
    });
};

export const registerUserInit = (context, data) => {
    context.commit("updateLoader", { registerUserInit: true });

    return new Promise((resolve, reject) => {
        RegisterUserInit(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { registerUserInit: false });
            });
    });
};

export const registerUserProxy = (context, data) => {
    context.commit("updateLoader", { registerUserProxy: true });

    return new Promise((resolve, reject) => {
        RegisterUserProxy(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { registerUserProxy: false });
            });
    });
};

export const searchUsers = (context, data) => {
    context.commit("updateLoader", { searchUsers: true });

    return new Promise((resolve, reject) => {
        SearchUsers(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { searchUsers: false });
            });
    });
};

export const verifyUser = (context, data) => {
    context.commit("updateLoader", { verifyUser: true });
    return new Promise((resolve, reject) => {
        Verify(data)
            .then(res => {
                context.commit("verifyUser", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { verifyUser: false });
            });
    });
};

export const authCode = (context, data) => {
    context.commit("updateLoader", { authCode: true });
    return new Promise((resolve, reject) => {
        AuthCode(data)
            .then(res => {
                context.commit("authCode", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authCode: false });
            });
    });
};

export const validateCode = (context, data) => {
    context.commit("updateLoader", { authCode: true });
    return new Promise((resolve, reject) => {
        ValidateCode(data)
            .then(res => {
                context.commit("authCode", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authCode: false });
            });
    })
};

export const forgotPassword = (context, data) => {
    context.commit("updateLoader", { forgotPassword: true });

    return new Promise((resolve, reject) => {
        ForgotPassword(data)
            .then(res => {
                context.commit("forgotPassword", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { forgotPassword: false });
            });
    });

};

export const resetPassword = (context, data) => {
    context.commit("updateLoader", { resetPassword: true });
    return new Promise((resolve, reject) => {
        ResetPassword(data)
            .then(res => {
                context.commit("resetPassword", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { resetPassword: false });
            });
    });
};

export const changePassword = (context, data) => {
    context.commit("updateLoader", { resetPassword: true });
    return new Promise((resolve, reject) => {
        ChangePassword(data)
            .then(res => {
                context.commit("resetPassword", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { resetPassword: false });
            });
    });
};

export const phoneCode = (context, data) => {
    context.commit("updateLoader", { phoneCode: true });
    return new Promise((resolve, reject) => {
        PhoneCode(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { phoneCode: false });
            });
    });
};

export const updatePhone = (context, data) => {
    context.commit("updateLoader", { updatePhone: true });
    return new Promise((resolve, reject) => {
        UpdatePhone(data)
            .then(res => {
                context.commit("fetchProfile", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updatePhone: false });
            });
    });
};

export const identityRegistrationInit = (context, data) => {
    context.commit("updateLoader", { identityRegistrationInit: true });

    return new Promise((resolve, reject) => {
        IdentityRegistrationInit(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { identityRegistrationInit: false });
            });
    });
};

export const identityRegistrationCreate = (context, data) => {
    context.commit("updateLoader", { identityRegistrationCreate: true });

    return new Promise((resolve, reject) => {
        IdentityRegistrationCreate(data)
            .then(res => {
                context.commit("authUser", res.data.data);
                resolve(res.data.token);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { identityRegistrationCreate: false });
            });
    });
};

export const identityAuthInit = (context, data) => {
    context.commit("updateLoader", { identityAuthInit: true });

    return new Promise((resolve, reject) => {
        IdentityAuthInit(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { identityAuthInit: false });
            });
    });
};

export const identityAuthCreate = (context, data) => {
    context.commit("updateLoader", { identityAuthCreate: true });

    return new Promise((resolve, reject) => {
        IdentityAuthCreate(data)
            .then(res => {
                context.commit("authUser", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { identityAuthCreate: false });
            });
    });
};

const UserAction = {
    getAuthUser,
    authUser,
    authUserWithOTP,
    registerUser,
    verifyUser,
    validateCode,
    getProfile,
    saveProfile,
    authCode,
    forgotPassword,
    resetPassword,
    changePassword,
    logout,
    retrieveAuth,
    updatePhone,
    phoneCode,
    registerUserProxy,
    registerUserInit,
    searchUsers,
    identityRegistrationInit,
    identityRegistrationCreate,
    identityAuthInit,
    identityAuthCreate
};

export default UserAction;