import { StreamChat } from 'stream-chat';
import { STREAM_CHAT_API_KEY, avatarRight } from "../constant"
import { AuthChat } from '@/services/user';
import store from '@/store';

export class StreamChatProvider {

    constructor() {
        if (StreamChatProvider.instance) {
            return StreamChatProvider.instance;
        }
        const client = StreamChat.getInstance(STREAM_CHAT_API_KEY, { enableWSFallBack: true });
        this.client = client;
        const self = this
        this.connectUser().then( res => {
            const { me } = res
            self.user = me
            const { uuid: businessUUID, name: businessName } = store.getters.auth.cluster || {}
            self.channel = client.channel('messaging', 
                businessUUID, // change to business uuid
                // {members: [me.id]}, 
                {
                    name: businessName,
                }
            );
            self.channel.watch();
            self.listenForNewMessages();
        });
        StreamChatProvider.instance = this;
    }

    static getInstance() {
        if (StreamChatProvider.instance) return StreamChatProvider.instance
        return new StreamChatProvider()
    }

    async connectUser() {
        const { data } = await AuthChat();
        const { uuid, name } = store.state.auth
        const { avatar } = store.getters.chatAvatar

        if (!avatar) store.commit("setChatAvatar", avatarRight);
    
        return await this.client
        .connectUser({
            id: uuid,
            name,
            image: avatar || avatarRight,
        },data.data);
    }

    async send(message, option = {}) {
        await StreamChatProvider.getInstance().channel.sendMessage({message, ...option});
    }

    async sendImage(e) {
        const files = e.target.files;
        return await StreamChatProvider.getInstance().channel.sendImage(files[0]);
    }

    getMessages() {
        return store.state.messages;
    }

    listenForNewMessages() {
        this.channel.on('message.new', event => {
            const { message } = event;
            // console.log(message)
            const { user } = message;
            const userid = user.id
            const { uuid } = store.state.auth
            if ( uuid !== userid ) {
                store.commit("pushNewMessage", event.message)
            }
            const chatDiv = document.getElementById("chat-container");
            chatDiv.scrollTop = chatDiv.scrollHeight;
        });
    }

    async getChannels(cid = null) {
        if ( !this.channel ) {
            const self = this
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(self.getChannels())
                }, 2000);
            })
        }
        const filter = { type: 'messaging' };
        if (cid) {filter.id = { $in: [cid] }}
        
        const sort = [{ last_message_at: -1 }];

        return await this.client.queryChannels(filter, sort, {
            watch: true, // this is the default
            // state: true,
        });
    }

    async joinChannel({cid, userId}) {
        
        const channels = await this.getChannels(cid)
        if ( channels.length === 0) {
            throw Error("No channel found")
        }
        const channel = channels[0]
        // console.log(channel)

        store.commit("fetchMessages", [
            ...channel.state.latestMessages,
            ...this.getMessages()
        ]);

        StreamChatProvider.getInstance().channel = channel;
        StreamChatProvider.getInstance().listenForNewMessages();

        return await channel.addMembers([userId]);
    }

    async getUser() {
        if ( !this.user ) {
            const self = this
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(self.getUser())
                }, 2000);
            })
        }

        return this.user;
    }
}
