import store from "@/store";
import { avatarRight, avatarLeft } from "./constant";

export default class Chat {
    constructor(provider){
        this.provider = provider;
        this.loading = false;
        this.responses = {};
    }
    
    async send(message, option = {}) {
        await this.provider.send(message, option)
    }

    sendMessage(e){
        if ( e.keyCode !== 13 ) {
            return
        }
        const message = e.target.value;
        const user = store.state.auth
        user.id = user.uuid
        user.image = this.myAvatar()
        const status = {
            isSending: true,
            isSent: false,
            isFailed: false
        };
        const d = new Date();
        store.commit("pushNewMessage", {message, user, status, created_at: d.toISOString()})
        const index = store.state.messages.length - 1;
        // document.getElementById("message-input-feild").value = ""
        e.target.value = ""
        this.responses = status
        this.send(message).then(() => {
            this.responses = {
                ...this.responses,
                isSent: true
            }
        }).catch(() => {
            this.responses = {
                ...this.responses,
                isFailed: true
            }
        })
        .finally(() => {
            this.responses = {
                ...this.responses,
                isSending: false
            };
            const status = this.responses
            store.commit("updateMessageAtIndex", {
                index, 
                message: {
                    ...store.state.messages[index],
                    status
                }
            });
            const chatDiv = document.getElementById("chat-container");
            chatDiv.scrollTop = chatDiv.scrollHeight;
        });
    }

    sendImage(e) {
        const files = e.target.files;
        const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/gif','image/bmp', 'application/pdf'];
        const file = files[0];
        const allowSize = 12; //in mb
        const { type, size } = file;
        
        if ( !allowedExtension.includes(type) || size/(1024*1024) > allowSize ) {
            return;
        }

        this.loading = true;
        this.provider.sendImage(e).then( r => {
            const { file, duration } = r;
            e.target.value = ""
            // document.getElementById("message-input-feild").value = ""
            const self = this
            const status = {
                isSending: true,
                isSent: false,
                isFailed: false
            };
            this.responses = status
            const message = "file";
            const user = store.state.auth
            user.id = user.uuid
            user.image = this.myAvatar()
            const attachments = [{
                type: type.split("/")[0],
                asset_url: file,
                thumb_url: file,
            }];
            const d = new Date();
            store.commit("pushNewMessage", {message, attachments, user, status, created_at: d.toISOString()});
            const index = store.state.messages.length - 1;

            this.send(`file`, {attachments}).then(() => {
                self.responses = {
                    ...this.responses,
                    isSent: true
                }
            }).catch(() => {
                self.responses = {
                    ...this.responses,
                    isFailed: true
                }
            })
            .finally(() => {
                this.responses = {
                    ...this.responses,
                    isSending: false
                };
                const status = this.responses
                store.commit("updateMessageAtIndex", {
                    index, 
                    message: {
                        ...store.state.messages[index],
                        status
                    }
                });
            })
        }).finally(() => {
            this.loading = false
        })
    }

    messages() {
        return this.provider.getMessages()
    }

    avatar() {
        return avatarLeft
    }

    myAvatar() {
        const avatar = store.getters.chatAvatar;
        if (!!avatar) return avatar;
        store.commit("setChatAvatar", avatarRight)
        return avatarRight
    }
    
    channels() {
        return this.provider.getChannels()
    }

    async join(data) {
        return await this.provider.joinChannel(data)
    }

    async user() {
        return await this.provider.getUser()
    }
}