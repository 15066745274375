export const fetchSettings = (state, settings) => {
    state.settings = settings;
};

export const addSettings = (state, setting) => {
    state.settings = state.settings.push(setting);
};

export const updateSettings = (state, setting) => {
    state.settings = setting;
};

export const deleteSettings = (state, setting) => {
    const settings = state.settings.filter(
        (_setting) => _setting.id !== setting.id
    );
    state.settings = settings;
};

export const fetchSecurityQuestions = (state, securityQuestions) => {
    state.securityQuestions = securityQuestions;
}


const SettingsMutation = {
    fetchSettings,
    addSettings,
    updateSettings,
    deleteSettings,
    fetchSecurityQuestions
};

export default SettingsMutation;