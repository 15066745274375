<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay pb-4 fixed" :style="{'background-image': `url(${banner})`, 'z-index': '0'}">
        <div class="container">
          <div class="card-body px-4 pb-5">
            <h6 class="mb-3 text-white">Hi, 
              <span v-if="Object.values(auth()).length > 0">{{ auth().name }}</span>
              <div v-else class="spinner-border spinner-border-sm text-warning" role="status">
                <span class="sr-only"></span>
              </div>
            </h6>
            <div class="btn-group" role="group" aria-label="main action button">
              <button v-if="ACCOUNT_TYPES.HOME === activeAccount()" @click="toggleVisibility(visibility() === 'no' ? 'yes': 'no')" class="btn btn-warning btn-sm" to="/plans">
                {{ visibility() && visibility() === 'no' ? 'show balance' : 'hide balance' }}
              </button>
              <router-link v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount()" class="btn btn-warning btn-sm" to="/plans">Start Investing</router-link>
              <router-link v-if="ACCOUNT_TYPES.SAVINGS === activeAccount()" class="btn btn-warning btn-sm" to="/saves">Start Saving</router-link>
              <router-link v-if="ACCOUNT_TYPES.LOAN === activeAccount()" class="btn btn-warning btn-sm" to="/loans">Take Loan</router-link>
              <button 
                class="btn btn-outline-warning btn-sm" 
                data-bs-toggle="modal" 
                data-bs-target="#save-usd-modal"
              >Preserve Naira</button>
              <!-- <router-link :to="{path: '/bank-withdraw-success',query: { amount: '2000', currency: 'N', bank: 'text' }}">test</router-link> -->
            </div>
            <!-- <div class="btn-group" role="group" aria-label="Basic outlined example">
              <router-link v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount()" class="btn btn-outline-warning" style="border-right: 1px" to="/plans">Start Plan</router-link>
              <router-link v-if="ACCOUNT_TYPES.SAVINGS === activeAccount()" class="btn btn-outline-warning" to="/saves">Start Saving</router-link>
            </div> -->
          </div>
        </div>
      </div>

      <div class="card-section">
        <balance @page-change="(_) => loadTransaction()"></balance>
        <Transition name="bounce">
          <transaction-pin></transaction-pin>
        </Transition>

        <Transition name="slide-fade">
          <prediction v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount()"></prediction>
        </Transition>

        <TransitionGroup name="slide-fade">
          <plan-list v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount()"></plan-list>
          <loan-list v-if="ACCOUNT_TYPES.LOAN === activeAccount()"></loan-list>
          <save-list v-if="ACCOUNT_TYPES.SAVINGS === activeAccount()"></save-list>
          <div class="notification-area mt-4" v-if="ACCOUNT_TYPES.HOME === activeAccount()">
            <div class="container">
              <transaction-list :transactions="transactions().data || []"></transaction-list>
              <div class="d-flex justify-content-center">
              <router-link class="btn m-1 btn-sm btn-warning shadow" to="/transaction">
                see more
              </router-link>
            </div>
            </div>
          </div>
        </TransitionGroup>

        <div class="my-6 py-2"></div>
      </div>

      <modal title="Payment Method" id="save-usd-modal">
        <div class="text-center"> 
          <h6>We preserve your naira in USD value.</h6>
        </div>
        <div class="text-center">  
          <form>
            <div class="form-group">
              <label class="form-label" for="fund-amount">Amount (NGN)</label>
              <input class="form-control form-control-clicked" id="fund-amount" type="number" placeholder="100" v-model="v$.fund_amount.$model" >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fund_amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <router-link 
              class="my-1 btn btn-warning w-100 shadow" 
              v-if="!v$.fund_amount.$invalid"
              :to="{ path: 'saves', query: { amount: fund_amount, funding_source: 'bank', name: 'Preserve my Naira Funds', plan: usd_plan_id }}"
              @click="handleCloseSaveModal"
            >continue</router-link>
            <!-- <router-link 
              v-if="!v$.fund_amount.$invalid"
              class="my-1 btn btn-warning w-100 shadow" 
              :to="`/saves?amount=${fund_amount}&funding_source=bank&name=Preserve my Naira Funds&plan=5`">
              Continue
            </router-link> -->
          </form>
        </div>
      </modal>
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import Balance from '@/components/common/wallet/Balance.vue'
import TransactionPin from '@/components/common/wallet/TransactionPin.vue'
import Prediction from '@/components/common/coin/Prediction.vue'
import PlanList from '@/components/common/plan/PlanList.vue'
import SaveList from '@/components/common/save/SaveList.vue'
import LoanList from '@/components/common/loan/LoanList.vue'
import { mapActions, mapGetters } from 'vuex'
import { ACCOUNT_TYPES } from "../../../store/states/account.state"
import TransactionList from '@/components/pages/transaction/TransactionList.vue'
import Modal from '@/components/common/modal/Modal.vue'
import useVuelidate from '@vuelidate/core';
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import { required, minLength } from '@vuelidate/validators';
import { USD_SAVINGS_ID } from '@/utils';

export default {
  name: 'Dashboard',
  components: {
    PageTemplate,
    Balance,
    Prediction,
    PlanList,
    SaveList,
    LoanList,
    TransactionList,
    Modal,
    TransactionPin
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      fund_amount: { required, min: minLength(3) },
    }
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      fund_amount: null,
      ACCOUNT_TYPES,
      banner: banner || require("@/assets/images/common/22.jpeg"),
      usd_plan_id: USD_SAVINGS_ID
    }
  },
  mounted() {
    this.getAuthUser();
    this.loadTransaction()
  },
  methods: {
    ...mapActions([
      "getAuthUser",
      "getTransactions",
      "toggleVisibility"
    ]),
    ...mapGetters([
      "auth",
      "activeAccount",
      "transactions",
      "visibility",
      "appConfig"
    ]),
    handleCloseSaveModal() {
      const saveUSDModal = document.getElementById('save-usd-modal');
      if (saveUSDModal) {
        const _saveUSDModal = bootstrapBundleMin.Modal.getInstance(saveUSDModal)
        _saveUSDModal.hide();
      }
    },
    loadTransaction() {
      if ( ACCOUNT_TYPES.HOME === this.activeAccount() ) {
        this.getTransactions({})
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn.btn-warning {
  color: var(--primary-color) !important;
}
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px;
  overflow: hidden;
}
.card-section {
  position: relative;
  margin-top: -63px;
  /* z-index: 1; */
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}


.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
