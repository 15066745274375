import http from "./http";

export const AddSettings = async data => {
    return await http.post("/settings", data);
};

export const UpdateSettings = async data => {
    return await http.put("/settings", data);
};

export const GetSettings = async() => {
    return await http.get("/settings");
};

export const DeleteSettings = async id => {
    return await http.get("/settings/" + id);
};




export const AddSecurityQuestion = async data => {
    return await http.post("/security-questions", data);
};

export const UpdateSecurityQuestion = async data => {
    return await http.put("/security-questions", data);
};

export const GetSecurityQuestion = async() => {
    return await http.get("/security-questions");
};

export const DeleteSecurityQuestion = async id => {
    return await http.get("/security-questions/" + id);
};

export const AnswerSecurityQuestion = async ans => {
    return await http.post("/security-questions/answer", ans);
};

export const FetchUserSecurityQuestion = async user_uuid => {
    return await http.get("/security-questions/user/" + user_uuid);
};