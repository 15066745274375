export const fetchEmails = (state, emails) => {
  state.emails = emails;
};

export const fetchSmS = (state, sms) => {
  state.sms = sms;
};

export const fetchAds = (state, ads) => {
  state.ads = ads;
};

export const fetchMessages = (state, messages) => {
  state.messages = messages;
};

export const setChatAvatar = (state, chatAvatar) => {
  localStorage.setItem("chatAvatar", chatAvatar);
  state.chatAvatar = chatAvatar;
};

export const pushNewAds = (state, ads) => {
  state.ads.content = state.ads.content.push(ads);
};

export const sendEmail = (state, notification) => {
  state.emails.content = state.emails.content.push(notification);
};

export const sendSmS = (state, notification) => {
  state.sms.content = state.sms.content.push(notification);
};

export const pushNewMessage = (state, message) => {
  state.messages = [
    ...state.messages,
    message
  ];
};

export const updateAds = (state, ads) => {
  const adsIdx = state.ads.content.findIndex((_ads) => _ads.id === ads.id);
  if (adsIdx < 0) {
    return;
  }
  state.ads.content[adsIdx] = ads;
};

export const updateMessageAtIndex = (state, {index, message}) => {
  state.messages[index] = message;
};

export const deleteAds = (state, ad) => {
  const ads = state.ads.content.filter((_ads) => _ads.id !== ad.id);

  state.ads.content = ads;
};

export const toggleAds = (state, ads) => updateAds(state, ads);

export const likeAds = (state, ads) => updateAds(state, ads);

const NotificationMutation = {
  fetchEmails,
  fetchSmS,
  fetchAds,
  pushNewAds,
  sendEmail,
  sendSmS,
  updateAds,
  deleteAds,
  toggleAds,
  likeAds,
  pushNewMessage,
  fetchMessages,
  setChatAvatar,
  updateMessageAtIndex
};

export default NotificationMutation;
