import { mapActions, mapGetters } from 'vuex';

export const bankAccount = {
    data() {
        return {
          bankAccountList: []
        }
    },
    mounted() {
        this.getBankAccounts(this.query).then(() => {
          this.filterBankAccount();
        })
    },
    methods: {
        ...mapActions([
          "getBankAccounts"
        ]),
        ...mapGetters([
          "bankAccounts"
        ]),
        loadMoreBankAccounts(query) {
          const self = this;
          this.getBankAccounts(query).then(() => {
            self.filterBankAccount();
          })
        },
        searchBankAccounts(e) {
          const value = e.target.value;
          this.filterBankAccount(value)
        },
        filterBankAccount(search = null) {
          if (!this.bankAccounts().data || this.bankAccounts().data.length === 0) {
            this.bankAccountList = [];
            return;
          }
    
          const bankAccounts = this.bankAccounts().data;
    
          if (search === null) {
            this.bankAccountList = bankAccounts;
            return;
          }
    
          this.bankAccountList = bankAccounts.filter( bankAccount => (
            bankAccount.account_name.includes(search) ||
            bankAccount.account_number.includes(search) ||
            bankAccount.bank_name.includes(search)
          ));
    
          this.query = {
            search
          }
          if ( this.timer !== null ) {
            clearTimeout(this.timer);
          }
    
          this.timer = setTimeout(() => {
            this.loadMoreBankAccounts(this.query)
          }, 2000)
        }
    }
}