<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      

      <div class="container mb-4">
        <div class="card direction-rtl">
          <div class="card-body">
            <h6 class="text-center"> 
              <!-- <i class="bi bi-shield-lock-fill" style="font-size:xx-large"></i>
              <br/> -->
              Start saving today. Choose the most convinient from list of options.
            </h6>
            <div class="d-flex justify-content-center">
              <img src="../../../assets/images/plan/save-up.png" class="img-plan"/>
              <h2 class="text-center text-green-700 bg-gray-700 d-flex align-items-center">
                <!-- USD rate: {{ rates() ? rates()[auth().settings.base_currency].buy_rate : "--" }} -->
                High Yeild
              </h2>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="card">
          <div class="card-body">
            <!-- Price Table One -->
            <div class="price-table-one">
              <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center" id="priceTab" role="tablist">
                <template v-for="(plan, i) in savingPlans() || plans" :key="i">
                  <li
                    class="nav-item" @click="resetDefault()" 
                    role="presentation"
                    v-if="plan.is_active"
                  >
                    <a 
                      class="nav-link shadow" 
                      :id="`priceTab${i}`" 
                      data-bs-toggle="tab" 
                      :href="`#priceTab_${i}`" 
                      role="tab" 
                      :aria-controls="`priceTab_${i}`" 
                      aria-selected="false"
                    >
                      <i class="bi bi-bullseye"></i>
                    </a>
                  </li>
                </template>
                <!-- <li class="nav-item" @click="resetDefault()" role="presentation"><a class="nav-link shadow" id="priceTab0" data-bs-toggle="tab" href="#priceTab_0" role="tab" aria-controls="priceTab_0" aria-selected="false"><i class="bi bi-bullseye"></i></a></li>
                <li class="nav-item" @click="resetDefault()" role="presentation"><a class="nav-link shadow" id="priceTab1" data-bs-toggle="tab" href="#priceTab_1" role="tab" aria-controls="priceTab_1" aria-selected="false"><i class="bi bi-hourglass-split"></i></a></li>
                <li class="nav-item" @click="resetDefault()" role="presentation"><a class="nav-link shadow active" id="priceTab2" data-bs-toggle="tab" href="#priceTab_2" role="tab" aria-controls="priceTab_2" aria-selected="true"><i class="bi bi-key"></i></a></li> -->
              </ul>
              <div class="tab-content" id="priceTabContent">
                <template v-for="(_plan, i) in savingPlans() || plans" :key="i">
                  <div v-if="_plan.is_active" :class="`tab-pane fade ${Object.values(plan).length === 0 ? i === (savingPlans() || plans).length - 1 ? 'active show' : '' : _plan.id === plan.id ? 'active show': ''}`" :id="`priceTab_${i}`" role="tabpanel" aria-labelledby="priceTabOne">
                    <!-- Single Price Table -->
                    <div class="single-price-content shadow-sm">
                      <div class="price"><span class="text-white mb-2">{{ _plan.name }}</span>
                        <h2 class="display-3">{{ _plan.name }} ({{ _plan.base_currency }})</h2>
                        <span class="badge bg-light text-dark rounded-pill mx-1" v-for="(t, j) in JSON.parse(_plan.risk_type)" :key="i + '' + j">{{ t }}</span>
                      </div>
                      <!-- Pricing Desc -->
                      <div class="pricing-desc">
                        <ul class="ps-0">
                          <!-- <li><i class="bi bi-check-lg me-2"></i>{{  auth() ? auth().settings.base_currency : _plan.base_currency }} {{ rates() ? _plan.min_amount * rates()[auth().settings.base_currency].buy_rate : _plan.min_amount }} - {{  auth() ? auth().settings.base_currency : _plan.base_currency }} {{ rates() ? _plan.max_amount * rates()[auth().settings.base_currency].buy_rate : _plan.max_amount }}</li> -->
                          <li><i class="bi bi-check-lg me-2"></i>{{  auth() ? baseCurrency : _plan.base_currency }} {{ rates() ? _plan.min_amount * (activeRate.buy_rate / rates()[_plan.base_currency].buy_rate) : _plan.min_amount }} - {{  auth() ? baseCurrency : _plan.base_currency }} {{ rates() ? _plan.max_amount * (activeRate.buy_rate / rates()[_plan.base_currency].buy_rate) : _plan.max_amount }}</li>
                          <li><i class="bi bi-check-lg me-2"></i>{{ _plan.duration }} Months Savings Plan. </li>
                          <li v-if="auth().settings.base_currency !== _plan.base_currency"><i class="bi bi-check-lg me-2"></i>
                            USD rate: {{ rates() ? rates()[auth().settings.base_currency].buy_rate : "--" }}
                          </li>
                          <li><i class="bi bi-check-lg me-2"></i>This will be saved in {{ _plan.base_currency }}. </li>
                          <template v-if="_plan.coin_types.length > 0">
                            <li v-for="(coin, k) in _plan.coin_types" :key="k"><i class="bi bi-check-lg me-2"></i>You will get {{ (coin && coin.pivot) ? coin.pivot.quantity : 0 }} % {{ coin ? getCoinName(coin.name) : '' }} </li>
                          </template>
                          <template v-for="(d, i) in (_plan.description || '').split('-')" :key="i">
                            <li v-if="d.trim() !== ''"><i class="bi bi-check-lg me-2"></i>{{ d }}</li>
                          </template>
                          <template v-for="(d, i) in (_plan.how_to_buy || '').split('-')" :key="i">
                            <li v-if="d.trim() !== ''"><i class="bi bi-check-lg me-2"></i>{{ d }}</li>
                          </template>
                        </ul>
                      </div>
                      <!-- Purchase -->
                      <div class="purchase">
                        <button class="btn btn-warning btn-lg btn-creative w-100" data-bs-toggle="modal" data-bs-target="#start-save-modal" @click="selectPlan(_plan)">Start Saving Now</button>
                        <!-- <small class="d-block text-white mt-2 ms-1">No credit card required*</small> -->
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="loaders().getPlan">
                  <div class="d-flex flex-column justify-content-center text-center">
                    <div class="my-4 text-center">loading plans</div>
                    <br/>
                    <div class="mb-4 text-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal title="Confirm Savings Plan" id="start-save-modal">
        <div v-if="!stage">
          <div class="text-center"> 
            <h6>
              Confirm {{ plan && plan.name ? plan.name.toLowerCase() : "" }} plan subsciption
            </h6>
            <h2>
              {{ duration || plan.duration }} {{ ( duration_unit || plan.duration_unit ) + ( ( duration || plan.duration ) === 1 ? "": "s") }}
            </h2>
          </div>

          <div class="form-group">
              <label class="form-label" for="exampleInputnumber">What are you saving for?</label>
              <input class="form-control form-control-clicked" id="exampleInputnumber" v-model="v$.name.$model"  type="text" placeholder="House project ...">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
          </div>

          <div class="form-group" v-if="name&&name.length>=3">
              <label class="form-label" for="exampleInputnumber">
                What amount are you saving 
                <span v-if="(duration || plan.duration) === 1">?</span>
                <span v-else>{{ plan.duration_unit === "day" ? 'daily' : `${plan.duration_unit}ly` }}</span>
              </label>
              <input class="form-control form-control-clicked" id="exampleInputnumber" v-model="v$.amount.$model"  type="number" placeholder="0">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
          </div>

          <div v-if="amount>=100">
            <div>
              <!-- <label class="form-label" for="airtime-network">Wallet</label> -->
              <div class="bg-info text-dark rounded mb-2 p-2">
                <div class="mb-1">
                  <small>Deduct from</small>
                </div>
                <label class="form-check-label" for="funding-source">
                  <input class="form-radio-input" name="funding-source" value="card" v-model="v$.funding_source.$model" id="funding-source1" type="radio" aria-label="funding-source"/>&nbsp;
                  <i class="bi bi-credit-card"></i> 
                  card (default) 
                </label>
                &nbsp;
                <label class="form-check-label" for="funding-source">
                  <input class="form-radio-input" name="funding-source" value="NGN" v-model="v$.funding_source.$model" id="funding-source2" type="radio" aria-label="funding-source"/>&nbsp;
                  <i class="bi bi-wallet"></i> 
                  wallet 
                </label>
                &nbsp;
                <label class="form-check-label" for="funding-source">
                  <input class="form-radio-input" name="funding-source" value="bank" v-model="v$.funding_source.$model" id="funding-source3" type="radio" aria-label="funding-source"/>&nbsp;
                  <i class="bi bi-bank"></i>
                  bank 
                </label> 
              </div>
              <div class="form-group mb-3" v-if="!['card', 'bank'].includes(funding_source)">
                <div class="input-group-text select-input">
                  <select class="form-select" :disabled="funding_source==='card'" id="transfer-wallet-fiat" name="transfer-wallet-fiat" aria-label="transfer-wallet-fiat" v-model="v$.funding_source.$model">
                    <!-- <option v-for="(w, i) in Object.values(account().wallet)" :key="i" :value="w.base_currency">{{ w.base_currency }} wallet</option> -->
                    <option v-for="(w, i) in ['NGN']" :key="i" :value="w">{{ w }} wallet {{ `(${account() && account().wallet ? account().wallet[w].ledger_balance  : '0'})`}}</option>
                  </select>
                </div>
              </div>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.funding_source.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-check" v-if="plan.is_flexible || plan.is_group">
              <input 
                class="form-check-input" 
                name="re-occurring" 
                id="setReOccurring" 
                type="checkbox"
                :checked="setReOccurring" 
                @change="handleReOccurring"
              >
              <label class="form-check-label" for="setReOccurring">repeat</label>
            </div>

            <div class="row mt-2" v-if="setReOccurring">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label" for="durationInput">Duration</label>
                  <select class="form-select" id="duration-input" v-model="v$.duration.$model" name="duration-input" aria-label="duration-input">
                    <option v-for="(w, i) in [...Array(30).keys()]" :key="i" :value="w+1">{{ w+1 }}</option>
                  </select>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.duration.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label" for="durationInput">Unit</label>
                  <select class="form-select" id="duration-uint-input" v-model="v$.duration_unit.$model" name="duration-uint-input" aria-label="duration-uint-input">
                    <option v-for="(w, i) in ['day', 'week', 'month']" :key="i" :value="w">{{ w }}</option>
                  </select>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.duration_unit.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-secondary fs-6">
              <span v-if="(duration || plan.duration) === 1">Your {{ funding_source }} {{ funding_source === "card" ? "" : " wallet" }} will be charged once.</span>
              <span v-else>
                Your {{ funding_source }} {{ funding_source === "card" ? "" : " wallet" }} will be charged {{ formatter(ffs).format(amount) }} {{ ffs !== plan.base_currency ? `(${formatter(plan.base_currency).format(amount / (activeRate.buy_rate / rates()[plan.base_currency || baseCurrency].buy_rate))})` : '' }} every {{ duration_unit || plan.duration_unit }} for {{ duration || plan.duration }} {{ duration_unit || plan.duration_unit }}(s)
              </span>
            </div>

            <div class="form-check">
              <input class="form-check-input" id="confirm" type="checkbox" v-model="v$.confirm.$model">
              <label class="form-check-label" for="confirm">confirm action</label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.confirm.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="text-center my-1">
            <!-- <app-button @click="subscribeToPlan" :title="'Confirm'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button> -->
            <app-button @click="handleProceed" :title="'proceed'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button>
          </div>
        </div>

        <div class="card invoice-card shadow" v-else-if="stage === stages.ACCOUNT_DETAILS">
          <div class="card-body">
            <div class="text-center"> 
              <h6 class="mb-2">Account Details</h6>
            </div>
            <hr/>
            <template v-if="loaders().fundAccount">
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only"></span>
              </div>
            </template>
            <template v-else-if="accountDetails() && accountDetails().account_number">
              <b>Pay {{ formatter(ffs || baseCurrency).format(amount) }} to</b>
              <h3 class="display-3"> 
                <span @click="clickToCopy(accountDetails().account_number)">
                  {{ accountDetails().account_number }}
                  <sup class="text-xs">
                    <small>
                      <i class="bi bi-files"></i>
                    </small>
                  </sup>
                </span>
              </h3>
              <div>
                <span v-if="accountDetails() && accountDetails().account_number" class="badge bg-light text-dark rounded-pill">
                  BANK: {{ accountDetails().bank_name }}
                </span>
                <span v-if="accountDetails() && accountDetails().account_number" class="badge bg-light text-dark rounded-pill mt-1">
                  ACCOUNT NAME: {{ accountDetails().account_name }}
                </span>
              </div>
              <!-- Pricing Desc -->
              <div class="pricing-desc">
                <ul class="ps-0">
                  <li><i class="bi bi-check-lg me-2"></i>Amount: {{ ffs }} {{ amount }} </li> 
                  <li><i class="bi bi-check-lg me-2"></i>Reference: {{ accountDetails().initiation_tran_ref || accountDetails().reference }}</li>
                  <!-- -->
                  <li><i class="bi bi-check-lg me-2"></i>Date - {{ (new Date()).toDateString() }}</li>
                  <li><i class="bi bi-check-lg me-2"></i>Processing Fee - NGN 0</li>
                </ul>
              </div>
              <!-- Purchase -->
              <div class="purchase d-flex" v-if="!loaders().fundAccount && accountDetails() && accountDetails().account_number">
                <div class="spinner-grow text-success" role="status"><span class="visually-hidden">Loading...</span></div>
                <p>awaiting payment</p>
              </div>
            </template>
            <div class="d-flex justify-content-end">
              <button class="btn btn-sm" @click="stage=null">back</button>
            </div>
          </div>
        </div>

        <div class="card invoice-card shadow" v-else-if="stage === stages.RECIEPT">
          <div class="card-body">
            <!-- Download Invoice -->
            <div class="download-invoice text-end mb-3">
              <a class="btn btn-sm btn-primary me-2" href="#" @click="printDiv('invoice-div')">
                <i class="bi bi-file-earmark-pdf"></i> PDF
              </a>
              <a class="btn btn-sm btn-light" href="#" @click="printDiv('invoice-div')">
                <i class="bi bi-printer"></i> Print
              </a>
            </div>

            <div id="invoice-div">
              <!-- Invoice Info -->
              <div class="invoice-info text-end mb-4">
                <h5 class="mb-1 fz-14">{{ plan.name }} | {{ name }}</h5>
                <h6 class="fz-12">{{ duration || plan.duration }} {{ duration_unit || plan.duration_unit }}</h6>
                <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
              </div>

              <!-- Invoice Table -->
              <div class="invoice-table">
                <div class="table-responsive">
                  <table class="table table-bordered caption-top">
                    <caption>Payment plan</caption>
                    <thead class="table-light">
                      <tr>
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Q.</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr v-for="(save, i) in plan.duration" :key="i"> -->
                      <tr>
                        <td>1.</td>
                        <td>{{ plan.name }} savings plan subscription for {{ duration || plan.duration }} {{ duration_unit || plan.duration_unit }}</td>
                        <td>{{ duration || plan.duration }} {{ duration_unit || plan.duration_unit }}</td>
                        <td>{{ formatter(ffs).format(amount) }}</td>
                        <td>{{ formatter(ffs).format(amount * ( duration || plan.duration)) }}</td>
                      </tr>
                    </tbody>
                    <tfoot class="table-light">
                      <tr>
                        <td class="text-end" colspan="4">Total:</td>
                        <td class="text-end">{{ formatter(ffs).format(amount * (duration || plan.duration)) }}</td>
                      </tr>
                      <tr>
                        <td class="text-end" colspan="4">Fee {{ funding_source === 'card' ? '(1.4% + 50)' : '0'}}:</td>
                        <td class="text-end">{{ formatter(ffs).format(funding_source === 'card' ? ((amount * ( duration || plan.duration)) + 50) * 0.014 : 0 )}}</td>
                      </tr>
                      <tr>
                        <td class="text-end" colspan="4">Grand Total:</td>
                        <td class="text-end">{{ formatter(ffs).format((amount * ( duration || plan.duration)) + ( funding_source === 'card' ? ((amount * ( duration || plan.duration)) + 50)* 0.014 : 0)) }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div class="text-center my-1 mb-8">
              <app-button @click="subscribeToPlan" :title="'Confirm'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button>
            </div>

            <p class="mb-8">Notice: This is auto generated invoice.</p>
          </div>
          <hr/>
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm" @click="stage=null">back</button>
          </div>
        </div>
      </modal>
      
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import Modal from '@/components/common/modal/Modal.vue';
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { required, alphaNum, numeric, minLength } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
import AppButton from '@/components/common/form/AppButton.vue';
import { PLAN_TYPES, printDiv, formatter } from '@/utils';
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import moment from 'moment'
const toaster = createToaster({ /* options */ });

export default {
  name: 'SaveList',
  components: {
    PageTemplate,
    Modal,
    AppButton
  },
  watch: {
    funding_source(fs, ofs) {
      // if (typeof(this.rates()[fs === "card" ? this.auth() ? this.auth().settings.base_currency : "NGN" : fs]) === 'undefined') {
      //   toaster.show( fs + " not supported yet!" );
      if ( fs === "card" || fs === "bank" ) {
        this.ffs = ["card", "bank"].includes(ofs) ? this.baseCurrency : ofs;
      } else {
        this.ffs = fs;
        this.activeRate = this.rates()[this.baseCurrency] || {}
      }
    },
    setReOccurring(v) {
      if (v) {
        const { duration, duration_unit } = this.plan;
        this.duration = duration;
        this.duration_unit = duration_unit;
      } else {
        this.duration = 1;
        this.duration_unit = "day";
      }
    }
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
    this.formatter = formatter;
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      ffs: (Object.values(this.auth()).length > 0 && this.auth().settings) ? this.auth().settings.base_currency : "NGN",
      setReOccurring: false,
      confirmReciept: false,
      plans: [],
      plan: {},
      amount: null,
      confirm: false,
      funding_source: "card",
      name: "",
      isSuccessful: false,
      baseCurrency: this.auth() && this.auth().settings ? this.auth().settings.base_currency : "NGN",
      activeRate: this.rates() ? this.rates()["NGN"] : {},
      duration: null,
      duration_unit: null,
      stages: {
        RECIEPT: "reciept",
        ACCOUNT_DETAILS: "account_details"
      },
      stage: null
    }
  },
  validations() {
    return {
      amount: { required, numeric, min: minLength(1) },
      confirm: { required },
      funding_source: {alphaNum},
      name: { required, min: minLength(3)},
      duration: { numeric },
      duration_unit: {},
      // "user_uuid", "plan_id", "amount", "funding_source", "type", "name", "reference", "duration", "duration_unit"
    }
  },
  mounted() {
    this.getAllPlans();

    if ( Object.values(this.rates()).length === 0 ) {
      this.getRates()
    }
    if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    }
  },
  beforeMount() {
    const { name = "", amount = null, funding_source = "card", plan} = this.$route.query;
    const self = this;

    this.getAllPlans().then( res => {
      self.plans = res;
      if ( plan ) {
        self.plan = res.find((_plan) => _plan.id === +plan) || {};
        setTimeout(() => {
          const startSaveModal = document.getElementById('start-save-modal');
          const _startSaveModal = bootstrapBundleMin.Modal.getOrCreateInstance(startSaveModal)
          _startSaveModal.toggle();
        }, 700);
      }
    });
    this.name = name
    this.amount = amount;
    this.funding_source = funding_source
    this.ffs = this.baseCurrency;
    
  },
  methods: {
    ...mapGetters([
      "savingPlans",
      "loaders",
      "auth",
      "account",
      "rates",
      "accountDetails"
    ]),
    ...mapActions([
      "confirmFlutterPayment",
      "getAllPlans",
      "subscribePlan",
      "myAccount",
      "getRates",
      "fundAccount"
    ]),
    getCoinName(n) {
      const abbr = {
        LC: "Large Cap Coin",
        MC: "Medium Cap Coin",
        SM: "Small Cap Coin",
        LCC: "Large Cap Coin",
        MCC: "Medium Cap Coin",
        SMC: "Small Cap Coin"
      }
      const keys =  Object.keys(abbr);
      return ( keys.includes(n) ) ? abbr[n] : n; 
    },
    resetDefault() {
      this.duration = null;
      this.duration_unit = null
      this.amount = null;
      this.name = "";
      this.setReOccurring = false;
      this.stage = null,
      this.confirm = false;
    },
    selectPlan( plan ) {
      this.stage = null;
      this.plan = plan;
      const { duration, duration_unit, is_group, is_flexible } = plan;
      this.duration = duration;
      this.duration_unit = duration_unit;
      this.setReOccurring = is_group || is_flexible;
      // this.amount = (this.rates() ? this.rates()[this.ffs].buy_rate * plan.max_amount : 0) || null;
    },
    handleReOccurring(e){
      this.setReOccurring = e.target.checked;
    },
    handleProceed() {
      if (this.funding_source === "bank") {
        this.getVirtualAccount();
        this.stage = this.stages.ACCOUNT_DETAILS;
      } else {
        this.stage = this.stages.RECIEPT
      }
    },
    subscribeToPlan() {
      // const minAmt = 0;
      const minAmt = this.rates() && this.activeRate ? this.plan.min_amount * (this.activeRate.buy_rate / this.rates()[this.plan.base_currency].buy_rate) : this.plan.min_amount;

      if ( this.amount < minAmt ) {
        toaster.error("because of your selected savings plan, use an amount greater than " + this.ffs + " " + minAmt );
        return
      }

      if ( this.funding_source !== "card" && this.account().wallet.NGN.ledger_balance < this.amount ) {
        toaster.show("your balance is too low. " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }

      const userUuid = window.localStorage.getItem("uuid");
      const data = {
        user_uuid: userUuid,
        plan_id: this.plan.id,
        amount: this.amount,
        type: PLAN_TYPES.savings,
        funding_source: this.funding_source,
        name: this.name
      }

      if ( this.duration !== null ) {
        data.duration = this.duration
      }

      if( this.duration_unit !== null ) {
        data.duration_unit = this.duration_unit
      }
      const self = this;
      
      self.subscribePlan(data).then( res => {
        console.log(res);
        if ( self.funding_source !== "card" ) {
          self.$router.push({
            path: "/save-subscribe-success"
          });
        } else {
          self.isSuccessful = true;
          self.makePayment(res.reference)
        }
      }).catch( e => {
        const { message } = e
        toaster.error(message)
      });
    },
    makePaymentCallback(response) {
      console.log("Pay", response)
      const self = this;
      this.confirmFlutterPayment(response).then( res => {
        console.log(res);
        self.isSuccessful = true;
        self.$router.push({
          path: "/save-subscribe-success"
        });
        
      });
    },
    closedPaymentModal() {
      console.log('payment is closed');
      if ( this.isSuccessful ) {
        this.$router.push({
          path: "/save-subscribe-success"
        });
      }
    },
    makePayment(ref) {
      const self = this;
      const paymentData = {
        tx_ref: ref,
        amount: this.amount + 50,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        meta: {
          'counsumer_id': self.auth().id,
          'consumer_mac': window.localStorage.getItem("uuid")
        },
        customer: {
          email: self.auth().email,
          name: self.auth().name
        },
        customizations: {
          title: 'Jawo Savings Plan',
          description: ( self.plan.name ? self.plan.name.toLowerCase() : "" ) + " plan subsciption",
          logo: 'https://app.jawo.io/img/jawo.3fd2356f.png'
        },
        payment_plan: this.plan.reference,
        callback: self.makePaymentCallback,
        onclose: self.closedPaymentModal
      }
      if ( self.auth().profile && self.auth().profile.phone ) {
        paymentData.customer.phonenumber = self.auth().profile.phone
      }
      self.$launchFlutterwave(paymentData)
    },
    listenForPayment() {
      const userUUID = window.localStorage.getItem("uuid");
      const self = this;
      
      window.Echo.private(`transaction.credit.ngn.${userUUID}`)
        .listen('.credit.alert', function(e) {
            const data = e.trxData || e.transaction
            if ( data && data.status && data.status.toUpperCase() === "SUCCESSFUL" ) {
              self.stage = this.stages.RECIEPT
            }
            toaster.success(data.description || "Transaction received")
        }).listen("pusher:subscription_error", (e) => {
            console.log(e);
        });

      window.Echo.channel
    },
    getVirtualAccount() {
      const data = {type: "NGN"};
      if ( this.bvn ) {
        data['bvn'] = this.bvn;
      }
      const self = this;
      
      this.fundAccount(data)
      .then( res => {
        self.listenForPayment();
      })
      .catch( err => {
        toaster.error(err.toString())
      })
    },
    clickToCopy(text) {
      navigator.clipboard.writeText(text).then(function() {
        toaster.success('Copied to clipboard!');
      }, function(err) {
        toaster.show('Could not copy text: ', err);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.price-table-one .single-price-content{
  background-color: #061238;
}
.img-plan {
  width: 70px;
}
.price-table-one .nav-tabs .nav-link.active  {
  background-color: #061238;
    color: #ffffff;
    border-color: #061238;
}
.price-table-one .nav-tabs .nav-link {
  color: #061238;
}

.price-table-one .tab-content .tab-pane:nth-child(2) .single-price-content {
    background-color: var(--jw-text-success);
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link.active {
    background-color: var(--jw-text-success);
    border-color: var(--jw-text-success);
    color: #ffffff;
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link {
    color: var(--jw-text-success);
}
.input-group-text.select-input {
  padding: 0rem;
  border: 0px solid #ced4da;
}
</style>
