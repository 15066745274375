<template>
  <base-template></base-template>
</template>
<script>
// @ is an alias to /src
import BaseTemplate from '@/components/templates/BaseTemplate.vue'
// import Tracker from '@openreplay/tracker';
import { 
  // OPEN_REPLAY_KEY, 
  IS_PRODUCTION 
} from '@/utils';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    BaseTemplate
  },
  methods: {
    ...mapGetters([
      "auth",
      "appConfig"
    ]),
    ...mapActions(["fetchAppConfig"]),
    configureTheme: function() {
      const theme = this.appConfig()

      if ( Object.values(theme).some(v => v) ) {
        const { primary_text_color, secondary_text_color, primary_bg_color, secondary_bg_color, accent_color } = theme;
        const sec_bg_color = secondary_bg_color && secondary_bg_color.charAt(0) === "#" ? secondary_bg_color : `rgb(${secondary_bg_color})`;
        // const pry_bg_color = primary_bg_color.charAt(0) === "#" ? primary_bg_color : `rgb(${primary_bg_color})`;
        const pry_text_color = primary_text_color && primary_text_color.charAt(0) === "#" ? primary_text_color : `rgb(${primary_text_color})`;
        const sec_text_color = secondary_text_color && secondary_text_color.charAt(0) === "#" ? secondary_text_color : `rgb(${secondary_text_color})`;
        const card_bg_color = accent_color && accent_color.charAt(0) === "#" ? accent_color : `rgb(${accent_color})`;

        if (secondary_bg_color) document.body.style.setProperty('--warning-color', sec_bg_color);
        if (primary_bg_color) document.body.style.setProperty('--bs-success-rgb', primary_bg_color);
        if (primary_text_color) document.body.style.setProperty('--primary-color', pry_text_color);
        if (secondary_text_color) document.body.style.setProperty('--jw-text-success', sec_text_color);
        if (card_bg_color) document.body.style.setProperty('--accent-color', card_bg_color);
      }      
    }
  },
  data() {
    return {
      // tracker: null
    }
  },
  mounted() {
    const self = this;
    // this.tracker = new Tracker({
    //   projectKey: OPEN_REPLAY_KEY,  
    // });
    // this.tracker.start();
    const { email } = this.auth()
    if ( email ) {
      // this.tracker.setUserID(email);
    }
    
    const { hostname } = window.location;
    const domains = hostname.split('.');
    const domain = domains[domains.length - 2];
    const subdomain = domains[0];
    // 
    if ( IS_PRODUCTION ) {
      if( domain !== "jawo" ) {
        this.fetchAppConfig({
          identifier: hostname,
          identifiertype: 'domain'
        }).then(() => self.configureTheme());
      } else 
      if (!['www', 'app'].includes(subdomain)) {
        this.fetchAppConfig({
          identifier: subdomain,
          identifiertype: 'subdomain'
        }).then(() => self.configureTheme());
      }
    } else {
      this.fetchAppConfig({
        identifier: "4C2-4GD",
        identifiertype: 'subdomain'
      }).then(() => self.configureTheme());
    }
  },
  watch: {
    '$store.state.auth': function(b, n) {
      const { email } = n
      if ( email ) {
        // this.tracker.setUserID(email);
      }
    },
    '$store.state.appConfig': function(n) {
      const theme = typeof n === "string" ? JSON.parse(n) : n;
      const { primary_text_color, secondary_text_color, primary_bg_color, secondary_bg_color, accent_color } = theme || {};
      
      const sec_bg_color = secondary_bg_color && secondary_bg_color.charAt(0) === "#" ? secondary_bg_color : `rgb(${secondary_bg_color})`;
      // const pry_bg_color = primary_bg_color.charAt(0) === "#" ? primary_bg_color : `rgb(${primary_bg_color})`;
      const pry_text_color = primary_text_color && primary_text_color.charAt(0) === "#" ? primary_text_color : `rgb(${primary_text_color})`;
      const sec_text_color = secondary_text_color && secondary_text_color.charAt(0) === "#" ? secondary_text_color : `rgb(${secondary_text_color})`;
      const card_bg_color = accent_color && accent_color.charAt(0) === "#" ? accent_color : `rgb(${accent_color})`;

      if (secondary_bg_color) document.body.style.setProperty('--warning-color', sec_bg_color);
      if (primary_bg_color) document.body.style.setProperty('--bs-success-rgb', primary_bg_color);
      if (primary_text_color) document.body.style.setProperty('--primary-color', pry_text_color);
      if (secondary_text_color) document.body.style.setProperty('--jw-text-success', sec_text_color);
      if (card_bg_color) document.body.style.setProperty('--accent-color', card_bg_color);
    }
  }
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: var(--primary-color);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: var(--primary-color);
}

nav a.router-link-exact-active {
  color: rgba(var(--bs-success-rgb));;
}
</style>
