<template>
  <base-template>
    <div class="hero-block-wrapper bg-white">
      <div class="custom-container">
        <div class="hero-block-content slideSource" id="first-page">
          <img class="mb-4" :src="appLogo" alt="">
        </div>
      </div>
    </div>
  </base-template>
</template>

<script>
// @ is an alias to /src
import BaseTemplate from '@/components/templates/BaseTemplate.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FirstPage',
  components: {
    BaseTemplate
  },
  data() {
    const { logo } = this.appConfig() || {};
    return {
      appLogo: logo || require("../../../assets/jawo.png"),
    }
  },
  methods: {
    ...mapActions(["retrieveAuth"]),
    ...mapGetters(["appConfig"]),
    goToSecondPage() {
      const self = this;
      setTimeout(function() {
        const elem = document.getElementById('first-page');
        elem.classList.toggle('fade')
        setTimeout(function() {
          self.$router.push({
            path: '/splash',
          })
        }, 1000)
      }, 1500);
    },
    retrieveAuthWithCode(auth_code) {
      const self = this;
      this.retrieveAuth(auth_code).then( function (res) {
        self.$router.push('/dashboard');
      }).catch( function (err) {
        self.errors = err.data ? err.data : {}
        self.goToSecondPage();
      }).finally(() => {})
    }
  },
  mounted() {
    
    if (
      this.$route.params.referrer || this.$route.query.referrer
    ) {
      const code = this.$route.params.referrer || this.$route.query.referrer;
      window.localStorage.setItem("referrer", code);
    }

    if ( this.$route.params.auth_code || this.$route.query.auth_code ) {
      const { auth_code } = this.$route.params || this.$route.query;
      this.retrieveAuthWithCode(auth_code);
    } else { 
      this.goToSecondPage();
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
#first-page {
  opacity: 1;
  transition: opacity 1s; 
}

#first-page.fade {
  opacity: 0;
}
</style>
