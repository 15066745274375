import http from "./http";

export const GetBanks = async params => {
    return await http.get("/banks", {params});
};

export const GetBanksWithUSSD = async() => {
    return await http.get("/banks/ussd");
};

export const Enquiry = async data => {
    return await http.post("/enquire", data);
};

export const AddBankAccount = async data => {
    return await http.post("/bank-account", data);
};

export const ActivateMandate = async data => {
    return await http.post("/activate-mandate", data);
}

export const UpdateBankAccount = async data => {
    return await http.put("/bank-account", data);
};

export const GetBankAccount = async params => {
    return await http.get("/bank-account", {params});
};

export const DeleteBankAccount = async id => {
    return await http.delete("/bank-account/" + id);
};