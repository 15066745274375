export const getRates = (
  state, rates
) => {
  const _rates = {}
  rates.forEach( rate => {
    _rates[rate.currency] = rate
  });
  state.rates = _rates;
}


const RateMutation = {
  getRates
};

export default RateMutation;
