import { AddSettings, FetchUserSecurityQuestion, GetSecurityQuestion, GetSettings, UpdateSettings } from "@/services/settings";

export const getSettings = (context) => {
    context.commit("updateLoader", { fetchSettings: true });
    return new Promise((resolve, reject) => {
        GetSettings()
            .then(res => {
                context.commit("fetchSettings", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchSettings: false });
            });
    });
};

export const addSettings = (context, data) => {
    context.commit("updateLoader", { addSettings: true });
    return new Promise((resolve, reject) => {
        AddSettings(data)
            .then(res => {
                context.commit("addSettings", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addSettings: false });
            });
    });
};

export const updateSettings = (context, data) => {
    context.commit("updateLoader", { updateSettings: true });
    return new Promise((resolve, reject) => {
        UpdateSettings(data)
            .then(res => {
                context.commit("updateSettings", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateSettings: false });
            });
    });
};


export const getSecurityQuestions = (context) => {
    context.commit("updateLoader", { fetchSecurityQuestions: true });
    return new Promise((resolve, reject) => {
        GetSecurityQuestion()
            .then(res => {
                const result = res.data.data
                context.commit("fetchSecurityQuestions", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchSecurityQuestions: false });
            });
    });
}

export const answerSecurityQuestion = (context, ans) => {
    context.commit("updateLoader", { answerSecurityQuestion: true });
    return new Promise((resolve, reject) => {
        AnswerSecurityQuestion(ans)
            .then(res => {
                const result = res.data.data;
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { answerSecurityQuestion: false });
            });
    });
}

export const fetchUserSecurityQuestion = (context, uuid) => {
    context.commit("updateLoader", { fetchUserSecurityQuestion: true });
    return new Promise((resolve, reject) => {
        FetchUserSecurityQuestion(uuid)
            .then(res => {
                const result = res.data.data
                    // context.commit("fetchUserSecurityQuestion", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchUserSecurityQuestion: false });
            });
    });
}





const SettingsAction = {
    getSettings,
    addSettings,
    updateSettings,
    getSecurityQuestions,
    answerSecurityQuestion,
    fetchUserSecurityQuestion
};

export default SettingsAction;