<template>
  <div class="container">
    <success-notification 
      title="Order Placed" 
      :message="`A ${$route.query.type} order of ${$route.query.amount} ${$route.query.currency} has been placed `" 
      next="/withdraw" 
      nextPageName="withdrawal"
    ></success-notification>
  </div>
</template>

<script>

import SuccessNotification from '@/components/pages/notification/SuccessNotification.vue'


export default {
  name: 'SendSellCoinSuccess',
  components: {
    SuccessNotification
  },
  data() {
    return {
      bank: {},
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
