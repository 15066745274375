export const ACCOUNT_TYPES = {
    INVESTMENT: "investment",
    CRYPTO: "crypto",
    SAVINGS: "savings",
    LOAN: "loan",
    HOME: "home"
}
const AccountState = {
    activeAccount: localStorage.getItem("activeAccount") || ACCOUNT_TYPES.HOME,
    accountDetails: {},
    accountBalance: 0,
    // groups: [],
    account: {},
    // settings: [],
    // subordinates: [],
    profit: 0
};

export default AccountState;