export const fetchBusinessAvailableState = (state, data) => {
    state.businessStates = data
}

export const fetchBusinessByState = (state, data) => {
    state.businesses = data
}

export const joinBusiness = (state, data) => {
    const cluster = data && data.business ? data.business : data
    const clusters = state.auth.cluster
    state.auth.clusters = [
        cluster,
        ...clusters
    ]
}


const BusinessMutation = {
    fetchBusinessAvailableState,
    fetchBusinessByState,
    joinBusiness
};

export default BusinessMutation;