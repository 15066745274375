<template>
  <div class="container mt-4">
    <div class="asset-slide">
      <div class="card">
        <div class="card-body">
          <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <apexchart type="area" height="350" :options="areaChartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { tns } from "tiny-slider";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'Summary',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      updatePriceInterval: null,
      coins: [],
      series: [{
        name: 'Balance',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },  
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
          },
          categories: [],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Balance',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
      },

      areaChartOptions: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        
        title: {
          text: 'Balances',
        },
        subtitle: {
          text: 'wallet',
          align: 'left'
        },
        labels: [],
        xaxis: {},
        yaxis: {
          opposite: true
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
    }
  },
  mounted() {
    const self = this;
    if (Object.keys(this.account()).length > 0) {
      this.myAccount().then( res => {
        const coins = res.wallet.coins;
        for ( const x in coins ) {
          self.coins.push({
            symbol: coins[x].symbol,
            balance: coins[x].balance,
            name: coins[x].coin
          });
        }
        self.getPriceUpdate()
      });
    }
    this.updatePriceInterval = setInterval(function() {
      self.getPriceUpdate();
    }, 60000)
    this.resetSlide();
  },
  methods: {
    ...mapActions([
      "myAccount",
      "getPrices"
    ]),
    ...mapGetters([
      "loaders",
      "account"
    ]),
    ...mapMutations([
      "updateProfit"
    ]),
    getPriceUpdate() {
      if ( this.coins.length === 0 ) {
        return
      }
      const data = { pairs: this.coins.map( c => c.symbol + "USDT" )}
      const self = this
      this.getPrices(data).then( res => {

        const prices = {};
        for( const p in res ) {
          const price = res[p];
          prices[price.symbol] = price.price
        }

        const seriesData = [];
        const categories = [];
        let profit = 0;

        self.coins.forEach( coin => {
          const newBalance = coin.balance * prices[coin.symbol + "USDT"];
          profit+=newBalance;
          seriesData.push(Math.round(newBalance));
          categories.push(coin.symbol);
        })
        this.updateProfit(profit)
        self.series = [{
          name: 'Balance',
          data: seriesData
        }];
        self.chartOptions.xaxis.categories = [...categories];
        self.areaChartOptions.labels = [...categories];
      });
    },
    resetSlide() {
        self.slider = tns({
          container: '.asset-slide',
          items: 1,
          slideBy: 'page',
          autoplay: false,
          controlsPosition: "bottom",
          navPosition: "bottom",
          arrowKeys: false,
          controls: false,
          prevButton: false,
          nav: false,
          navAsThumbnails: false,
          autoplayHoverPause: true,
          autoplayButton: false,
          controlsContainer: false,
          navContainer: false,
          // freezable: false
        });
    }
  },
  unmounted() {
    if (this.updatePriceInterval) {
      clearInterval(this.updatePriceInterval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
