// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCames-dXikumJQlWygHAUPNNhtYAQG5Vc",
  authDomain: "jawo-81bf8.firebaseapp.com",
  projectId: "jawo-81bf8",
  storageBucket: "jawo-81bf8.appspot.com",
  messagingSenderId: "1080133719193",
  appId: "1:1080133719193:web:d3a7553cd698b1a3b2d408",
  measurementId: "G-08H4CG4X0V"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);