<template>
  <page-template>

    <div class="page-content-wrapper py-3">
     


      <div class="container">
        <div class="card">
          <div class="card-body">
            <!-- Price Table One -->
            <div class="price-table-one">
              <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center">
                <span class="nav-item"></span><span class="nav-item"></span>
                <li class="nav-item" role="presentation">
                  <a class="nav-link shadow" data-bs-toggle="tab" href="#" @click="goBack" role="tab" aria-controls="priceTab_Two" aria-selected="false">
                    <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
              <div class="tab-content" >
                <span class="tab-pane"></span>
                <span class="tab-pane"></span>
                <div class="tab-pane fade active show" role="tabpanel" aria-labelledby="priceTabThree">
                  <!-- Single Price Table -->
                  <div class="single-price-content shadow-sm">

                    <div class="text-lg">
                      <p class="text-warning font-bold"><b>transfer to the account details below</b></p>
                    </div>

                    <div class="price">
                      <span v-if="ussdCode" class="text-white mb-2">USSD Code</span>
                      <span v-else class="text-white mb-2">Account Details</span>
                      <hr/>
                      <h3 class="display-3">
                        <template v-if="loaders().fundAccount">
                          <div class="spinner-border text-warning" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </template>
                        <template v-else-if="accountDetails() && accountDetails().account_number">
                          <span v-if="ussdCode" @click="clickToCopy(ussdCode.replace(/AccountNumber/g, accountDetails().account_number).replace(/Amount/g, amount))">
                            {{ ussdCode.replace(/AccountNumber/g, accountDetails().account_number).replace(/Amount/g, amount) }}
                            <sup class="text-xs">
                              <small>
                                <i class="bi bi-files"></i>
                              </small>
                            </sup>
                          </span>
                          <span v-else @click="clickToCopy(accountDetails().account_number)">
                            {{ accountDetails().account_number }}
                            <sup class="text-xs">
                              <small>
                                <i class="bi bi-files"></i>
                              </small>
                            </sup>
                          </span>
                        </template>
                        <template v-else>
                          <button class="btn btn-success w-100" data-bs-toggle="modal" data-bs-target="#add-phone" id="add-phone-button" >Add Phone</button>
                        </template>
                      </h3>
                      <div v-if="ussdCode">
                        <a v-if="accountDetails() && accountDetails().account_number" class="badge bg-light text-dark rounded-pill" :href="`tel:${ussdCode.replace(/AccountNumber/g, accountDetails().account_number).replace(/Amount/g, amount)}`">Click to Dial</a>
                      </div>
                      <div>
                        <span v-if="accountDetails() && accountDetails().account_number" class="badge bg-light text-dark rounded-pill">
                          BANK: {{ accountDetails().bank_name }}
                        </span>
                        <span v-if="accountDetails() && accountDetails().account_number" class="badge bg-light text-dark rounded-pill mt-1">
                          ACCOUNT NAME: {{ accountDetails().account_name }}
                        </span>
                      </div>
                    </div>
                    <!-- Pricing Desc -->
                    <div class="pricing-desc">
                      <ul class="ps-0">
                        <li><i class="bi bi-check-lg me-2"></i>Reference: {{ accountDetails().initiation_tran_ref || accountDetails().reference }}</li>
                        <!-- <li><i class="bi bi-check-lg me-2"></i>Amount </li> -->
                        <li><i class="bi bi-check-lg me-2"></i>Status - Pending</li>
                        <li><i class="bi bi-check-lg me-2"></i>Date - {{ (new Date()).toDateString() }}</li>
                        <li><i class="bi bi-check-lg me-2"></i>Processing Fee - NGN 0</li>
                      </ul>
                    </div>
                    <!-- Purchase -->
                    <div class="purchase flex" v-if="!loaders().fundAccount && accountDetails() && accountDetails().account_number">
                      <div class="spinner-grow text-success" role="status"><span class="visually-hidden">Loading...</span></div>
                      <p class="text-light">awaiting payment</p>
                    </div>
                    <div>
                      <p class="text-muted">* confirm in <router-link to="/transaction" class="text text-muted">transaction</router-link> after making payment.</p>
                    </div>
                    <!-- <button class="btn btn-success w-100 mt-2" v-if="!accountDetails().account_number" data-bs-toggle="modal" :disabled="accountDetails() && accountDetails().account_number" data-bs-target="#add-bvn" id="add-bvn-button" >Get Account</button> -->
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal title="Input your BVN to get an account" id="add-bvn">
      <div class="">
        <form>
          <div class="form-group">
            <label class="form-label" for="bvn-data">BVN</label>
            <input class="form-control form-control-clicked" id="bvn-data" type="text" placeholder="22900..."  v-model="v$.bvn.$model" >
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bvn.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <app-button 
            @click="getVirtualAccount()" 
            :classes="'my-1 btn-warning w-100 shadow'" 
            :title="'Generate Account'" 
            :loading="loaders().fundAccount" 
            :disabled="loaders().fundAccount || v$.bvn.$invalid" 
          >
          </app-button>
        </form>
      </div>
    </modal>

    <modal title="Add Phone Number" id="add-phone">
      <div class="">
        <form>
          <div class="form-group mb-3" v-if="!hasCode">
            <label class="form-label" for="addPhoneInput">Add phone number</label>
            <input class="form-control" id="addPhoneInput" v-model="v$.phone.$model" type="text" placeholder="07034567...">
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.phone.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div v-else>
            <div class="form-group mb-3">
              <label class="form-label" for="otp-code">OTP Code</label>
              <input class="form-control" id="otp-code" v-model="v$.code.$model" type="text" placeholder="">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.code.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div>
              An OTP code has been sent to {{ phone }} 
              <span v-if="duration > 0">
                resend in {{ Math.floor(duration/60) }}m:{{ duration%60 }}s
              </span>
              <span @click="getCode()" class="cursor-pointer pointer text-success" v-else>
                resend
              </span>
            </div>
          </div>
          
          <button 
            class="btn btn-success w-100" 
            type="button"
            @click="getCode()" 
            :disabled="loaders().phoneCode || v$.phone.$invalid" 
            v-if="!hasCode"
          >Send</button>

          <button 
            class="btn btn-success w-100" 
            type="button"
            @click="updateProfile()" 
            :disabled="loaders().updatePhone || v$.code.$invalid" 
            v-else 
          >Add</button>
        </form>
      </div>
    </modal>

  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import { createToaster } from "@meforma/vue-toaster";
import Modal from '@/components/common/modal/Modal.vue';
import useVuelidate from '@vuelidate/core';
import AppButton from '@/components/common/form/AppButton.vue';
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
const toaster = createToaster({ /* options */ });

export default {
  name: 'BankTransfer',
  components: {
    PageTemplate,
    Modal,
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      bvn: { required, min: minLength(11) },
      phone: {required, min: minLength(11), max: maxLength(11), numeric},
      code: {min: minLength(6), max: maxLength(6)}
    }
  },
  data(){
    return {
      bvn: "",
      phone: "",
      duration: 0,
      code: "",
      hasCode: false,
      ussdCode: null,
      amount: 0
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    ...mapGetters([
      "accountDetails",
      "loaders"
    ]),
    ...mapActions([
      "fundAccount",
      "updatePhone",
      "phoneCode"
    ]),
    listenForPayment() {
      const userUUID = window.localStorage.getItem("uuid");
      const self = this;
      
      window.Echo.private(`transaction.credit.ngn.${userUUID}`)
        .listen('.credit.alert', function(e) {
            // console.log(e);
            const data = e.trxData || e.transaction
            if ( data && data.status && data.status.toUpperCase() === "SUCCESSFUL" ) {
              self.$router.push({
                path: '/bank-transfer-success', 
                query: { amount: data.net_amount, currency: data.currency }
              });
              // 
            }
            toaster.success(data.description || "Transaction received")
        }).listen("pusher:subscription_error", (e) => {
            console.log(e);
        });

      window.Echo.channel
    },
    getVirtualAccount() {
      const data = {type: "NGN"};
      if ( this.bvn ) {
        data['bvn'] = this.bvn;
      }
      const self = this;
      
      this.fundAccount(data)
      .then( res => {
        if ( res && parseInt(res.code) === 25 ) {
          document.getElementById("add-bvn-button").click();
        } else {
          document.getElementById("close-modal-id").click();
        }
        self.listenForPayment();
        // $('body').removeClass('modal-backdrop fade show');
        const overlay = document.getElementsByClassName("modal-backdrop")[0];
        if (overlay) overlay.classList.remove(...overlay.classList)
      })
      .catch( err => {
        console.log(err)
        const overlay = document.getElementsByClassName("modal-backdrop")[0];
        if (overlay) overlay.classList.remove(...overlay.classList)  
      })
    },
    startCountDown() {
      const self = this;
      const interval = setInterval(() => {
        self.duration = self.duration - 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(interval)
      }, self.duration * 1000);
    },
    getCode() {
      const self = this
      if ( this.phone.match(/\d/g).length===11 ) {
        this.phoneCode({
          phone: this.phone
        })
        .then( () => {
          toaster.success('OTP sent to ' + self.phone);
          self.duration = 90;
          self.startCountDown()
          self.hasCode = true
        })
        .catch( err => {
          self.errors = err.data
        })
      } else {
        toaster.error("phone number not valid")
      }
    },
    updateProfile() {
      const self = this
      this.updatePhone({
        phone: this.phone,
        code: this.code
      })
        .then( () => {
          toaster.success('Phone successfully updated');
          self.getVirtualAccount()
        })
        .catch( err => {
          self.errors = err.data
        })
    },
    clickToCopy(text) {
      navigator.clipboard.writeText(text).then(function() {
        toaster.success('Copied to clipboard!');
      }, function(err) {
        toaster.show('Could not copy text: ', err);
      });
    }
  },
  mounted() {
    this.getVirtualAccount()
    const { code, amount = 0} = this.$route.query;
    if ( code ) {
      this.ussdCode = code
      this.amount = amount
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: aliceblue;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
