import { createApp } from 'vue'
// import Toast from "vue-toastification";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "vue-toastification/dist/index.css";
import Toaster from "@meforma/vue-toaster";
import Flutterwave from  'vue-flutterwave'
import Echo from 'laravel-echo';
import VueShepherdPlugin from 'vue-shepherd';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as Sentry from "@sentry/vue";

import { 
    BASE_URL, 
    FLUTTERWAVE_PUB_KEY, 
    PUSHER_APP_CLUSTER, 
    PUSHER_APP_KEY, 
    WS_HOST, 
    WS_PORT,
    IS_PRODUCTION, 
} from './utils'

const options = {
    // You can set your default options here
};

const app = createApp(App)
app.config.globalProperties = {
    ...app.config.globalProperties,
    is: (role) => {
        const roles = (store.getters.auth.roles || []).map( r => r.name)
        return roles.includes(role);
    },
    has: (permission) => {
        let permissions = [];
        (store.getters.auth.roles || []).forEach( r => {
            permissions = [
                ...permissions,
                ...r.permissions.map(p => p.name)
            ]
        })
        return permissions.includes(permission);
    }
}

Sentry.init({
    app,
    dsn: "https://a6db969be12be02663bc69e2577dad77@o4507431486357504.ingest.de.sentry.io/4507431493042256",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/jawo\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component("v-select", vSelect);
app.use(router)
    .use(store)
    .use(Toaster)
    .use(Flutterwave, { publicKey: FLUTTERWAVE_PUB_KEY } )
    .use(VueShepherdPlugin)
    // .use(Toast)
    .mount('#app')

export default app;

window.Pusher = require('pusher-js');

window.Pusher.logToConsole = !IS_PRODUCTION;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     authEndpoint: BASE_URL + 'broadcasting/auth',
//     key: PUSHER_APP_KEY,
//     host: WS_HOST,
//     // wssHost: WS_HOST,
//     wsHost: WS_HOST,
//     wsPort: WS_PORT,
//     // wssPort: WS_PORT,
//     cluster: PUSHER_APP_CLUSTER,
//     // encrypted: true,
//     forceTLS: false,
//     disableStats: false,
//     // enabledTransports: ['ws', 'wss'],
//     wsPart: '/ws',
//     auth: {
//         headers: {
//             Authorization: 'Bearer ' + window.localStorage.getItem("rep") || ""
//         },
//     },
// });

window.Echo = new Echo({
    broadcaster: 'pusher',
    authEndpoint: BASE_URL + 'broadcasting/auth',
    key: PUSHER_APP_KEY,
    cluster: PUSHER_APP_CLUSTER,
    forceTLS: true,
    disableStats: false,
    auth: {
        headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem("rep") || ""
        },
    },
});