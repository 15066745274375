<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="/">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>
    <div class="login-wrapper d-flex align-items-center justify-content-center">
      <div class="custom-container">
        <div class="text-center px-4"><img class="login-intro-img" :src="appLogo" alt=""></div>
        <!-- Register Form -->
        <div class="register-form mt-4">
          <h6 class="mb-3 text-center">Log in with your {{ registrationMethod.toLowerCase() }}.</h6>
          <TransitionGroup>
            <a class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-google me-2 text-danger"></i>Google
            </a>
            <button v-if="registrationMethod!==registrationMethods.bvn" @click="changeRegistrationMethod(registrationMethods.bvn)" class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-hand-index-thumb-fill me-2 text-primary"></i>Use BVN
            </button>
            <button v-if="registrationMethod!==registrationMethods.nin" @click="changeRegistrationMethod(registrationMethods.nin)" class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-hand-index-fill me-2 text-secondary"></i>Use NIN
            </button>
            <button v-if="registrationMethod!==registrationMethods.email" @click="changeRegistrationMethod(registrationMethods.email)" class="btn m-1 btn-outline text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`" >
              <i class="bi bi-envelope-fill me-2 text-secondary"></i>Use Email
            </button>
          </TransitionGroup>
          <hr/>

          <TransitionGroup>
            <form action="#" class="was-validated" v-if="registrationMethod===registrationMethods.email">
              <div class="form-group">
                <input :class="`form-control ${ login.email ? 'form-control-clicked' : ''}`" type="email" v-model="v$.login.email.$model" placeholder="Email">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.login.email.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                <div class="input-errors text-xs text-danger mt-2">
                  <!-- <div class="error-msg">{{ errors.email ? errors.email.join(",") : "" }}</div> -->
                </div>
              </div>
              <div class="form-group position-relative">
                <input :class="`form-control ${ login.password ? 'form-control-clicked' : ''}`" id="psw-input" v-model="v$.login.password.$model" :type="passwordType" placeholder="Enter Password" @keyup="checkStrength">
                <div class="position-absolute" @click="changePasswordType" id="password-visibility"><i class="bi bi-eye" v-if="passwordType === 'password'"></i><i class="bi bi-eye-slash-fill" v-else></i></div>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.login.password.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div id="popover-password" class="text-xs">
                  <p><span id="result"></span></p>
                  <div class="progress">
                      <div id="password-strength" 
                          :class="`progress-bar ${passwordStrengthProgressBarClasses}`" 
                          role="progressbar" 
                          aria-valuenow="40" 
                          aria-valuemin="0" 
                          aria-valuemax="100" 
                          >
                      </div>
                  </div>
                  <ul class="list-unstyled">
                      <li class="">
                          <small class="low-upper-case text-xs">
                              <i :class="`bi ${passwordStrength.uppercase ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                              &nbsp;Lowercase &amp; Uppercase
                          </small>
                      </li>
                      <li class="">
                          <small class="one-number text-xs">
                              <i :class="`bi ${passwordStrength.number ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                              &nbsp;Number (0-9)
                          </small> 
                      </li>
                      <li class="">
                          <small class="one-special-char text-xs">
                              <i :class="`bi ${passwordStrength.specialChar ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                              &nbsp;Special Character (!@#$%^&*)
                          </small>
                      </li>
                      <li class="">
                          <small class="eight-character text-xs">
                              <i :class="`bi ${passwordStrength.min ? 'bi-check2' : 'bi-dot'}`" aria-hidden="true"></i>
                              &nbsp;Atleast 8 Character
                          </small>
                      </li>
                  </ul>
              </div>

              By signing in, you are 
              <a class="" href="#" data-bs-toggle="modal" data-bs-target="#consentModal">consenting</a> to our 
              <a class="" href="https://jawo.io/terms.html" >terms</a>

              <app-button @click="authenticate" :title="'Sign In'" :loading="loaders().authUser" :disabled="loaders().authUser || v$.login.$invalid || passwordStrength.value < 3" ></app-button>
            
              <hr/>
              <!-- <a class="btn m-1 btn-outline w-100 text-muted btn-light shadow" :href="`${baseUrl}auth/google/redirect`">
                <i class="bi bi-google me-2 text-danger"></i>Google
              </a> -->
            </form>

            <form  class="was-validated" v-if="registrationMethod===registrationMethods.bvn">
              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ bvn ? 'form-control-clicked' : ''}`" v-model="v$.bvn.$model" type="text" required placeholder="BVN Number">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bvn.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>
              <app-button 
                @click="authenticateViaBVN" 
                :title="'Continue'" 
                :loading="loaders().identityRegistrationInit || loaders().identityAuthInit" 
                :disabled="loaders().identityRegistrationInit || v$.bvn.$invalid || loaders().identityAuthInit" 
              ></app-button>
            </form>

            <form  class="was-validated" v-if="registrationMethod===registrationMethods.nin">
              <div class="form-group text-start mb-3">
                <input :class="`form-control ${ nin ? 'form-control-clicked' : ''}`" v-model="v$.nin.$model" type="text" required placeholder="NIN Number">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nin.$errors" :key="index">
                  <small class="error-msg">{{ error.$message }}</small>
                </div>
              </div>
              <app-button 
                @click="authenticateViaNIN" 
                :title="'Continue'" 
                :loading="loaders().identityRegistrationInit || loaders().identityAuthInit" 
                :disabled="loaders().identityRegistrationInit || loaders().identityAuthInit || v$.nin.$invalid" 
                ></app-button>
            </form>
          </TransitionGroup>

          <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
            <div class="error-msg">
              <small>{{ key }}: {{ error }}</small>
            </div>
          </div>
          <hr/>
        </div>
        <!-- Login Meta -->
        <div class="login-meta-data text-center"><router-link class="stretched-link forgot-password d-block mt-3 mb-1" to="/forgot-password">Forgot Password?</router-link>
          <p class="mb-0">Dodn't have an account? <router-link class="stretched-link text-success" to="/register">Register Now</router-link></p>
        </div>
      </div>
    </div>

    <modal title="Consent" id="consentModal">
      <p>
        You accept and consent to the terms of this Privacy Policy when you order, sign up or interact with us or any of our Services regardless of how they are accessed or used. Changes to this Privacy Policy
      </p>
      <p>
        We may modify this Privacy Policy from time to time by publishing or uploading an updated version on our website. The updated version will become effective as of the published date.
        Furthermore, if any revised version includes a substantial change in terms, Users shall be informed via email (sent to the email address specified in your account), by means of a notice on the Jawo Platform.
        Controller
      </p>
      <p>
        Jawo is the controller and responsible for the privacy of your personal data on the Jawo app and web.
        If you have any questions about this privacy policy or our privacy practices, please contact us below –
        Customer Service Desk
      </p>
      <p>Email address: support@jawo.io</p>
    </modal>
  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue';
import AppButton from '@/components/common/form/AppButton.vue';
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import Modal from '@/components/common/modal/Modal.vue';
import { BASE_URL } from '../../../utils/constant.util';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  name: 'LoginPage',
  components: {
    CentralizedTemplate,
    AppButton,
    Modal
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    const { logo } = this.appConfig() || {};
    return {
      nin: "",
      bvn: "",
      login: {},
      errors: {},
      baseUrl: BASE_URL,
      passwordType: "password",
      passwordStrength: {
        value: 0
      },
      passwordStrengthProgressBarClasses: "",
      appLogo: logo || require("../../../assets/jawo.png"),
      registrationMethod: "EMAIL",
      registrationMethods: {
        email: "EMAIL",
        bvn: "BVN",
        nin: "NIN"
      }
    }
  },
  validations() {
    return {
      nin: { required, min: minLength(11) },
      bvn: { required, min: minLength(11) },
      login: {
        email: { required, email },
        password: { required, min: minLength(6) }
      }
    }
  },
  methods: {
    ...mapActions([
      "authUser",
      "identityAuthInit",
      "identityRegistrationInit"
    ]),
    ...mapGetters([
      "loaders",
      "appConfig"
    ]),
    authenticateViaBVN() {
      this.identityAuthInit({
        bvn: this.bvn
      }).then((res) => {
        const { message, code_sent } = res;
        if (code_sent) {
          this.$router.push({
            path: '/otp-confirm-page', 
            params: {bvn: this.bvn, page: "identity-auth", message},
            query: {bvn: this.bvn, page: "identity-auth", message}
          });
        }
      }).catch (err => {
        toaster.info("Please wait while we try to sign you up.")
        this.registerViaBVN()
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
        this.resetError()
      })
    },
    authenticateViaNIN() {
      this.identityAuthInit({
        nin: this.nin
      }).then((res) => {
        const { message, code_sent } = res;
        if (code_sent) {
          this.$router.push({
            path: '/otp-confirm-page', 
            params: {nin: this.nin, page: "identity-auth", message},
            query: {nin: this.nin, page: "identity-auth", message}
          });
        }
      }).catch (err => { 
        toaster.info("Please wait while we try to sign you up.")
        this.registerViaNIN()
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
        this.resetError()
      })
    },
    authenticate() {
      // 
      const data = {
        email: this.login.email,
        password: this.login.password
      };
      const self = this;
      this.authUser(data).then( function (res) {
        const { next, message = "" } = res;
        if (next && next === "otp") {
          self.$router.push({
            path: '/otp-confirm-page', 
            params: {email: self.login.email, page: "otp-login", message},
            query: {email: self.login.email, page: "otp-login", message}
          });
        } else {
          self.$router.push('/dashboard');
        }
      }).catch( function (err) {
        const { data = {}, message } = err
        self.errors = {...data, message};
        toaster.error(message);
      }).finally(() => {
      })
    },
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },
    changeRegistrationMethod(method) {
      this.registrationMethod = method
    },
    checkStrength(e) {
      const password = e.target.value
      let strength = 0;

      //If password contains both lower and uppercase characters
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
          strength += 1;
          this.passwordStrength.uppercase = true;
      } else {
          this.passwordStrength.uppercase = false;
      }
      //If it has numbers and characters
      if (password.match(/([0-9])/)) {
          strength += 1;
          this.passwordStrength.number = true;
      } else {
          this.passwordStrength.number = false;
      }
      //If it has one special character
      if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
          strength += 1;
          this.passwordStrength.specialChar = true;
      } else {
        this.passwordStrength.specialChar = false;
      }
      //If password is greater than 7
      if (password.length > 7) {
          strength += 1;
          this.passwordStrength.min = true;
      } else {
          this.passwordStrength.min = false; 
      }

      // If value is less than 2
      if (strength < 2) {
          this.passwordStrengthProgressBarClasses = 'progress-bar-danger w-10'
      } else if (strength == 3) {
          this.passwordStrengthProgressBarClasses = 'progress-bar-warning w-60'
      } else if (strength == 4) {
          this.passwordStrengthProgressBarClasses = 'progress-bar-success w-100'
      }
      this.passwordStrength.value = strength;
    },
    registerViaBVN() {
      this.identityRegistrationInit({
        bvn: this.bvn
      }).then((res) => {
        const { message, code_sent } = res;
        if (code_sent) {
          this.$router.push({
            path: '/otp-confirm-page', 
            params: {bvn: this.bvn, referrer: this.referrer, page: "identity-registration", message},
            query: {bvn: this.bvn, referrer: this.referrer, page: "identity-registration", message}
          });
        }
      }).catch (err => {
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
      })
    },
    registerViaNIN() {
      this.identityRegistrationInit({
        nin: this.nin
      }).then((res) => {
        const { message, code_sent } = res;
        if (code_sent) {
          this.$router.push({
            path: '/otp-confirm-page', 
            params: {nin: this.nin, referrer: this.referrer, page: "identity-registration", message},
            query: {nin: this.nin, referrer: this.referrer, page: "identity-registration", message}
          });
        }
      }).catch (err => {
        const { data = {}, message } = err
        this.errors = {
          ...data,
          message
        }
      })
    },
    resetError() {
      const self = this;
      setTimeout(function() {
        self.errors = {}
      }, 2000)
    }
  },
  watch: {
    '$store.state.auth': function(b, n) {
      if (n.access_token) {
        this.$route.path("/dashboard")
      }
    }
  },
  mounted() {
    //
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}

.progress {
    height: 3px !important;
}

.progress-bar-danger {
    background-color: #e90f10;
}
.progress-bar-warning{
    background-color: #ffad00;
}
.progress-bar-success{
    background-color: #02b502;
}
.w-10 {
  width: 10%;
}
.w-60 {
  width: 60%;
}
.w-100 {
  width: 100%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
