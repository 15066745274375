import { PLAN_TYPES } from "@/utils";

export const getPlans = (
  state,
  plans
) => {
  const cryptoPlans = plans.filter( plan => [PLAN_TYPES.crypto, PLAN_TYPES.invesment].includes(plan.type))
  const savingPlans = plans.filter( plan => plan.type === PLAN_TYPES.savings)
  const loanPlans = plans.filter( plan => plan.type === PLAN_TYPES.loan)
  state.cryptoPlans = cryptoPlans;
  state.savingPlans = savingPlans;
  state.loanPlans = loanPlans;
};

export const subscibe = (
  state, subscibe
) => {
  state.subscibe = subscibe;
}


const PlanMutation = {
  getPlans,
  subscibe
};

export default PlanMutation;
