<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>


    <div class="login-wrapper d-flex align-items-center justify-content-center text-center">

      

      <div class="container">
        <div class="text-center">
          <img class="login-intro-img mb-4" src="../../../assets/images/splash/19.png" alt="">

          <!-- <div class="ratio ratio-1x1">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/watch?v=yRr0_gJ-3mI"></iframe>
          </div> -->

          <h3>Loan Profile!</h3>
          <p class="mb-4 text-xs">Let us know your financial profile. </p>
          <div class="container px-0 questions-slide">
            <template v-for="(question, i) in questions()" :key="i">
              <div class="card" v-if="question.answers && question.answers.length > 0">
                <div class="card-body">
                  <h5 class="mb-2"> {{ question.question }} </h5>
                  <div class="single-plan-check shadow-sm active-effect" v-for="(answer, j) in question.answers" :key="j">
                    <div class="form-check mb-0">
                      <input class="form-check-input" type="radio" :name="`planChoose${i}`" @click="choose(answer.id, question.type)" >
                      <label class="form-check-label">{{ answer.answer }}</label>
                    </div>
                    <svg class="bi bi-person text-primary" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                    </svg>
                  </div>
                  <!-- <div class="single-plan-check shadow-sm active-effect">
                    <div class="form-check mb-0">
                      <input class="form-check-input" type="radio" :name="`planChoose${i}`" @click="choose(question.answers[1].id)">
                      <label class="form-check-label">{{ question.answers[1].answer }}</label>
                    </div>
                    <svg class="bi bi-people text-success" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"></path>
                    </svg>
                  </div>
                  <div class="single-plan-check shadow-sm active-effect">
                    <div class="form-check mb-0">
                      <input class="form-check-input" type="radio" :name="`planChoose${i}`" @click="choose(question.answers[2].id)" >
                      <label class="form-check-label">{{ question.answers[2].answer }}</label>
                    </div>
                    <svg class="bi bi-building text-danger" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"></path>
                      <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                    </svg>
                  </div> -->


                  <router-link v-if="i === (questions().length - 1)" class="btn m-1 btn-circle btn-light shadow" to="/loans">
                    <svg class="bi bi-check2" width="18" height="18" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
                    </svg>
                  </router-link>
                  <p v-else class="text-mute">
                    swipe left
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>


  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue'
import { mapActions, mapGetters } from 'vuex';
import { tns } from "tiny-slider"

export default {
  name: 'LoanQuestionPage',
  components: {
    CentralizedTemplate
  },
  data() {
    return {
      slider: null
    }
  },
  methods: {
    ...mapActions([
      "getQuestions",
      "selectAnwser"
    ]),
    ...mapGetters([
      "questions",
      "loaders"
    ]),
    choose(id, questionType) {
      const self = this;
      this.selectAnwser(id).then( res => {
        const scores = window.localStorage.getItem("scores") ? JSON.parse(window.localStorage.getItem("scores")) : {}
        scores[questionType] = res;
        window.localStorage.setItem("scores", JSON.stringify(scores))
        if (!self.slider) {
          self.initSlider()
        }
        const info = self.slider.getInfo();
        if ( info.index < info.slideCount - 1 ) {
          self.slider.goTo('next')
          return;
        }

        self.$router.push({ 
          path: '/success'
        })
      })
    },
    goBack() {
      this.$router.go(-1)
    }, 
    initSlider () {
      this.slider = tns({
        container: '.questions-slide',
        items: 1,
        slideBy: 'page',
        autoplay: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        arrowKeys: true,
        controls: false,
        prevButton: ".btn",
        nav: false
      })
    }
  },
  mounted() {
    const self = this;
    this.initSlider();
    this.getQuestions('?type=loan').then( function() {
      setTimeout( function() {
        self.initSlider()
      }, 500)
    });
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}

botton {
  padding: 5px 10px;
}
</style>
