<template>
  <centralized-template>
    <div class="login-back-button">
      <router-link to="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </router-link>
    </div>
    <div class="login-wrapper d-flex align-items-center justify-content-center">
      <div class="custom-container">
        <div class="text-center"><img class="mx-auto mb-4 d-block" src="../../../assets/images/auth/36.png" alt="">
          <h3>Change Password</h3>
          <p>Enter your new password</p>
        </div>
        <!-- OTP Send Form -->
        <div class="otp-form mt-4">
          <form>
            <div class="form-group position-relative">
              <input :class="`form-control ${ reset.password ? 'form-control-clicked' : ''}`" id="psw-input" v-model="v$.reset.password.$model" :type="passwordType" placeholder="Enter Password">
              <div class="position-absolute" @click="changePasswordType" id="password-visibility"><i class="bi bi-eye" v-if="passwordType === 'password'"></i><i class="bi bi-eye-slash" v-else></i></div>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.reset.password.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group position-relative">
              <input :class="`form-control ${ reset.passwordConfirm ? 'form-control-clicked' : ''}`" id="psw-input" v-model="v$.reset.passwordConfirm.$model" :type="passwordType" placeholder="Confirm Password">
              <div class="position-absolute" @click="changePasswordType" id="password-visibility"><i class="bi bi-eye" v-if="passwordType === 'password'"></i><i class="bi bi-eye-slash" v-else></i></div>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.reset.passwordConfirm.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <app-button @click="confirm" :title="'Confirm'" :loading="loaders().resetPassword" :disabled="loaders().resetPassword || v$.reset.$invalid" ></app-button>
            
            <!-- <button class="btn btn-success w-100" type="submit" :disabled="loaders().resetPassword || v$.reset.$invalid" @click="confirm" >Confirm</button> -->


          </form>
        </div>
        <!-- Term & Privacy Info -->
        <div class="login-meta-data px-4 text-center">
          <p class="mt-3 mb-0">Check out our <a class="mx-1 stretched-link text-success" href="#">Term of Services</a>and <a class="mx-1 stretched-link text-success" href="#">Privacy Policy.</a></p>
        </div>
        <div class="mt-4">
          <ul>
            <li v-for="(error, i) in errors" :key="i" class="text-danger text-xs" > {{ error.join(', ') }} </li>
          </ul>
        </div>
      </div>
    </div>
  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue';
import useVuelidate from '@vuelidate/core';
import AppButton from '@/components/common/form/AppButton.vue';
import { required, sameAs, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    CentralizedTemplate,
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      reset: {},
      errors: {},
      passwordType: "password"
    }
  },
  validations() {
    return {
      reset: {
        password: { required, min: minLength(6) },
        passwordConfirm: { 
          required, 
          // sameAs: sameAs(this.password) 
        }
      },
    }
  },
  methods: {
    ...mapActions([
      "resetPassword"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    confirm() {
      const data = {
        email: this.$route.query.email,
        code: this.$route.query.code,
        password: this.reset.password
      };

      this.resetPassword(data).then( res => {
        console.log(res);
        this.$router.push('/login')
      }).catch( err => {
        this.errors = err.data
      });

    },
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },
    goBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
