<template>
    <modal :title="title || 'Add Phone Number'" :id="id">
        <form>
            <div class="form-group mb-3" v-if="!hasCode">
            <label class="form-label" for="addPhoneInput">Add phone number</label>
            <input class="form-control" id="addPhoneInput" v-model="v$.phone.$model" type="text" placeholder="07034567...">
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.phone.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
            </div>
            </div>
            <div v-else>
            <div class="form-group mb-3">
                <label class="form-label" for="otp-code">OTP Code</label>
                <input class="form-control" id="otp-code" v-model="v$.code.$model" type="text" placeholder="">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.code.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div>
                An OTP code has been sent to {{ phone }} 
                <span v-if="duration > 0">
                resend in {{ Math.floor(duration/60) }}m:{{ duration%60 }}s
                </span>
                <span @click="getCode()" class="cursor-pointer pointer text-success" v-else>
                resend
                </span>
            </div>
            </div>
            
            <button 
                class="btn btn-success w-100" 
                type="button"
                @click="getCode()" 
                :disabled="loaders().phoneCode || v$.phone.$invalid" 
                v-if="!hasCode"
            >Send</button>

            <button 
                class="btn btn-success w-100" 
                type="button"
                @click="updateProfile()" 
                :disabled="loaders().updatePhone || v$.code.$invalid" 
                v-else 
            >Add</button>
        </form>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { createToaster } from "@meforma/vue-toaster";
    import { mapGetters, mapActions } from 'vuex';
    import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
    // import SuccessNotification from '@/components/common/notification/SuccessNotification.vue'
    const toaster = createToaster({ /* options */ });
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

    export default {
        name: 'ModalChangePhone',
        props: ['title', 'id'],
        components: {
            Modal,
            // SuccessNotification
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                phone: {required, min: minLength(11), max: maxLength(11), numeric},
                code: {min: minLength(6), max: maxLength(6)}
            }
        },
        data() {
            return {
                phone: "",
                code: "",
                duration: 0,
                hasCode: false,
            }
        },
        methods: {
            ...mapGetters([
                "loaders",
            ]),
            ...mapActions([
                "phoneCode", 
                "updatePhone"
            ]),
            startCountDown() {
                const self = this;
                const interval = setInterval(() => {
                    self.duration = self.duration - 1;
                }, 1000);
                setTimeout(() => {
                    clearInterval(interval)
                }, self.duration * 1000);
            },
            getCode() {
                const self = this
                if ( this.phone.match(/\d/g).length===11 ) {
                    this.phoneCode({
                        phone: this.phone
                    })
                    .then( () => {
                    toaster.success('OTP sent to ' + self.phone);
                        self.duration = 90;
                        self.startCountDown()
                        self.hasCode = true
                    })
                    .catch( err => {
                        self.errors = err.data
                    })
                } else {
                    toaster.error("phone number not valid")
                }
            },
            updateProfile() {
                const self = this
                this.updatePhone({
                    phone: this.phone,
                    code: this.code
                })
                .then( () => {
                    toaster.success('Phone successfully updated');
                    const thisModal = document.getElementById(self.id);
                    const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
                    _thisModal.hide();
                })
                .catch( err => {
                    self.errors = err.data
                })
            },
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  