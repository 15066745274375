import { GetCandleStick, GetPrices } from "@/services/coins";

export const getCandleStick = (context, data) => {
    context.commit("updateLoader", { getCandleStick: true });
    context.commit("updateToast", false);
    return new Promise((resolve, reject) => {
        GetCandleStick(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("getCandleStick", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCandleStick: false });
                context.commit("updateToast", true);
            });
    });
};

export const getPrices = (context, data) => {
    context.commit("updateLoader", { getPrices: true });
    context.commit("updateToast", false);
    return new Promise((resolve, reject) => {
        GetPrices(data)
            .then(res => {
                const result = [];//res.data.data;
                // context.commit("getPrices", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPrices: false });
                context.commit("updateLoader", true);
            });
    });
};




const CoinsAction = {
    getCandleStick,
    getPrices
};

export default CoinsAction;