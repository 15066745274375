<template>

    <div class="container mt-4 mb-4">
        <div class="card">
        <div class="card-header">
            <h6 class="py-1">Investments</h6>
        </div>
        <div class="card-body" v-if="userCryptoPlans() && userCryptoPlans().length > 0">
            <div class="accordion accordion-flush accordion-style-two" id="accordionStyle2">
            <!-- Single Accordion -->
            <div class="accordion-item" v-for="(plan, i) in userCryptoPlans()" :key="i">
                <div class="accordion-header" :id="`accordion${i}`">
                    <div class="d-flex justify-between">
                        <h6 
                            data-bs-toggle="collapse" 
                            :data-bs-target="`#accordionStyleCol${i}`" 
                            aria-expanded="false" 
                            :aria-controls="`accordionStyleCol${i}`" 
                            class="collapsed"
                        >
                            <div class="col flex-row">
                                <div>{{ plan.name }} </div>
                                <span :class="`badge ${getStatusColor(plan.subscription.status)} mt-2`" style="font-size:8px">{{ plan.subscription.status }}</span>
                                
                            </div> 
                            <font size="1" :color="getPlanBalance(plan) < 0 ? 'red': 'green'" class="text text-xs"> ({{ plan.subscription.currency }} {{ getPlanBalance(plan) }})</font>
                            <p class="text-xs text-muted mt-3 mx-4">
                                <small>
                                    {{ moment(plan.subscription.updated_at || plan.subscription.updated_at).format("MMM DD, YYYY HH:mm a") }}
                                </small>
                            </p>
                        </h6>
                        

                        <h6 
                            data-bs-toggle="collapse" 
                            :data-bs-target="`#accordionStyleCol${i}`" 
                            aria-expanded="false" 
                            :aria-controls="`accordionStyleCol${i}`" 
                            class="collapsed"
                            style="display:block;width:0%;padding:0px;margin-bottom: 0.5rem;margin-left: -20px;"
                        >
                            <i class="bi bi-plus-lg"></i>
                        </h6>

                    </div>
                
                </div>

                <div class="accordion-collapse collapse" :id="`accordionStyleCol${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle2" style="">
                    <div class="accordion-body">
                        
                        <p class="mb-0">{{ plan.subscription.comment }}</p>
                        <h4 class="badge bg-dark">{{ plan.subscription.currency }} {{ plan.subscription.amount.toFixed(2) }} | {{ plan.subscription.base_currency }} {{( plan.subscription.rate * plan.subscription.amount).toFixed(2) }}</h4>
                        <p>{{ plan.subscription.currency }} rate: {{ plan.subscription.rate }} {{ plan.subscription.base_currency }}</p>
                        <p>We have invested {{ plan.subscription.currency }} {{ getPlanSpent(plan) }} of your funds</p>
                        <p>Coins We Bought: </p>
                        <p class="mb-0" v-if="plan.subscription.coins">
                        
                            <table class="table mb-0 table-hover" >
                                <thead v-if="plan.subscription.coins.length > 0">
                                    <th>
                                        Coin
                                    </th>
                                    <th>
                                        Bought At
                                    </th>
                                    <th>
                                        Sold At
                                    </th>
                                </thead>
                                <thead v-else>
                                    <th>
                                        Investment
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Due Date
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="(coin, j) in plan.subscription.coins" :key="j" :class="`tb-bg-${coin.status}`" :title="coin.status">
                                        <td>
                                            <div class="text-xs">
                                                {{ coin.purchaced_coin.symbol }} 
                                                <img 
                                                    :src="coin.purchaced_coin.logo" 
                                                    width="20px" 
                                                    style="width:20px; border-radius:3px" 
                                                    onerror="javascript: this.src = 'https://raw.githubusercontent.com/coinwink/cryptocurrency-logos/master/coins/16x16/1027.png'"
                                                />
                                            </div>
                                            <div class="text-xs">
                                                {{ coin.purchaced_coin.coin }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-xs">
                                                <small :title="!coin.amount_bought ? 'Not Bought': ''">{{ coin.purchaced_coin.symbol }} &nbsp; {{ coin.amount_bought ? coin.amount_bought > 1 ? Math.round( coin.amount_bought * 100 ) / 100 : Math.round( coin.amount_bought * 100 ) / 100 : 'NB' }}</small>
                                            </div>
                                            <div class="text-xs" :title="!coin.price_bought ? 'Not Bought': ''">
                                                {{ plan.base_currency }} &nbsp; {{ coin.price_bought ? Math.round(coin.price_bought * 10)/10 : '0.00' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-xs">
                                                <small :title="!coin.amount_sold ? 'Not Sold': ''">{{ coin.purchaced_coin.symbol }} &nbsp; {{ coin.amount_sold ? coin.amount_sold.toFixed(3) : 'NS' }}</small>
                                            </div>
                                            <div class="text-xs bg-red-500">
                                                {{ plan.base_currency }} &nbsp; {{ (coin.price_sold && coin.price_sold > 0) ? ((coin.price_sold * 10)/10).toFixed(3) : (coin.amount_bought * priceList[coin.purchaced_coin.symbol+plan.base_currency]).toFixed(3) || '0.00' }}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr 
                                        v-for="(investment, j) in plan.subscription.investments" 
                                        :key="j" 
                                        :class="`tb-bg-${investment.status}`" 
                                        :title="investment.status" 
                                        @mouseover="activeInvestment=j" 
                                        @mouseout="activeInvestment=null"
                                    >
                                        <td>
                                            <div class="text-xs">
                                                <!-- {{ formatCurrency({amount: investment.amount, currncy: plan.subscription.currency}) }} -->
                                                {{ plan.subscription.currency }} {{ Math.round(investment.amount * 10)/10 }} 
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-xs">
                                                {{ investment.status }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div class="text-xs" v-if="activeSave==j && ['PENDING', 'DUE'].includes(investment.status)" >
                                                <button class="btn btn-sm btn-success" :disabled="moment(investment.due_at).isAfter(new Date)" @click="makeClaim(plan.subscription)">claim!</button>
                                            </div>
                                            <div v-else>
                                                {{ moment(investment.due_at).format("DD MMM HH:mm a") }}
                                            </div>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </p>
                        <br/>
                        <div class="mt-1 text-end">
                            <button 
                            :id="`terminate-button-${plan.subscription.id}`"
                            class="btn btn-warning btn-sm btn-creative" 
                            data-bs-toggle="modal" 
                            data-bs-target="#terminate" 
                            @click="cryptoPlan = plan" 
                            v-if="plan.subscription.status ==='ACTIVE' || plan.subscription.status ==='INITIATED'" 
                            :disabled="plan.subscription.status!=='ACTIVE' && plan.subscription.status !=='INITIATED'">terminate</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="card-body" v-else>
            <div class="text-center">
                <i class="bi bi-clipboard-x"></i>
                No coin set yet <br/>
                <small>
                  To start <router-link to="/plans" style="text-decoration: none; color: var(--jw-text-success)"> click here. </router-link>
                </small>
                <img src="../../../assets/images/common/empty.png" />
            </div>
        </div>
        </div>
        <modal title="Terminate Plan" id="terminate">
            <div v-if="Object.values(cryptoPlan).length > 0">
                <h6>
                You are about to terminate your investment plan "<b>{{cryptoPlan.subscription.name}}</b>"
                </h6>
                <i class="bi bi-clock"></i> Due date: <b>{{ moment(cryptoPlan.subscription.created_at).add(parseInt(cryptoPlan.subscription.duration), 'M').format("DD MMM HH:mm a") }}</b>
                <div v-if="cryptoPlan.is_flexible || moment(cryptoPlan.subscription.created_at).add(parseInt(cryptoPlan.subscription.duration), 'M').isBefore(new Date)">
                    <i class="bi bi-cash-coin"></i> Termination Fee: 0
                </div>
                <div v-else>
                    <i class="bi bi-cash-coin"></i> Termination before the due date will cost you <b>{{ cryptoPlan.opt_out_penalty }}%</b> of your saved amount.
                    <span></span>
                </div>
                <div class="text-end mt-2">
                    <app-button @click="stopSavings()" :classes="'btn-sm btn-danger'" :title="'terminate'" :loading="loaders().abortPlan" :disabled="loaders().abortPlan || terminated" ></app-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapStatusBgColor } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AppButton from '@/components/common/form/AppButton.vue';
import Modal from '@/components/common/modal/Modal.vue'


export default {
  name: 'PlanList',
  props: ['title', 'id'],
  components: {
    AppButton,
    Modal
  },
  data() {
    return {
        terminated: false,
        cryptoPlan: {},
        updatePriceInterval: null,
        priceList: {},
        defaultCoinLogo: require("../../../assets/images/common/1027.png"),
        activeInvestment: null
    }
  },
  mounted() {
    const self = this;
    if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    }
  },
  methods: {
    ...mapActions([
        "myAccount",
        "abortPlan"
    ]),
    ...mapGetters([
      "userCryptoPlans", 
      "account",
      "loaders"
    ]),
    getStatusColor(status) {
      return mapStatusBgColor(status)
    },
    stopSavings() {
      if ( !confirm("Are you sure you want to stop this savings plan?")) {
        return 
      }
      // console.log(this.cryptoPlan)
      const id = this.cryptoPlan.subscription.id;
      const self = this;
      this.abortPlan(id).catch( (res) => {
        self.terminated = true;
      })
      const elem = document.getElementById("terminate-button-" + id);
      elem.disabled = true;
    },
    makeClaim(sub) {
        console.log("notify jawo admin");
    },
    getPlanBalance(plan) {
        return plan.subscription.coins.reduce( (t, coin) => t + ( coin.price_sold ? Math.round((parseFloat(coin.price_sold) - parseFloat(coin.price_bought)) * 10)/10 : 0), 0 )
    },
    getPlanSpent(plan) {
        return plan.subscription.coins.reduce( (t, coin) => t + ( coin.price_sold ? Math.round(parseFloat(coin.price_bought) * 10)/10 : 0), 0 )
    },
    setDefault (img) {
        console.log(img)
    }
  },
  created() {
    this.moment = moment;
  },
  unmounted() {
    if (this.updatePriceInterval) {
      clearInterval(this.updatePriceInterval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tb-bg-ACTIVE {
    background-color:rgba(34, 174, 229, 0.061);
}
/* .tb-bg-DECLINED {
    background-color:rgba(229, 34, 44, 0.061);
} */
.tb-bg-FAILED {
    background-color:rgba(229, 34, 44, 0.061);
}
.tb-bg-COMPLETED {
    background-color:rgba(34, 229, 96, 0.061);
}
.tb-bg-INITIATED {
    background-color:rgba(34, 73, 229, 0.061);
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}
</style>
