export const vendAirtime = (state, airtime) => {
    // 
};
export const fetchData = (state, data) => {
    state.vasdata = data;
};
export const vendData = (state, data) => {
    // 
};
export const fetchPower = (state, power) => {
    state.vaspower = power;
};
export const validatePower = (state, power) => {
    // 
};
export const vendPower = (state, power) => {
    // 
};
export const fetchCable = (state, cable) => {
    state.vascable = cable;
};
export const validateCable = (state, cable) => {
    // 
};
export const vendCable = (state, cable) => {
    // 
};



const VasMutation = {
    vendAirtime,
    fetchData,
    vendData,
    fetchPower,
    validatePower,
    vendPower,
    fetchCable,
    validateCable,
    vendCable
};

export default VasMutation;