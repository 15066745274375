import http from "./http";

export const GetAllPlans = async() => {
    return await http.get("/plans/user");
};

export const FetchByReference = async reference => {
    return await http.get(`/plans/${reference}/fetch`);
}

export const FetchByCode = async code => {
    return await http.get(`/plans/find/${code}`);
}

export const Subscribe = async(data) => {
    return await http.post("/plans/subscribe", data);
};

export const JoinPlan = async(data) => {
    return await http.post("/plans/join", data);
};

export const ConfirmFlutterPayment = async data => {
    return await http.post("/payment/flutter/confirm", data);
}

export const AbortPlan = async id => {
    return await http.delete("/plan/terminate/" + id)
}

export const RepayLoan = async (data, loanId) => {
    return await http.put(`/plan/repay/${loanId}`, data);
}

export const PaySavings = async (data, saveId) => {
    return await http.put(`/savings/pay/${saveId}`, data);
}