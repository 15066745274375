<template>
    <modal :title="title || 'Set Transaction Pin'" :id="id">
        <form>
            <div class="form-group mb-3" v-if="walletPinStatus() && walletPinStatus()[currency] && walletPinStatus()[currency] !== 'pin_default'">
                <label class="form-label" for="oldPin">
                    Reset/Old Pin
                    <i class="bi bi-info-circle" data-toggle="tooltip" data-placement="top" data-html="true" title="<em>default pin is: </em> <u><b>0000</b></u>"></i>
                </label>
                <input class="form-control" id="oldPin" v-model="v$.pin.old_pin.$model" :type="passwordType" placeholder="0000">
                <div class="position-absolute" @click="changePasswordType" id="password-visibility">
                    <i class="bi bi-eye" v-if="passwordType === 'password'"></i>
                    <i class="bi bi-eye-slash" v-else></i>
                </div>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.pin.old_pin.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="newPin">New Pin</label>
                <input class="form-control" id="newPin" v-model="v$.pin.pin.$model" :type="passwordType" placeholder="0000">

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.pin.pin.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="confirmPin">Confirm Pin</label>
                <input class="form-control" id="confirmPin" v-model="v$.pin.confirm_pin.$model" :type="passwordType" placeholder="0000">

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.pin.confirm_pin.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <button class="btn btn-success w-100" @click="handleChangePin" :disabled="loaders().changePin || v$.pin.$invalid" type="button">Update</button>
        </form>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { createToaster } from "@meforma/vue-toaster";
    import { mapGetters, mapActions } from 'vuex';
    import { required, minLength, maxLength, numeric, sameAs } from '@vuelidate/validators';
    const toaster = createToaster({ /* options */ });
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

    export default {
        name: 'ModalTransactionPin',
        props: ['title', 'id'],
        components: {
            Modal
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                pin: {
                    old_pin: { required, min: minLength(4), max: maxLength(4), numeric },
                    pin: { required, min: minLength(4), max: maxLength(4), numeric },
                    confirm_pin: { required, min: minLength(4), max: maxLength(4), sameAs: sameAs(this.pin.pin), numeric },
                },
            }
        },
        mounted() {
            if (
                !this.walletPinStatus() || 
                (
                    this.walletPinStatus() && 
                    Object.keys(this.walletPinStatus()).length === 0
                    )
                ) {
                this.transactionPinStatus().then( res => {
                    this.walletTransactionPinToUpdate = Object.keys(res).filter( v => res[v] !== 'pin_set')
                })
            } else {
                const statuses = this.walletPinStatus()
                this.walletTransactionPinToUpdate = Object.keys(statuses).filter( v => statuses[v] !== 'pin_set')
            }

            const { 
                operation, 
                currency = "NGN"
            } = this.$route.query;
            this.currency = currency;
            if ( operation && operation === "reset-pin") {
                const statuses = this.walletPinStatus()
                if ( statuses[currency] && statuses[currency] === "pin_default") {
                    this.pin.old_pin = "0000"
                }
                setTimeout(() => {
                    const thisModal = document.getElementById(this.id);
                    const _thisModal = bootstrapBundleMin.Modal.getOrCreateInstance(thisModal)
                    _thisModal.toggle();
                }, 700);
            }
        },
        data() {
            return {
                pin: {},
                walletTransactionPinToUpdate: [],
                passwordType: "password",
                currency: "NGN",
            }
        },
        methods: {
            ...mapGetters([
                "loaders",
                "walletPinStatus"
            ]),
            ...mapActions([
                "changePin",
                "transactionPinStatus"
            ]),
            changePasswordType() {
                this.passwordType = this.passwordType === "password" ? "text" : "password"
            },
            handleResetPin() {
                if (this.walletTransactionPinToUpdate.length === 0) {
                    toaster.info("Open the chat icon to request a reset pin.")
                    return;
                }
                const currency = this.walletTransactionPinToUpdate[0];
                const statuses = this.walletPinStatus()
                if ( statuses[currency] && statuses[currency] === "pin_default") {
                    this.pin.old_pin = "0000"
                }
                const thisModal = document.getElementById(this.id);
                const _thisModal = bootstrapBundleMin.Modal.getOrCreateInstance(thisModal)
                _thisModal.show();
            },
            handleChangePin() {
                this.changePin({
                    old_pin: this.pin.old_pin, 
                    pin: this.pin.pin,
                    currency: this.currency
                }).then( res => {
                    toaster.success("Transaction pin changed!")
                    const thisModal = document.getElementById(this.id);
                    const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
                    _thisModal.hide();
                }).catch( err => {
                    const { message } = err
                    toaster.error(message)
                })
            },
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  