<template>
  <div class="container">
    <success-notification title="Transfer Successful" :message="`The sum of ${$route.query.currency} ${$route.query.amount} has been deposited into your wallet `" next="/transaction" nextPageName="transactions"></success-notification>
  </div>
</template>

<script>

import SuccessNotification from '@/components/pages/notification/SuccessNotification.vue'


export default {
  name: 'BankTransferSuccess',
  components: {
    SuccessNotification
  },
  data() {
    return {
      user: {},
      // countries: countries
    }
  },
  mounted() {
    //
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
