<template>
    <modal :title="title || stage?.split('_').join(' ') || 'Business'" :id="id">
        <transition name="bounce">
            <div v-if="stage === ''">
                <h6>
                    <!--  -->
                    Cluster Code
                    <br>
                    <small>Enter cluster code</small>
                </h6>
                <div class="form-group">
                    <!-- <label class="form-label" for="userNameInput">Code</label> -->
                    <input class="form-control form-control-clicked" id="userNameInput" v-model="v$.businessCode.$model" type="text" placeholder="333-AAA">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.businessCode.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="text-center my-1 mb-8">
                    <app-button 
                    @click="findBusiness" 
                    :title="'Preview'"
                    :disabled="loaders().fetchBusinessByCode || v$.businessCode.$invalid"
                    :loading="loaders().fetchBusinessByCode"
                    ></app-button>
                </div>
                <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                    <div class="error-msg">{{ key }}: {{ error }}</div>
                </div>
                <small @click="stage = stages.FIND_BUSINESS_STATE">Find Cluster</small>
            </div>
        </transition>
        <transition name="bounce">
            <div v-if="stage === stages.PREVIEW_BUSINESS" class="single-plan-check shadow-sm active-effect d-block">
                <h5>{{ business.code }}</h5>
                <div class="w-full flex">
                    {{ business.name }}
                </div>
                <div class="text-center my-1 mb-8">
                    <app-button 
                    @click="joinCluster" 
                    :title="'Confirm'"
                    :disabled="loaders().joinBusiness || v$.businessCode.$invalid"
                    :loading="loaders().joinBusiness"
                    ></app-button>
                </div>
                <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                    <div class="error-msg">{{ key }}: {{ error }}</div>
                </div>
                <hr/>
                <div @click="stage=''">back</div>
            </div>
        </transition>
        <transition name="bounce">
            <success-notification 
            v-if="stage === stages.JOIN_PLAN_COMPLETED"
            title="Successful" 
            message="Thank You!" 
            nextPageName="Done"
            @close="hideModal"
            ></success-notification>
        </transition> 
        <transition name="slide-fade">
            <div v-if="stage === stages.FIND_BUSINESS_STATE">
                <div class="alert alert-warning alert-dismissible fade show">
                    You have to join a cluster near you in order to get a loan.
                </div>

                <div v-if="loaders().fetchBusinessAvailableState" class="spinner-border spinner-border-sm text-warning" role="status">
                    <span class="sr-only"></span>
                </div>
                <div v-else class="overflow-auto mb-8" style="height: 400px;">
                    <h6 class="my-2">Please select a state</h6>
                    <div v-for="(state, i) in businessStates()" :key="i">
                        <div @click="() => selectedState = state" v-if="state" :class="{'text-warning': selectedState === state}">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="mb-1 ">{{ state }}</p>
                                <div>
                                    <i class="bi bi-bank text-xs"></i>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div class="text-center my-1 mb-8">
                        <app-button 
                        @click="handleContinueFindBusiness" 
                        :title="'Continue'"
                        :disabled="!selectedState"
                        :loading="loaders().fetchBusinessAvailableState"
                        ></app-button>
                    </div>
                </div>
                <hr/>
                <small>
                    <i class="bi bi-info-circle text-warning"></i> <i>Make sure you select the state you are currently operating in, to enhance easy acceptance into a cluster.</i>
                </small>
            </div>
        </transition>
        <transition name="slide-fade">
            <div v-if="stage === stages.FIND_BUSINESSES">
                <div v-if="loaders().fetchBusinessByState" class="spinner-border spinner-border-sm text-warning" role="status">
                    <span class="sr-only"></span>
                </div>
                <div v-else class="overflow-auto mb-8" style="height: 400px;">
                    <h6>Clusters in {{ selectedState }}</h6>
                    <small>
                        <i class="bi bi-info-circle text-warning"></i> Join any cluster by clicking on the whatsapp icon. You can request the cluster code from the admin.
                    </small>
                    <div class="my-5" v-for="(business, i) in businesses().data || []" :key="i">
                        <div v-if="business">
                            <div class="d-flex align-items-center justify-content-between text-secondary">
                                <p class="mb-1 ">{{ business.name }} | {{ business.lga }}</p>
                                <div>
                                    <a v-if="business.whatsapp_link" :href="business.whatsapp_link" target="_blank">
                                        <i class="bi bi-whatsapp text-xs"></i>
                                    </a>
                                    <a v-else-if="business.support_email" :href="`mailto:${business.support_email}`" target="_blank">
                                        <i class="bi bi-envelope text-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p @click="() => stage=stages.FIND_BUSINESS_STATE">
                        back
                    </p>
                    <p @click="() => stage=''">
                        I have a cluster code
                    </p>
                </div>
            </div>
        </transition>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { createToaster } from "@meforma/vue-toaster";
    import { mapGetters, mapActions } from 'vuex';
    import { required, minLength } from '@vuelidate/validators';
    import SuccessNotification from '@/components/common/notification/SuccessNotification.vue';
    import AppButton from '@/components/common/form/AppButton.vue';
    const toaster = createToaster({ /* options */ });
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

    export default {
        name: 'ModalBusiness',
        props: ['title', 'id', 'initStage'],
        components: {
            Modal,
            AppButton,
            SuccessNotification
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                businessCode: {required, min: minLength(6)},
            }
        },
        async mounted() {
            this.stage = ""
            this.selectedState = ""
            this.businessCode = ""
            if (this.initStage) {
                this.stage = this.initStage
            }
            await this.fetchBusinessAvailableState()
        },
        data() {
            return {
                selectedState: "",
                business: {},
                errors: {},
                businessCode: "",
                stage: "",
                stages: {
                    JOIN_PLAN_COMPLETED: "completed",
                    PREVIEW_BUSINESS: "preview_business",
                    FIND_BUSINESS_STATE: "select_cluster_state",
                    FIND_BUSINESSES: "select_cluster"
                },
            }
        },
        methods: {
            ...mapGetters([
                "loaders",
                "auth",
                "businessStates",
                "businesses"
            ]),
            ...mapActions([
                "fetchBusinessAvailableState",
                "fetchBusinessByState",
                "fetchBusinessByCode",
                "joinBusiness"
            ]),
            findBusiness() {
                if ( !this.businessCode ) {
                    this.errors = {message: "You cannot subscribe a user to this cluster"}
                    return
                }
                this.errors = {}
                this.fetchBusinessByCode(this.businessCode).then (r => {
                    toaster.success("Kindly confirm cluster " + r.name + ".")
                    this.stage = this.stages.PREVIEW_BUSINESS
                    this.business = r;
                }).catch( e => {
                    const { message } = e
                    this.errors = { message }
                });
            },
            joinCluster() {
                if ( !this.businessCode ) {
                    this.errors = {message: "You cannot subscribe a user to this cluster"}
                    return
                }
                const self = this
                this.joinBusiness({
                    user: this.auth().id, 
                    code: this.businessCode
                }).then (r => {
                    self.stage = self.stages.JOIN_PLAN_COMPLETED
                    toaster.success("You have joined " + r.business.name + " cluster. Kindly reload.")
                }).catch( e => {
                    const { message } = e
                    this.errors = { message }
                });
            },
            hideModal() {
                const thisModal = document.getElementById(this.id);
                const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
                _thisModal.hide();
                this.stage=''
            },
            async handleContinueFindBusiness() {
                this.stage = this.stages.FIND_BUSINESSES
                await this.fetchBusinessByState({state: this.selectedState})
            }
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}



.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>
  