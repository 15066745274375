export const ACTION_TYPE = {
    Add: "Add",
    Edit: "Edit"
}

export const ENVIRONMENT = process.env.NODE_ENV
export const IS_PRODUCTION = ENVIRONMENT === "production"
export const BASE_URL = IS_PRODUCTION ? "https://api.jawo.io/" : "http://localhost:8000/";
export const ASTRO_PAY_APP_ID = "PbXuYJFEKLNMleTXz1jRkADgYFisgCMnr7k8LSsJVA7Am9giVkixBNHxoo16azxA"
export const PUSHER_APP_KEY = IS_PRODUCTION ? "024f3c8faacbde13a57a" : "90861b8f263129ad9082"; // "90861b8f263129ad9082"
export const PUSHER_APP_CLUSTER = 'eu'
export const WS_HOST = IS_PRODUCTION ? 'socket.jawo.io' : 'localhost';
export const WS_PORT = IS_PRODUCTION ? 443 : 6001
    // export const WS_PORT = 6001
export const FLUTTERWAVE_PUB_KEY = IS_PRODUCTION ? 'FLWPUBK-5821f743ec9cdd6b6dd0f583a8caf4ec-X' : 'FLWPUBK_TEST-a204d1f3fc9b2aa7e18ce907985d46bd-X'
export const OPEN_REPLAY_KEY = "ipjeCropwQPKwbo39uo8"
export const USD_SAVINGS_ID = IS_PRODUCTION ? 7 : 5;
export const VAPID_KEY = "BAx0OhhzHv6Apa_FTCGObguY8P9S0djfZQmEgRXqaHKRDRK3z9VOU2NU-ji_9s0uvoIqouuJKpKP8hyHkSgyriE"

export const PLAN_TYPES = {
    savings: "savings", 
    loan: "loan", 
    crypto: "crypto", 
    stock: "stock", 
    forex: "forex", 
    nft: "nft", 
    estate: "estate",
    invesment: "investment"
}