<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-5">
            <h3 class="mb-3 text-white">Savings List</h3><router-link class="btn btn-warning" to="/saves">Start a Saving Plan</router-link>
          </div>
        </div>
      </div>

      <div class=" mt-4">
        <save-list></save-list>
      </div>

    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import SaveList from '@/components/common/save/SaveList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserSaveList',
  components: {
    PageTemplate,
    SaveList
  },
  data() {
    const { banner } = this.appConfig();
    return {
      banner: banner || require("@/assets/images/common/22.jpeg"),
    }
  },
  methods: {
    ...mapGetters(["appConfig"])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
