<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      <balance></balance>
      <Summary></Summary>
      <div class="notification-area mt-4">
        <div class="container">
          <div class="card mb-4">
            <div class="card-body p-3">
              <div class="form-group mb-0">
                <input class="form-control form-control-clicked" id="elementsSearchInput" type="text" onkeyup="elementsSearch()" placeholder="Search...">
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
                <h6>Wallets</h6>
            </div>
            <div class="card-body" v-if="account().wallet && account().wallet.coins.length > 0">
              <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1">
                <!-- Single Accordion -->
                <div class="accordion-item mb-2 border-bottom" v-for="(coin, i) in account().wallet.coins" :key="i">
                  <div class="accordion-header" :id="`accordion${i}`">
                    <div class="row">
                      <!-- <div class="row"> -->
                        <div class="col-7">
                          <div class="d-flex">
                            <img :src="coin.logo" width="25px" style="width: 25px; height: 25px;"/> &nbsp;
                            <p>{{ coin.symbol }}</p>
                          </div>
                          <div>
                            <p>{{ JSON.parse(coin.meta).name || 'coin name'}}</p>
                          </div>
                        </div>
                        <div class="col-5 text-end">
                          <p class="">{{ Math.round( ( coin.balance * 10000000) ) / 10000000  }}</p>
                          <div>
                            <button class="btn btn-sm btn-danger mx-2" @click="selectedCoin = coin" data-bs-toggle="modal" data-bs-target="#sellModal">sell</button>
                            <button class="btn btn-sm btn-warning" @click="selectedCoin = coin" data-bs-toggle="modal" data-bs-target="#sendModal">send</button>
                          </div>
                        </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" v-else>
              <div class="text-center">
                <i class="bi bi-clipboard-x"></i>
                No coin yet <br/>
                <small>
                  To start <router-link to="/plans" style="text-decoration: none; color: var(--jw-text-success)"> click here. </router-link>
                </small>
                <img src="../../../assets/images/common/empty-wallet.png" />
              </div>
          </div>
          </div>
        </div>
      </div>

      <modal title="Send Coin to Wallet" id="sendModal">
        <div class="text-center"> 
          <h6>We will send to the address provided</h6>
        </div>
        <div>
          <h2>This feature is coming soon</h2>
        </div>
        <!-- <div class="">
          <div class="alert custom-alert-3 alert-secondary alert-dismissible fade show" role="alert"><i class="bi bi-check-all"></i>
            <div class="alert-text">
              <h6>Coin to Sell</h6>
              <span> <img :src="selectedCoin.logo" width="25px" style="width: 25px"/> {{ selectedCoin.symbol }} | {{ selectedCoin.coin }}</span>
            </div>
            <button class="btn btn-close position-relative p-1 ms-auto" type="button" @click="clearSelectedBank"></button>
          </div>

          <form>
            <div class="form-group">
              <label class="form-label" for="address">Recepient</label>
              <input class="form-control form-control-clicked" id="address" type="text" placeholder="1mbc.... or Wallet" v-model="v$.send.recepient.$model">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.send.recepient.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group">
              <label class="form-label" for="crypto-amount">Amount</label>
              <input class="form-control form-control-clicked" id="crypto-amount" type="number" placeholder="0.2" v-model="v$.send.amount.$model">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.send.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-check">
              <input class="form-check-input" id="confirm" type="checkbox" v-model="v$.send.confirm.$model">
              <label class="form-check-label" for="confirm">confirm action</label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.send.confirm.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <app-button @click="sendCryptoCoin" :classes="'my-1 btn-warning w-100 shadow'" :title="'Send'" :loading="loaders().send" :disabled="loaders().send || v$.send.$invalid" ></app-button>
          </form>
        </div> -->
      </modal>

      <modal title="Sell and Withdraw" id="sellModal">
        <div class="text-center"> 
          <h6>We will sell this coin on your behalf</h6>
        </div>
        <div>
          <h2>This feature is coming soon</h2>
        </div>
        <!-- <div class="">

          <div class="alert custom-alert-3 alert-secondary alert-dismissible fade show" role="alert"><i class="bi bi-check-all"></i>
            <div class="alert-text">
              <h6>Coin to Sell</h6>
              <span> <img :src="selectedCoin.logo" width="25px" style="width: 25px"/> {{ selectedCoin.symbol }} | {{ selectedCoin.coin }}</span>
            </div>
            <button class="btn btn-close position-relative p-1 ms-auto" type="button" @click="clearSelectedBank"></button> 
          </div>

          <form>
            <div class="form-group">
              <label class="form-label" for="crypto-amount">Amount</label>
              <input class="form-control form-control-clicked" id="crypto-amount" type="number" placeholder="0.2"  v-model="v$.sell.amount.$model" >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.sell.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-check">
              <input class="form-check-input" id="confirm" type="checkbox" v-model="v$.sell.confirm.$model">
              <label class="form-check-label" for="confirm">confirm action</label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.sell.confirm.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <app-button @click="sellCryptoCoin" :classes="'my-1 btn-warning w-100 shadow'" :title="'Sell'" :loading="loaders().sell" :disabled="loaders().sell || v$.sell.$invalid" ></app-button>
          </form>
        </div> -->
      </modal>

    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import Balance from '@/components/common/wallet/Balance.vue';
import Summary from '@/components/common/wallet/Summary.vue';
import Modal from '@/components/common/modal/Modal.vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/common/form/AppButton.vue';
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

export default {
  name: 'Wallet',
  components: {
    PageTemplate,
    Balance,
    Summary,
    Modal,
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      send: {
        confirm: { required },
        amount: { required, min: minLength(1) },
        recepient: { required }
      },
      sell: {
        confirm: { required },
        amount: { required, min: minLength(1) }
      }
    }
  },
  data() {
    return {
      send: {},
      sell: {},
      selectedCoin: {}
    }
  },
  mounted() {},
  methods: {
    ...mapGetters([
      "account",
      "loaders"
    ]),
    ...mapActions([
      "sellCoin",
      "sendCoin"
    ]),

    sendCryptoCoin() {
      const userUuid = window.localStorage.getItem("uuid");
      // var uniq = userUuid + '-' + (new Date()).getTime();
      const data = {
        amount: this.send.amount,
        user_uuid: userUuid,
        symbol: this.selectedCoin.symbol,
        recepient: this.send.recepient
      };
      const self = this;
      this.sendCoin(data).then( res => {
        const sendModal = document.getElementById('sendModal');
        const _sendModal = bootstrapBundleMin.Modal.getInstance(sendModal)
        _sendModal.hide();

        self.$router.push({
          path: '/send-sell-success', 
          query: { amount: data.amount, currency: this.selectedCoin.symbol, recepient: this.send.recepient, type: 'send' }
        });
      })
    },

    sellCryptoCoin() {
      const userUuid = window.localStorage.getItem("uuid");
      // var uniq = userUuid + '-' + (new Date()).getTime();
      const data = {
        amount: this.sell.amount,
        user_uuid: userUuid,
        symbol: this.selectedCoin.symbol
      };
      const self = this;
      this.sellCoin(data).then( res => {
        const sellModal = document.getElementById('sellModal');
        const _sellModal = bootstrapBundleMin.Modal.getInstance(sellModal)
        _sellModal.hide();

        self.$router.push({
          path: '/send-sell-success', 
          query: { amount: data.amount, currency: this.selectedCoin.symbol, type: 'sell' }
        });
      })
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
