<template>
  <base-template>
    <div class="hero-block-wrapper bg-success">
      <!-- Styles -->
      <div class="hero-block-styles">
        <div class="hb-styles1" style="background-image: url('../../../assets/images/common/dot.png')"></div>
        <div class="hb-styles2"></div>
        <div class="hb-styles3"></div>
      </div>
      <div class="custom-container">
        <!-- Skip Page -->
        <!-- <div class="skip-page"><router-link to="/login">Skip</router-link></div> -->
        <!-- Hero Block Content -->
        <div class="hero-block-content"><img class="mb-4" src="../../../assets/images/splash/19.png" alt="">
          <!-- <h2 class="display-4 text-white mb-3">Make the most of crypto investment!</h2>
          <p class="text-white">A more comfortable technology that abstract technicalities and gives you assess to unlimited wealth.</p> -->
          <h2 class="display-4 text-white mb-3">We don't just hold your money!</h2>
          <p class="text-white">We make more for you. Saving, invest and take loan from us.</p>
          <router-link class="btn btn-warning btn-lg w-100 text-white" to="/login">Get Started</router-link>
        </div>
      </div>
    </div>
  </base-template>
</template>

<script>
// @ is an alias to /src
import BaseTemplate from '@/components/templates/BaseTemplate.vue'

export default {
  name: 'SecondPage',
  components: {
    BaseTemplate
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
