<template>
  <div>
    <!-- <div class="login-back-button">
      <a href="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div> -->

    <div class="order-success-wrapper-2">
      <div class="custom-container">
        <div class="order-done-content">
          <svg class="bi bi-check-circle-fill text-success mb-4" width="60" height="60" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
          </svg>
          <h5>{{ title || "Successful" }}</h5>
          <p>
            {{ message || "Thank You!" }}
          </p>
          <div class="">
            <slot />
          </div>
          <hr/>
          <h6>Earn extra N200</h6>
          <div class="btn-group mt-3" role="group" aria-label="main action button">
            <router-link class="btn btn-warning" to="/referral">Get N200</router-link>
            <router-link v-if="next" class="btn btn-warning text-white" @click="$emit('close', e)" :to="next"> {{ nextPageName || " done "}}</router-link>
            <button class="btn btn-warning text-white" @click="$emit('close', e)"> {{ nextPageName || " done "}}</button>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SuccessNotification',
  props: ['title', 'message', 'next', 'nextPageName'],
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    const overlay = document.getElementsByClassName("modal-backdrop")[0];
    if ( overlay ) {
      overlay.classList.remove(...overlay.classList)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 10px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
