import { PLAN_TYPES } from '@/utils';
// import moment from 'moment';

export const switchAccount = (state, data) => {
    state.activeAccount = data
}

export const toggleVisibility = (state, data) => {
    state.visible = data
}

export const fetchAccount = (state, account) => {
    setPlans(state, account.plans);
    state.account = account;
};

const setPlans = (state, plans) => {
    const cryptoPlans = [];
    const savingPlans = [];
    const loanPlans = [];

    for (const plan of plans) {
        switch (plan.type) {
            case PLAN_TYPES.crypto:
                cryptoPlans.push(plan)
                break;
            case PLAN_TYPES.invesment:
                cryptoPlans.push(plan)
                break;
            case PLAN_TYPES.savings:
                savingPlans.push(plan)
                break;
            case PLAN_TYPES.loan:
                loanPlans.push(plan)
                break;
        }
    }

    state.userCryptoPlans = cryptoPlans;
    state.userSavingPlans = savingPlans;
    state.userLoanPlans = loanPlans
}

export const fundAccount = (state, accountDetails) => {
    state.accountDetails = accountDetails;
};

export const updateProfit = (state, profit) => {
    state.profit = profit;
};




export const fetchAccountStaff = (state, staffs) => {
    state.staffs = staffs;
};

export const fetchAccountSubAccount = (
    state,
    subAccounts
) => {
    state.subAccounts = subAccounts;
};

export const fetchAccountSetting = (state, setting) => {
    state.setting = setting;
};


export const fetchAnAccount = (state, account) => {
    // content
    console.log(state, account)
};
export const createAccount = (state, account) => {
    // content
    console.log(state, account)
};
export const updateAccount = (state, account) => {
    // content
    console.log(state, account)
};
export const fetchAllStaff = (state, staffs) => {
    state.staffs = staffs;
};
export const fetchStaff = (state, staff) => {
    // state.staffs = staff
    console.log(state, staff)
};
export const createStaff = (state, staff) => {
    // content
    const idx = state.accounts.data.findIndex(
        (account) => account.id === staff.accountId
    );
    if (idx < 0) {
        return;
    }
    state.accounts.data[idx].staffs.push(staff);
};
export const updateStaff = (state, staff) => {
    // content
    console.log(state, staff)
};
export const deleteStaff = (state, staff) => {
    // content
    console.log(state, staff)
};



export const createSubAccount = (state, subAccount) => {
    // content
    const idx = state.accounts.data.findIndex(
        (account) => account.id === subAccount.accountId
    );
    if (idx < 0) {
        return;
    }
    state.accounts.data[idx].sub_accounts.push(subAccount);
};

export const fetchAllSetting = (state, settings) => {
    state.settings = settings;
};
export const fetchSetting = (state, settings) => {
    // content
    console.log(state, settings)
};
export const createSetting = (state, settings) => {
    // content
    const idx = state.accounts.data.findIndex(
        (account) => account.id === settings.accountId
    );
    if (idx < 0) {
        return;
    }
    state.accounts.data[idx].setting = settings;
};
export const createSettingPin = (state, settings) => {
    // content
    console.log(state, settings)
};
export const verifySettingPin = (state, settings) => {
    // content
    console.log(state, settings)
};
export const updateSetting = (state, settings) => {
    // content
    console.log(state, settings)
};
export const deleteSetting = (state, settings) => {
    // content
    console.log(state, settings)
};


export const verifyAccount = (state, confirm) => {
    if (confirm) {
        state.auth.meta.is_verified = true
    }
}

export const confirmAddress = (state, d) => {
    const confirmation_date = new Date();
    confirmation_date.setDate(confirmation_date.getDate() + 3);
    localStorage.setItem("address_confirmation_date", confirmation_date)
}

const AccountMutation = {
    switchAccount,
    toggleVisibility,
    
    fetchAccount,
    fundAccount,

    fetchAccountStaff,
    fetchAccountSubAccount,
    fetchAccountSetting,
    fetchAnAccount,
    createAccount,
    updateAccount,
    fetchAllStaff,
    fetchStaff,
    createStaff,
    updateStaff,
    deleteStaff,
    updateProfit,

    createSubAccount,
    fetchAllSetting,
    fetchSetting,
    createSetting,
    createSettingPin,
    verifySettingPin,
    updateSetting,
    deleteSetting,

    verifyAccount,
    confirmAddress
};

export default AccountMutation;