import {
  GetMyTransactions
} from "@/services/transaction";

export const getTransactions = (context, _query) => {
  context.commit("updateLoader", { fetchTransactions: true });
  return new Promise((resolve, reject) => {
    GetMyTransactions(_query)
    .then(res => {
      context.commit("loadTransactions", res.data.data);
      resolve(res.data.data)
    })
    .catch(err => {
      console.log(err);
      reject(err)
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { fetchTransactions: false });
    });
  });
};


const TransactionAction = {
  getTransactions
};

export default TransactionAction;
