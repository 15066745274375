<template>
    <button :class="`btn ${ classes || 'btn-success w-100'}`" :disabled="disabled || false" type="button">
        <i v-if="icon" :class="`bi ${icon}`"></i> {{ title || 'Submit' }}
        <span v-if="loading" class="spinner-border spinner-border-sm text-light ml-2 text-xs" role="status"><span class="visually-hidden">Loading...</span></span>
    </button>
</template>

<script>

export default {
  name: 'AppButton',
  props: ['title', 'loading', 'disabled', 'classes', 'icon'],
  methods: {
      clicked(e) {
          this.$emit('click', e);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
