<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      <div class="container">
        <!-- User Information-->
        <div class="card user-info-card mb-3">
          <div class="card-body d-flex align-items-center">
            <div class="user-profile me-3">
              <img :src="getImageUrl()" alt="">
              <i class="bi bi-pencil"></i>
              <form>
                <input class="form-control" type="file"  @change="uploadFile">
              </form>
            </div>
            <div class="user-info">
              <div class="d-flex align-items-center">
                <h5 class="mb-1">
                  {{ userProfile() ? userProfile().first_name : 'firstname' }} {{ userProfile() ? userProfile().last_name : 'lastname' }}
                </h5>
                <span class="badge ms-2 rounded-pill" :class="`${userProfile() && userProfile().image ? 'bg-success' : 'bg-warning'}`">
                  <i class="bi bi-bookmark-check" v-if="userProfile() && userProfile().image"></i>
                  <i class="bi bi-bookmark-dash" v-else></i>
                </span>
              </div>
              <p class="mb-0">{{ auth().email || "@username" }}</p>
            </div>
          </div>
        </div>
        <!-- User Meta Data-->
        <div class="user-data-card mb-4" v-if="!edit">
          <div class="card" v-if="session==='' || session==='about'">
            <div class="d-flex card-head justify-content-between">
              <h6 class="p-4">
                <i class="bi bi-file-earmark-person-fill text-xs"></i> 
                About
              </h6>
              <p class="p-4 text-end" @click="startEditProfile">edit <i class="bi bi-pencil"></i></p>
            </div>
            <div class="card-body" >
              <p>First Name: {{ userProfile() ? userProfile().first_name : 'nil' }}</p>
              <p>Last Name: {{ userProfile() ? userProfile().last_name : 'nil' }}</p>
              <p>Gender: {{ userProfile() ? userProfile().gender : 'nil' }}</p>
              <p>Date of Birth: {{ userProfile() && userProfile().dob ? moment(userProfile().dob).format("ddd MMM DD, YYYY") : 'nil' }}</p>
            </div>
          </div>

          <div class="card mt-2" v-if="session==='' || session==='address'">
            <div class="d-flex card-head justify-content-between">
              <h6 class="p-4"><i class="bi bi-geo-alt text-xs"></i> Address</h6>
              <p class="p-4 text-end" @click="startEditProfile">edit <i class="bi bi-pencil"></i></p>
            </div>
            <div class="card-body" >
              <p>Street: {{ userProfile() ? userProfile().address : 'nil' }}</p>
              <p>Postal Code: {{ userProfile() ? userProfile().postal_code : 'nil' }}</p>
              <p>Landmark: {{ userProfile() ? userProfile().landmark : 'nil' }}</p>
              <p>Local Govt.: {{ userProfile() ? userProfile().lga : 'nil' }}</p>
              <p>City: {{ userProfile() ? userProfile().city : 'nil' }}</p>
              <p>State: {{ userProfile() ? userProfile().state : 'nil' }}</p>
              <p>Country: {{ userProfile() ? userProfile().country : 'nil' }}</p>
            </div>

            <div class="my-3 mx-2">
              <div v-if="settings().address_verified_at" class="text-success">
                <i class="bi bi-patch-check-fill"></i> this address has been verified
              </div>
              <div v-else-if="pendingAddressVerification()" class="text-muted">
                <i class="bi bi-info-circle"></i> this address is pending verification
              </div>
              <div v-else class="d-flex justify-content-end" data-bs-toggle="modal" data-bs-target="#verify-address">
                <app-button 
                  title="Verify Address" 
                  icon="bi-info-circle" 
                  classes="btn-warning" 
                  :loading="loaders().confirmAddress"
                  :disabled="loaders().confirmAddress"
                ></app-button>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button class="btn btn-success w-100 mt-2" @click="() => $router.push({path: '/dashboard'})" type="button">
              Done
            </button>
          </div>
        </div>
        

        <div class="card user-data-card" v-else>
          <div class="card-head">
            <p class="p-3 text-end" @click="edit=false"><i class="bi bi-x-lg"></i></p>
          </div>
          <div class="card-body">
            <form>
              <div v-if="session==='' || session==='about'">
                <div class="form-group mb-3">
                  <label class="form-label" for="Firstname">First Name</label>
                  <input class="form-control" id="Firstname" v-model="v$.profileModel.first_name.$model" :disabled="loaders().fetchProfile || loaders().saveProfile"  type="text" placeholder="First Name">
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.profileModel.first_name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="lastname">Last Name</label>
                  <input class="form-control" id="lastname" v-model="v$.profileModel.last_name.$model" :disabled="loaders().fetchProfile || loaders().saveProfile"  type="text" placeholder="Last Name">
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.profileModel.last_name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="email">Email Address</label>
                  <input class="form-control" id="email" type="text" :value="auth().email" :disabled="loaders().fetchProfile || loaders().saveProfile"  placeholder="Email Address" readonly="">
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="gender">Gender</label>
                  <select v-model="v$.profileModel.gender.$model" class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile"  id="gender">
                    <option value="male" :selected="profileModel.gender && profileModel.gender.toLowerCase()==='male'">Male</option>
                    <option value="female" :selected="profileModel.gender && profileModel.gender.toLowerCase()==='female'">Female</option>
                  </select>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="email">Date of Birth</label>
                  <Datepicker v-model="v$.profileModel.dob.$model" :format="format" :flow="flow" auto-apply model-type="dd-MM-yyyy"></Datepicker>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.profileModel.dob.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div v-if="session==='' || session==='address'">
                <div class="form-group mb-3">
                  <label class="form-label" for="address">Address</label>
                  <input class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile" id="google-address" ref="googleAddress" name="address" v-model="v$.profileModel.address.$model"/>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="landmark">Landmark</label>
                  <input class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile"  id="landmark" name="landmark" v-model="v$.profileModel.landmark.$model"/>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="lga">LGA</label>
                  <input class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile"  id="lga" name="lga" v-model="v$.profileModel.lga.$model"/>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="city">City</label>
                  <input class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile"  id="city" name="city" v-model="v$.profileModel.city.$model"/>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="state">State</label>
                  <input class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile"  id="state" name="state" v-model="v$.profileModel.state.$model"/>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="postal_code">Postal Code</label>
                  <input class="form-control" :disabled="loaders().fetchProfile || loaders().saveProfile"  id="postal_code" name="postal_code" v-model="v$.profileModel.postal_code.$model"/>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="country">Country</label>
                  <select v-model="v$.profileModel.country.$model" :disabled="loaders().fetchProfile || loaders().saveProfile"  class="form-control" id="country">
                    <option v-for="(c, i) in countries" :key="i" :value="c.country">{{ c.country }}</option>
                  </select>
                  <!-- <input class="form-control" id="country" type="text" v-model="profileModel.country" placeholder="country"> -->
                </div>
              </div>
              <app-button @click="updateProfile" :title="'Update'" :loading="loaders().fetchProfile || loaders().saveProfile" :disabled="loaders().fetchProfile || loaders().saveProfile || v$.profileModel.$invalid" ></app-button>
              <!-- <button class="btn btn-success w-100" :disabled="loaders().fetchProfile || loaders().saveProfile || v$.profileModel.$invalid" type="button" @click="updateProfile"> {{ loaders().editProfile ? 'updating...' : 'Update'}} </button> -->
            </form>

            <div>
              <ul>
                <li v-for="(error, i) in Object.keys(errors)" :key="i">
                  <small class="text-danger text-xs" v-if="error">{{ error }} : {{ errors[error] && Array.isArray(errors[error]) ? errors[error].join(", ") : error }}</small>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="referrer==='verification' && !edit && inCompleteProfile" class="alert alert-warning alert-dismissible fade show">
          You are required to update your profile.
          Kindly update all <strong>required</strong> information.
          <div class="d-flex justify-content-end">
            <button class="btn m-1 btn-sm btn-warning shadow" @click="startEditProfile">
              Edit Profile
            </button>
          </div>
        </div>

      </div>
    </div>

    <modal title="Verify Address" id="verify-address">
      <form v-if="!confirmReciept">
        <p class="my-2">
          <i class="bi bi-info-circle"></i>
          Verifying your address requires one of our staff to visit your provided location. 
          <br/>
          This will cost you a one time payment of <strong>N1400</strong> provided it's a valid location you have provided.
          <p>
            Note that this will not be charged if you have a pending or successful verification.
          </p>
          <br/>
          <small><i>Click the button below to continue</i></small>
        </p> 
        <!-- <button class="btn btn-success w-100" @click="subscribe" :disabled="loaders().subscribeForAlert" type="button">Subscribe</button> -->
        <app-button @click="confirmReciept = true" :title="'proceed'" :loading="loaders().subscribeForAlert" :disabled="loaders().subscribeForAlert" ></app-button>
      </form>

      <div class="card invoice-card shadow" v-else>
        <div class="card-body">
          <!-- Download Invoice -->
          <div class="download-invoice text-end mb-3">
            <a class="btn btn-sm btn-primary me-2" href="#" @click="printDiv('invoice-div')">
              <i class="bi bi-file-earmark-pdf"></i> PDF
            </a>
            <a class="btn btn-sm btn-light" href="#" @click="printDiv('invoice-div')">
              <i class="bi bi-printer"></i> Print
            </a>
          </div>

          <div id="invoice-div">
            <!-- Invoice Info -->
            <div class="invoice-info text-end mb-4">
              <h5 class="mb-1 fz-14"> Address Verification</h5>
              <h6 class="fz-12">One time payment</h6>
              <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
            </div>

            <!-- Invoice Table -->
            <div class="invoice-table">
              <div class="table-responsive">
                <table class="table table-bordered caption-top">
                  <caption>Payment</caption>
                  <thead class="table-light">
                    <tr>
                      <th>Sl.</th>
                      <th>Description</th>
                      <th>Unit</th>
                      <th>Q.</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td>1</td>
                      <td>Address verification</td>
                      <td>1</td>
                      <td>1</td>
                      <td>NGN 1400</td>
                    </tr>
                  </tbody>
                  <tfoot class="table-light">
                    <tr>
                      <td class="text-end" colspan="4">Total:</td>
                      <td class="text-end">NGN 1400</td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="4">Fee (0%):</td>
                      <td class="text-end">NGN 0</td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="4">Grand Total:</td>
                      <td class="text-end">NGN 1400</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="text-center my-1">
            <app-button @click="updateAndVerifyAddress" :disabled="loaders().confirmAddress" :title="'Confirm'" :loading="loaders().confirmAddress" ></app-button>
          </div>

          <p class="mb-0">Notice: This is auto generated invoice.</p>
        </div>
      </div>
    </modal>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import { Countries } from '@/utils'
import { AddFile } from "@/services/file";
import useVuelidate from '@vuelidate/core';
import AppButton from '@/components/common/form/AppButton.vue';
import { required, minLength } from '@vuelidate/validators';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue';
import { createToaster } from "@meforma/vue-toaster";
import moment from 'moment'
import { printDiv } from '@/utils';
import Modal from '@/components/common/modal/Modal.vue'
const toaster = createToaster({ /* options */ });

export default {
  name: 'ProfilePage',
  components: {
    PageTemplate,
    AppButton,
    Datepicker,
    Modal
  }, 
  setup () {
    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const flow = ref(['month', 'year', 'calendar']);
    
    return { flow, format, v$: useVuelidate() }
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv
  },
  computed: {
    inCompleteProfile() {
      const profile = this.userProfile();
      if ( !profile.first_name || !profile.last_name || !profile.dob ) {
        return true;
      }
      return false
    }
  },
  data() {
    return {
      profileModel: {
        dob: '2000-01-01',
        country: '',
        state: '',
        city: '',
        lga: '',
        postal_code: ''
      },
      confirmReciept: false,
      countries: Countries,
      file: "https://cdn-icons-png.flaticon.com/512/149/149071.png",
      errors: {},
      edit: false,
      referrer: "",
      session: ""
    }
  },
  validations() {
    return {
      profileModel: {
        first_name : {required},
        last_name : {required},
        dob: { required },
        address : { min: minLength(3)},
        gender : {required},
        city : {},
        image : {},
        postal_code : { min: minLength(5) },
        country : {},
        lga : {},
        postal_code : {},
        state : {},
        landmark : {},
        image: {},
        about : {
          min: minLength(12)
        }
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.page ) {
      this.referrer = this.$route.query.page
    }
    const self = this
    this.getProfile().then( res => {
      self.profileModel = res
    })
    
    const { 
      edit, 
      session
    } = this.$route.query;

    if (session) {
      this.session = session;
    }
    if (edit) {
      this.edit = edit === "true" || edit === true
    }
  },
  methods: {
    ...mapActions([
      "getProfile",
      "saveProfile",
      "confirmAddress"
    ]),
    ...mapGetters([
      "loaders",
      "auth",
      "userProfile",
      "settings",
      "pendingAddressVerification"
    ]),
    updateProfile() {
      const self = this
      this.saveProfile(this.profileModel)
        .then(() => {
          self.edit = false
        })
        .catch( err => {
          const { data, message } = err
          toaster.error(message)
          self.errors = data || { message }
        })
    },
    updateAndVerifyAddress() {
      this.confirmAddress(this.profileModel).catch( err => {
        const { 
          // data, 
          message 
        } = err;
        toaster.error(message)
        // this.errors = data || { message }
      })
    },
    async uploadFile(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const res = await AddFile(formData);
      this.file = res.data.data;
      this.profileModel.image = res.data.data;
      this.updateProfile();
    },
    startEditProfile() {
      this.profileModel = this.userProfile();
      if ( this.userProfile() && this.userProfile().dob ) {
        const dates = this.userProfile().dob.split("-")
        const date = new Date(dates[2] + '-' + dates[1] + '-' + dates[0]);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        this.profileModel.dob = `${year}-${month}-${day}`;
      }
      this.edit = true
      setTimeout(() => {
        this.initGooglePlace()
      }, 2000)
    },
    getImageUrl(){
      const img = this.userProfile() && this.userProfile().image ? this.userProfile().image : this.file;
      let knownTypes = {
        '/': 'data:image/jpg;base64,',
        'i': 'data:image/png;base64,',
        'R': 'data:image/gif;base64,',
        'U': 'data:image/webp;base64,',
      }
      
      return !knownTypes[img[0]] ? img : knownTypes[img[0]]+img;
    },
    initGooglePlace() {
        const places = new google.maps.places.Autocomplete(this.$refs.googleAddress);
        const self = this;
        google.maps.event.addListener(places, 'place_changed', function() {
          const place = places.getPlace();
          const { address_components, formatted_address: address } = place;

          const component_count = address_components.length
          const { long_name: postal_code } = address_components[component_count-1]
          const { long_name: country } = address_components[component_count-2]
          const { long_name: state } = address_components[component_count-3]
          const { long_name: lga } = address_components[component_count-4]
          
          const  addresses = address.split(",");
          // console.log(place, address, addresses)
          const count = addresses.length;
          // const country = addresses[count-1];
          // const state = addresses[count-2];
          const city = addresses[count-3];
          self.profileModel.country = country;
          self.profileModel.state = state;
          self.profileModel.city = city;

          // self.profileModel.landmark = landmark;
          self.profileModel.lga = lga;

          self.profileModel.postal_code = postal_code;

          // var z=state.split(" ");
          // var i =z.length;
          // document.getElementById("pstate").value = z[1];
          // if(i>2)
          // document.getElementById("pzcode").value = z[2];
          // var latitude = place.geometry.location.lat();
          // var longitude = place.geometry.location.lng();
          // var mesg = address;
          // document.getElementById("txtPlaces").value = mesg;
          // var lati = latitude;
          // document.getElementById("plati").value = lati;
          // var longi = longitude;
          // document.getElementById("plongi").value = longi;            
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
