<template>
  <page-template>
    <div class="page-content-wrapper py-0">

      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-5">
            <h3 class="mb-3 text-white">Strategy Summary.</h3><router-link class="btn btn-warning" to="/plans">Start Plan</router-link>
          </div>
        </div>
      </div>
      

      <div class="notification-area mt-4">
        
        <div class="container">

          <div class="card mb-4">
            <div class="card-body p-3">
              <div class="form-group mb-0">
                <input class="form-control form-control-clicked" id="elementsSearchInput" type="text" onkeyup="elementsSearch()" placeholder="Search...">
              </div>
            </div>
          </div>

          <div class="card" v-if="strategies().data.length > 0">
            <div class="card-body">
              <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1">
                <!-- Single Accordion -->
                <div class="accordion-item" v-for="(strategy, i) in strategies().data" :key="i">
                  <div class="accordion-header" :id="`accordion${i}`">
                    <h6 data-bs-toggle="collapse" :data-bs-target="`#accordionStyle${i}`" aria-expanded="false" :aria-controls="`accordionStyle${i}`" class="collapsed">
                      <div class="flex justify-between">
                        <span>Strategy {{ i + 1 }} @ price {{ strategy.price }}</span>
                        <span :class="`m-1 badge ${ ( strategy.is_active || activeStrategy === strategy.id ) ? 'bg-success' : 'bg-secondary'}`" style="cursor: pointer" @click="toggleActiveStrategy(strategy)">{{ ( strategy.is_active || activeStrategy === strategy.id ) ? 'active' : 'in-active' }}</span>
                      </div>
                      <i class="bi bi-chevron-down"></i>
                    </h6>
                  </div>
                  <div class="accordion-collapse collapse" :id="`accordionStyle${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle1" style="">
                    <div class="accordion-body">
                      <p class="mb-0">
                        
                        <table class="table mb-0 table-hover">
                          <tbody>
                            <tr>
                              <td>% Change in 1 hr</td>
                              <td>{{ strategy.percent_change_1h }}</td>
                            </tr>
                            <tr>
                              <td>% Change in 24 hr</td>
                              <td>{{ strategy.percent_change_24h }}</td>
                            </tr>
                            <tr>
                              <td>% Change in 7 days</td>
                              <td>{{ strategy.percent_change_7d }}</td>
                            </tr>
                            <tr>
                              <td>% Change in 30 days</td>
                              <td>{{ strategy.percent_change_30d }}</td>
                            </tr>
                            <tr>
                              <td>Market Cap</td>
                              <td>{{ strategy.market_cap }}</td>
                            </tr>
                            <tr>
                              <td>Max Supply</td>
                              <td>{{ strategy.max_supply }}</td>
                            </tr>

                            <tr>
                              <td>Circulating Supply</td>
                              <td>{{ strategy.circulating_supply }}</td>
                            </tr>
                            <!-- <tr v-for="(msg, j) in JSON.parse(trx.meta)" :key="j">
                              <td>Charge</td>
                              <td>{{ msg }}</td>
                            </tr> -->
                          </tbody>
                        </table>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-2">
            <div class="card-body text-center">
              <span v-if="strategies().data.length === 0">Empty list</span>
              <form>
                <button class="btn btn-success w-100 mt-2" data-bs-toggle="modal" data-bs-target="#add-strategy" type="button">
                  Add New Strategy
                </button>
              </form>
            </div>
          </div>


        </div>
      </div>

    </div>

    <modal title="Add Strategy" id="add-strategy">
      <div class="card user-data-card mb-3">
        <div class="card-body">
          <form>
            <div class="form-group mb-3">
              <label class="form-label" for="change1h">Change in 1 hr</label>
              <input class="form-control" id="change1h" v-model="v$.prediction.percent_change_1h.$model" type="text" placeholder="Change in 1 hr">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.percent_change_1h.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>


            <div class="form-group mb-3">
              <label class="form-label" for="change24h">Change in 24 hr</label>
              <input class="form-control" id="change24h" v-model="v$.prediction.percent_change_24h.$model" type="text" placeholder="Change in 24 hr">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.percent_change_24h.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="change7d">Change in 7 days</label>
              <input class="form-control" id="change7d" v-model="v$.prediction.percent_change_7d.$model" type="text" placeholder="Change in 7 days">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.percent_change_7d.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="change30d">Change in 30 days</label>
              <input class="form-control" id="change30d" v-model="v$.prediction.percent_change_30d.$model" type="text" placeholder="Change in 30 days">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.percent_change_30d.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="max-supply">Max Supply</label>
              <input class="form-control" id="max-supply" v-model="v$.prediction.max_supply.$model" type="text" placeholder="Max Supply">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.max_supply.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="circulating-supply">Circulating Supply</label>
              <input class="form-control" id="circulating-supply" v-model="v$.prediction.circulating_supply.$model" type="text" placeholder="Circulating Supply">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.circulating_supply.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="market-cap">Market Cap</label>
              <input class="form-control" id="market-cap" v-model="v$.prediction.market_cap.$model" type="text" placeholder="Market Cap">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.market_cap.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="price">Price</label>
              <input class="form-control" id="price" v-model="v$.prediction.price.$model" type="text" placeholder="Price">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.prediction.price.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>


            <button class="btn btn-success w-100" @click="addStrategy(prediction)" :disabled="loaders().resetPassword || v$.prediction.$invalid" type="button">Add Strategy</button>
          </form>
        </div>
      </div>
    </modal>

  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import Modal from '@/components/common/modal/Modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'Strategy',
  components: {
    PageTemplate,
    Modal
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    const { banner } = this.appConfig();
    return {
      prediction: {},
      activeStrategy: null,
      banner: banner || require("@/assets/images/common/22.jpeg"),
    }
  },
  validations() {
    return {
      prediction: {
        percent_change_1h: { required },
        percent_change_24h: { required },
        percent_change_7d: { required },
        percent_change_30d: { required },
        max_supply: { required },
        circulating_supply: { required },
        market_cap: { required },
        price: { required },
      }
    }
  },
  mounted() {
    this.getStrategy()
  },
  methods: {
    ...mapActions([
      "getStrategy",
      "addStrategy",
      "updateStrategy",
      "toggleStrategy",
      "deleteStrategy"
    ]),
    ...mapGetters([
      "loaders",
      "strategies",
      "appConfig"
    ]),
    toggleActiveStrategy(strategy) {
      // this.strategies().data
      this.activeStrategy = this.activeStrategy === strategy.id ? null : strategy.id
      this.toggleStrategy(strategy);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
