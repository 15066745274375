<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      <div class="container">

        <div class="card timeline-card bg-success">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">{{ wallet.createdAt || '17 Nov 2021'}}</span>
                <h6>N {{ wallet.balance || '0'}}</h6>
              </div>
              <div class="timeline-icon mb-2">
                <svg class="bi bi-basket" width="28" height="28" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"></path>
                </svg>
              </div>
            </div>
            <p class="mb-2">
              {{ wallet.walletNumber }}
            </p>
            <div class="timeline-tags"></div>
          </div>
        </div>


        <!-- User Information-->
        <div class="card user-info-card mb-3">
          <div class="card-body d-flex align-items-center">
            <div class="user-profile me-3"><img src="../../../assets/images/common/2.jpeg" alt=""><i class="bi bi-pencil"></i>
              <form action="#">
                <input class="form-control" type="file">
              </form>
            </div>
            <div class="user-info">
              <div class="d-flex align-items-center">
                <h5 class="mb-1">{{ account.account_no || 'ACCT' }} </h5>
                <span :class="`badge ${!settings.approval ? ' bg-warning ' : ' bg-success ' }  ms-2 rounded-pill`"> {{ settings.approval ? ' active' : ' in-active ' }} </span>
              </div>
              <p class="mb-0">{{ settings.account_name || 'account name' }}</p>
            </div>
          </div>
        </div>
        <!-- User Meta Data-->
        <div class="card user-data-card mb-3">
          <div class="card-body">
            <form action="#">
              <div class="form-group mb-3">
                <label class="form-label" for="Account Name">Account Name</label>
                <input class="form-control" id="AccountName" v-model="settings.account_name" type="text" placeholder="Account Name">
              </div>
              <div class="form-check">
                <input class="form-check-input" id="receiveSms" v-model="settings.send_sms_receipt" type="checkbox" value="">
                <label class="form-check-label" for="receiveSms">Recieve SMS</label>
              </div>

              <div class="form-check">
                <input class="form-check-input" id="receiveEmail" v-model="settings.send_email_receipt" type="checkbox" value="">
                <label class="form-check-label" for="receiveEmail">Recieve Email</label>
              </div>
              <button class="btn btn-success w-100" type="submit">Update Now</button>
            </form>
          </div>
        </div>

        <div class="card user-data-card">
          <div class="card-body">
              <div class="text text-center">
                <span>
                  referral code: {{ settings.referral_code || "nil"}}
                </span>
              </div>
          </div>
        </div>
        
      </div>
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
// import { countries } from '@/assets/js/form-autocomplete';

export default {
  name: 'AccountPage',
  components: {
    PageTemplate
  },
  data() {
    return {
      settings: {},
      wallet: {}
    }
  },
  methods: {
    ...mapActions([
      "myAccount"
    ])
  },
  mounted() {
    this.myAccount();
  },
  watch: {
    '$store.state.account': function(b, n) {
      console.log(b, n)
      this.settings = b.setting ? b.setting : {};
      this.wallet = b.wallet ? b.wallet : {};
    }
  },
  computed: {
    ...mapGetters([
      "account"
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
