import {
  GetQuestions, SelectAnwser
} from "@/services/question";

export const getQuestions = (context, query = '') => {
  context.commit("updateLoader", { fetchQuestions: true });
  return new Promise((resolve, reject) => {
    GetQuestions(query)
      .then(res => {
        const result = res.data.data
        context.commit("fetchQuestions", result);
        resolve(result)
      })
      .catch(err => {
        console.log(err);
        reject(err)
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { fetchQuestions: false });
      });
    });
}

export const selectAnwser = (context, id) => {
  context.commit("updateLoader", { selectAnwser: true });
  return new Promise((resolve, reject) => {
    SelectAnwser(id)
      .then(res => {
        const result = res.data.data;
        resolve(result)
      })
      .catch(err => {
        console.log(err);
        reject(err)
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { selectAnwser: false });
      });
    });
}

const QuestionAction = {
  getQuestions,
  selectAnwser
};

export default QuestionAction;
