const PlanState = {
  cryptoPlans: [],
  savingPlans: [],
  loanPlans: [],
  subscribe: {},
  userCryptoPlans: [],
  userSavingPlans: [],
  userLoanPlans: []
};

export default PlanState;
