export default function credible({ next, store, _next }) {
    if (
        store.getters.userProfile 
        && Object.keys(store.getters.userProfile).length > 0 
        && !store.getters.userProfile.dob
    ) {
        return _next({
            name: 'profile',
            query: { page: "verification" }
        })
    }

    return next()
}