export const formatter = (currency) => {
    const currencyCode = {
      NGN: 'en-NG',
      USD: 'en-US',
      GBP: 'en-US'
    }

    if (!Object.keys(currencyCode).includes(currency)) {
      return {
        format(amount) {
          return formatCurrency({amount, currency})
        }
      }
    }

    const code = currencyCode[currency] || 'en-US'
    
    return new Intl.NumberFormat(code, {
      style: 'currency',
      currency,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
}

const cryptoSymbols = {
  "ETH": "Ξ",
  "BTC": "₿",
  "USDT": "₮",
  "USD": "$",
  "NGN": "₦",
  "GBP": "£"
}

export const formatCurrency = ({amount, currency, locale = "en"}) => {
  const curr = currency || "NGN"
  return `${cryptoSymbols[curr]} ${Number(amount).toFixed(2)}`
}
