const PredictionState = {
  predictions: {
    first_match: [],
    second_match: [],
    third_match: [],
    forth_match: []
  },
  strategies: {data: []}
};

export default PredictionState;
