<template>
  <page-template>

    <div class="page-content-wrapper py-3">
     
      <div class="container">
        <div class="card">
          <div class="card-body">
            <!-- Price Table One -->
            <div class="price-table-one">
              <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center">
                <span class="nav-item"></span><span class="nav-item"></span>
                <li class="nav-item" role="presentation">
                  <a class="nav-link shadow" data-bs-toggle="tab" href="#" @click="goBack" role="tab" aria-controls="priceTab_Two" aria-selected="false">
                    <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
              <div class="tab-content" >
                <span class="tab-pane"></span>
                <span class="tab-pane"></span>
                <div class="tab-pane fade active show" role="tabpanel" aria-labelledby="priceTabThree">
                  <!-- Single Price Table -->
                  <div class="single-price-content shadow-sm">

                    <div class="text-lg">
                      <p class="text-warning font-bold"><b>transfer to the account details below</b></p>
                    </div>

                    <div class="price"><span class="text-white mb-2">Account Details</span>
                      <hr/>
                      <h4>
                        {{ loaders().fundAccount ? 'loading...' : ( accountDetails().account_number || "nil" ) }}
                      </h4>
                      <span class="badge bg-light text-dark text-start lh-base">
                        Bank name: {{ accountDetails().bank_name }} <br/> 
                        Routing number: {{ accountDetails().routing_number }} <br/>
                        Swift code: {{ accountDetails().swift_code }} <br/>
                      </span>
                    </div>
                    <!-- Pricing Desc -->
                    <div class="pricing-desc">
                      <ul class="ps-0">
                        <li><i class="bi bi-check-lg me-2"></i>Reference: {{ accountDetails().initiation_tran_ref || accountDetails().reference }}</li>
                        <!-- <li><i class="bi bi-check-lg me-2"></i>Amount </li> -->
                        <li><i class="bi bi-check-lg me-2"></i>Status - Pending</li>
                        <li><i class="bi bi-check-lg me-2"></i>Date - {{ (new Date()).toDateString() }}</li>
                        <li><i class="bi bi-check-lg me-2"></i>Processing Fee - 1%</li>
                      </ul>
                    </div>
                    <!-- Purchase -->
                    <div class="purchase flex" v-if="!loaders().fundAccount">
                      <div class="spinner-grow text-success" role="status"><span class="visually-hidden">Loading...</span></div>
                      <p class="text-light">awaiting payment</p>
                    </div>
                    <div>
                      <p class="text-muted">* confirm in <router-link to="/transaction" class="text text-muted">transaction</router-link> after making payment.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    

  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  name: 'BankTransferUSD',
  components: {
    PageTemplate
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    ...mapGetters([
      "accountDetails",
      "loaders"
    ]),
    ...mapActions([
      "fundAccount"
    ]),
    listenForPayment() {
      const userUUID = window.localStorage.getItem("uuid");
      const self = this;
      
      window.Echo.private(`transaction.credit.usd.${userUUID}`)
        .listen('.credit.alert', function(e) {
            // console.log(e);
            if ( e.transaction.status === "SUCCESSFUL" ) {
              self.$router.push({
                path: '/bank-transfer-success', 
                query: { amount: e.transaction.net_amount, currency: e.transaction.currency }
              });
              // toaster(e.transaction.description)
            }
        }).listen("pusher:subscription_error", (e) => {
            console.log(e);
        });

      window.Echo.channel
    }
  },
  mounted() {
    const self = this;
    this.fundAccount({type: "USD"})
      .then( res => {
        self.listenForPayment();
        const overlay = document.getElementsByClassName("modal-backdrop")[0];
        if(overlay) overlay.classList.remove(...overlay.classList)
      })
      .catch( err => {
        console.log(err)
        const overlay = document.getElementsByClassName("modal-backdrop")[0];
        if(overlay) overlay.classList.remove(...overlay.classList)  
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
