import { AddBankAccount, ActivateMandate, Enquiry, GetBankAccount, GetBanks, GetBanksWithUSSD, UpdateBankAccount, DeleteBankAccount } from "@/services/bankAccount";


export const getBanks = (context, params = {}) => {
    context.commit("updateLoader", { fetchBanks: true });
    return new Promise((resolve, reject) => {
        GetBanks(params)
            .then(res => {
                context.commit("fetchBanks", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBanks: false });
            });
    });
};

export const getBanksWithUSSD = (context) => {
    context.commit("updateLoader", { fetchBanksWithUSSD: true });
    return new Promise((resolve, reject) => {
        GetBanksWithUSSD()
            .then(res => {
                context.commit("fetchBanksWithUSSD", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBanksWithUSSD: false });
            });
    });
};

export const enquire = (context, data) => {
    context.commit("updateLoader", { enquire: true });
    return new Promise((resolve, reject) => {
        Enquiry(data)
            .then(res => {
                // context.commit("enquire", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { enquire: false });
            });
    });
};



export const getBankAccounts = (context, params = {}) => {
    context.commit("updateLoader", { fetchBankAccount: true });
    return new Promise((resolve, reject) => {
        GetBankAccount(params)
            .then(res => {
                context.commit("fetchBankAccount", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBankAccount: false });
            });
    });
};

export const addBankAccount = (context, data) => {
    context.commit("updateLoader", { addBankAccount: true });
    return new Promise((resolve, reject) => {
        AddBankAccount(data)
            .then(res => {
                context.commit("addBankAccount", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addBankAccount: false });
            });
    });
};

export const activateMandate = (context, data = {}) => {
    context.commit("updateLoader", { activateMandate: true });
    return new Promise((resolve, reject) => {
        ActivateMandate(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                context.commit("updateLoader", { activateMandate: false });
            });
    });
};

export const updateBankAccount = (context, data) => {
    context.commit("updateLoader", { updateBankAccount: true });
    return new Promise((resolve, reject) => {
        UpdateBankAccount(data)
            .then(res => {
                context.commit("updateBankAccount", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateBankAccount: false });
            });
    });
};

export const deleteBankAccount = (context, bankAccount) => {
    const { id } = bankAccount;
    context.commit("updateLoader", { deleteBankAccount: true });
    context.commit("deleteBankAccount", bankAccount);
    return new Promise((resolve, reject) => {
        DeleteBankAccount(id)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteBankAccount: false });
            });
    });
};

const BankAccountAction = {
    getBanks,
    getBanksWithUSSD,
    enquire,
    getBankAccounts,
    addBankAccount,
    updateBankAccount,
    activateMandate,
    deleteBankAccount
};

export default BankAccountAction;