<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-5">
            <h3 class="mb-3 text-white">Bank Accounts.</h3>
            <router-link @click="switchAccount('home')" class="btn btn-warning" to="/dashboard">Withdraw</router-link>
          </div>
        </div>
      </div>
      <div class="notification-area mt-4">
        <div class="container">
          <div class="card mb-4">
            <div class="card-body p-3">
              <div class="form-group mb-0">
                <input class="form-control form-control-clicked" id="elementsSearchInput" type="text" @keyup="searchBankAccounts" placeholder="Search...">
              </div>
            </div>
          </div>
          <div class="card" v-if="bankAccountList.length > 0">
            <div class="card-body">
              <div class="accordion accordion-flush accordion-style-one" id="accordion1">
                <!-- Single Accordion -->
                <div class="accordion-item my-2" v-for="(bankAccount, i) in bankAccountList" :key="i">
                  <div class="accordion-header" :id="`accordion${i}`">
                    <div data-bs-toggle="collapse" :data-bs-target="`#accordionStyle${i}`" aria-expanded="false" :aria-controls="`accordionStyle${i}`" class="collapsed">
                      <div class="row">
                        <div class="col-1">
                          <small><i class="bi bi-bank"></i></small>
                        </div>
                        <div class="col-10">
                          {{ bankAccount.account_name }} <br/>
                          <small class="text-muted"><i>{{ bankAccount.bank_name }}</i> | {{ bankAccount.account_number }}</small>
                        </div>
                        <div class="col-1 d-flex justify-content-end" data-bs-toggle="modal" @click="activeBankAccount = bankAccount" data-bs-target="#delete-bank-account" >
                          <i 
                            class="bi bi-trash text-danger text-warning:hover"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-else-if="loaders().fetchBankAccount">
            <div class="d-flex flex-column justify-content-center text-center">
              <div class="my-4 text-center">loading bank account details...</div>
              <br/>
              <div class="mb-4 text-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="text-center">
              <i class="bi bi-clipboard-x"></i>
              No bank details yet
              <br/>
              <img src="../../../assets/images/common/empty-tnx.png" />
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-body text-center">
              <form>
                <button class="btn btn-success w-100 mt-2" data-bs-toggle="modal" data-bs-target="#add-bank-account" type="button">
                  Add New Bank Account
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-add-bank-account id="add-bank-account"></modal-add-bank-account>
    <modal title="Confirm Delete" id="delete-bank-account">
      <form>
        <div class="form-group mb-3">
          <label class="form-label" for="delete_bank_account_number">
              Enter Bank Account to Confirm
              <i class="bi bi-info-circle" data-toggle="tooltip" data-placement="top" data-html="true" title="<em>bank account number. eg: 079384....</u>"></i>
          </label>
          <input class="form-control" id="delete_bank_account_number" v-model="deleteBankAccountNumber" type="text" placeholder="07928399999">
          <div class="input-errors text-xs text-danger mt-2" v-if="error">
              <div class="error-msg">{{ error }}</div>
          </div>
        </div>
        <button class="btn btn-danger w-100" @click="removeBankAccount" :disabled="loaders().deleteBankAccount" type="button">Delete</button>
      </form>
    </modal>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import ModalAddBankAccount from '@/components/common/modal/ModalAddBankAccount.vue';
import Modal from '@/components/common/modal/Modal.vue';
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/common/form/AppButton.vue';
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import { bankAccount } from '@/mixins';

export default {
  name: 'BankAccount',
  mixins: [bankAccount],
  components: {
    PageTemplate,
    AppButton,
    ModalAddBankAccount,
    Modal
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      banner: banner || require("@/assets/images/common/22.jpeg"),
      error: "",
      deleteBankAccountNumber: "",
      activeBankAccount: {}
    }
  },
  mounted() {
    this.rmOverlay()
  },
  methods: {
    ...mapActions([
      "deleteBankAccount",
      "switchAccount"
    ]),
    ...mapGetters([
      "loaders",
      "appConfig"
    ]),
    rmOverlay() {
      const overlay = document.getElementsByClassName("modal-backdrop")[0];
      if ( overlay ) {
        overlay.classList.remove(...overlay.classList)
      }
    },
    async removeBankAccount() {
      if (this.deleteBankAccountNumber !== this.activeBankAccount.account_number) {
        this.error = "This entered value is not correct."
      }
      await this.deleteBankAccount(this.activeBankAccount)
      const deleteBankModal = document.getElementById("delete-bank-account");
      const _deleteBankModal = bootstrapBundleMin.Modal.getOrCreateInstance(deleteBankModal)
      _deleteBankModal.toggle();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
